import styled from "styled-components";

export const UploadDocType1FormStyled = styled.div`
  .input_wrap {
    margin-bottom: 20px;
  }
  .line_horizontal {
    margin-bottom: 20px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.GRAY_3}80;
  }
  .info_wrap {
    margin-bottom: 16px;
  }
  .f_label {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    .sub_label {
      color: ${({ theme }) => theme.COLORS.GRAY_3};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .title_rec {
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .sub_title_rec {
    margin-bottom: 10px;
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
`;
