import React from "react";
import { AuthenTestStep1FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const AuthenTestStep1Form = ({ onSubmit }) => {
  const schema = yup.object().shape({
    mobileNumber: yup.string().required("Mobile is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <AuthenTestStep1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label={'เบอร์โทรศัพท์'}
                placeholder={'กรุณากรอก'}
                errors={errors.mobileNumber?.message}
              />
            )}
            name="mobileNumber"
            defaultValue=""
          />
        </div>

        <Buttons.BgStandard
          type="submit"
          label="ตกลง"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </AuthenTestStep1FormStyled>
  );
};

AuthenTestStep1Form.propTypes = {};

export default AuthenTestStep1Form;
