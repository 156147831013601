import styled from "styled-components";

export const NormalStyled = styled.div`
  position: relative;
  padding: 36px 10px 9px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/backgrounds/header-normal.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .btn_back_header {
    position: absolute;
    left: 0px;
    padding: 10px 14px;
    background: none;
    cursor: pointer;
  }
  .title_header {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }

  .theme_standard {
  }

  &.theme_bg_transparent {
    background-image: unset;
    background: transparent;
  }
`;
