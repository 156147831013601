import React from "react";
import { connect } from "react-redux";
import { AgreementPublicInfoLogAgainContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import LoginContainer from "containers/LoginContainer";
import { ROUTE_PATH } from "utils/constants/routePath";

class AgreementPublicInfoLogAgainContainer extends React.Component {
  handleVerifySuccess = () => {
    const { financeRequestRedux } = this.props;
    console.log("rrr", financeRequestRedux);
    if (!financeRequestRedux) {
    } else {
      if (financeRequestRedux?.agreement_public_info) {
        this.props.router.navigate(ROUTE_PATH.NCB_CONSENT);
      } else {
        this.props.router.navigate(ROUTE_PATH.REGISTER_FINANCE_SUCCESS);
      }
    }
  };

  render() {
    const { financeRequestRedux } = this.props;
    return (
      <AgreementPublicInfoLogAgainContainerStyled>
        <LoginContainer onVerifySuccess={this.handleVerifySuccess} />
      </AgreementPublicInfoLogAgainContainerStyled>
    );
  }
}
const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  // setReduxFinanceRequestAgreementPublicInfo: (data) =>
  // dispatch(setReduxFinanceRequestAgreementPublicInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgreementPublicInfoLogAgainContainer));
