import AccountInfoCreateForm from "./AccountInfoCreateForm";
import AccountInfoForm from "./AccountInfoForm";
import AddressInfoForm from "./AddressInfoForm";
import AgreementPublicInfoForm from "./AgreementPublicInfoForm";
import AuthenTestStep1Form from "./AuthenTestStep1Form";
import AuthenTestStep2Form from "./AuthenTestStep2Form";
import CreditLimitEditForm from "./CreditLimitEditForm";
import CusInfoForm from "./CusInfoForm";
import EmpGeneralInfoForm from "./EmpGeneralInfoForm";
import LoginForm from "./LoginForm";
import NCBConsentSummaryForm from "./NCBConsentSummaryForm";
import PinOTPVerifyForm from "./PinOTPVerifyForm";
import PrivacyPolicyForm from "./PrivacyPolicyForm";
import RefinanceCreditDocForm from "./RefinanceCreditDocForm";
import RegisCusInfoForm from "./RegisCusInfoForm";
import ServiceProviderSortForm from "./ServiceProviderSortForm";
import TermAndConditionForm from "./TermAndConditionForm";
import UploadDocEtcForm from "./UploadDocEtcForm";
import UploadDocType1Form from "./UploadDocType1Form";
import UploadDocType2Form from "./UploadDocType2Form";
import WorkInfoType1Form from "./WorkInfoType1Form";
import WorkInfoType2Form from "./WorkInfoType2Form";

const EXPORT = {
  AccountInfoCreateForm,
  AccountInfoForm,
  AddressInfoForm,
  AgreementPublicInfoForm,
  AuthenTestStep1Form,
  AuthenTestStep2Form,
  CreditLimitEditForm,
  CusInfoForm,
  EmpGeneralInfoForm,
  LoginForm,
  NCBConsentSummaryForm,
  PinOTPVerifyForm,
  PrivacyPolicyForm,
  RefinanceCreditDocForm,
  RegisCusInfoForm,
  ServiceProviderSortForm,
  TermAndConditionForm,
  UploadDocEtcForm,
  UploadDocType1Form,
  UploadDocType2Form,
  WorkInfoType1Form,
  WorkInfoType2Form,
};

export default EXPORT;
