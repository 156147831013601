export default function renderIcon({
  width = "13",
  height = "13",
  color = "#ED008C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.98108 5.27148L6.98545 8.27585L9.98982 5.27148"
        stroke="#F56CBC"
        strokeWidth="1.66909"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
