import styled from "styled-components";

export const ResultApprovalContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-image: url("/assets/images/backgrounds/bg-2.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  .body_container {
    flex: 1;
    padding: 30px 12px 12px 12px;
    .finance_info_layout {
      margin-bottom: 22px;
    }
    .block {
      border-radius: 10px;
      box-shadow: 0px 2.58px 5px 0px #00000014;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      overflow: hidden;
      .top_b {
        padding: 22px 16px 12px 16px;
        background: ${({ theme }) => theme.COLORS.GRAY_7};
      }
      .bottom_b {
        padding: 16px 42px 54px 42px;
      }
      .approve_f {
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer {
    padding: 18px 10px;
  }

  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
  font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
`;
