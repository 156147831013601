import styled from "styled-components";

export const AlertStyled = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  z-index: 99;
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
  .backdrop {
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.COLORS.BLACK_1}D6;
  }
  .alert_body {
    position: relative;
    border-radius: 16px;
    width: 100%;
    max-width: 240px;
    padding: 20px 27px 18px 27px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .btn_alert_close {
      position: absolute;
      top: 15px;
      right: 10px;
      padding: 2px;
      cursor: pointer;
    }
    .alert_title {
      margin-bottom: 16px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
      font-family: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
    }
    .alert_description {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_9};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-family: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .alert_action_row {
      margin-top: 30px;
      display: flex;
      column-gap: 16px;
      .alert_action_col {
        flex: 1;
        text-align: center;
        .btn_alert_cancel {
          border-radius: 5px;
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
          padding: 6px 0px;
          width: 100%;
          max-width: 120px;
          text-align: center;
          color: ${({ theme }) => theme.COLORS.GRAY_2};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S13};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          font-family: inherit;
          cursor: pointer;
        }
        .btn_alert_confirm {
          border-radius: 5px;
          padding: 7px 1px;
          width: 100%;
          max-width: 120px;
          background: linear-gradient(
            102.16deg,
            #ed008c 57.64%,
            #ff9300 97.99%
          );
          text-align: center;
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S13};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          font-family: inherit;
          cursor: pointer;
        }
      }
    }
  }
`;
