import React from "react";
import { NCBConsentContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Headers } from "components";
import { AlertControl } from "components/Modals/Alert";

class NCBConsentContainer extends React.Component {
  handleClickDownload = () => {
    AlertControl.show({
      title: "ดาวน์โหลดเอกสาร",
      description: "ดาวน์โหลดเอกสารสำเร็จ",
      isClose: true,
      btnLabel2: "ตกลง",
    });
  };

  handleNext = () => {
    this.props.router.navigate(ROUTE_PATH.REGISTER_FINANCE_SUCCESS);
  };

  render() {
    return (
      <NCBConsentContainerStyled>
        <Headers.Normal title="ดาวน์โหลดเอกสาร" />
        <div className="title">
          ความยินยอมในการเปิดเผยข้อมูล โดยวิธีการผ่านระบบอินเทอร์เน็ต
        </div>
        <div className="body_sec">
          เงื่อนไขในการให้ความยินยอมเปิดเผยข้อมูลผ่านระบบ อินเทอร์เน็ต
          ข้าพเจ้าทราบดีว่า การให้ความยินยอมผ่านระบบอินเทอร์เน็ต จะมีลักษณะเป็น
          "ข้อมูลอิเล็กทรอนิกส์" และเป็นข้อความที่ได้ สร้าง ส่ง รับ เก็บรักษา
          หรือประมวลผลด้วยวิธีทาง อิเล็กทรอนิกส์
          ซึ่งจะมีผลเป็นการให้ความยินยอมในการเปิด เผยหรือใช้ข้อมูลของข้าพเจ้า
          ตามกฎหมายว่าด้วยการ
          ประกอบธุรกิจข้อมูลเครดิตและข้าพเจ้าจะไม่ยกเลิกเพิกถอน
          หรือปฏิเสธความยินยอมนี้ เพราะเหตุที่เป็นข้อมูล อิเล็กทรอนิกส์
          วิธีการให้ควางยินิยอมปิตผยบัสนสผ่าแรยงอิน(ทอร์เน็ต 1.
          ผู้ให้ความยืนยอมจะต้องกรอกข้อมูลที่ถูกต้อง
          2.ผู้ให้ความยินยอมอจส่งผ่ความยินย์อมด้วยตนเอง หรือ
          มอบหมายให้บุคคลใดส่งผ่านก็ได้
          3.การให้ความยินยอมโดยวิธีการผ่านระบบอินเทอร์เน็ต ให้
          ถือว่ามีการลงลายมือชื่อแล้ว เมื่อได้ดำเนินการตามขั้นตอน
          การยืนยันตัวตนและการใช้รหัสอ้างอิง ข้าพเจ้า ชื่อ....................
          นามสกุล ................... วัน/เดือน/ปี พ.ศ. เกิต บัตรประจำตัวประชาชน
          หรือหนังสือเดินทาง
        </div>
        <div className="footer_sec">
          {/* <div className="download_wrap">
            <Buttons.IconSquarePrefix
              icon={<Icons.Download width="20" height="20"/>}
              label="ดาวน์โหลดเอกสาร"
              onClick={this.handleClickDownload}
            />
          </div> */}
          <Buttons.BgStandard label="ดำเนินการต่อ" onClick={this.handleNext} />
        </div>
      </NCBConsentContainerStyled>
    );
  }
}

export default withRouter(NCBConsentContainer);
