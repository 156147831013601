import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ApproveStatusStyled } from "./styled";

const ApproveStatus = ({ theme_standard, icon, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ApproveStatusStyled className={customClass}>
      {icon} {label}
    </ApproveStatusStyled>
  );
};

ApproveStatus.propTypes = {};

export default ApproveStatus;
