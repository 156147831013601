import styled from "styled-components";

export const TextNumberFieldStyled = styled.div`
  .label {
    margin-bottom: 2px;
    min-height: 21px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    overflow: hidden;
    white-space: nowrap;
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  input {
    border-radius: 8px;
    border: 1px solid
      ${({ theme, err }) => (err ? theme.COLORS.RED_1 : theme.COLORS.PINK_3)};
    padding: 6.5px 12px;
    width: calc(100% - 26px);
    background: ${({ theme, disabled }) =>
      !disabled ? "transparent" : theme.COLORS.GRAY_8};
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-family: inherit;
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    &::placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type="number"] {
    -moz-appearance: textfield; /* Firefox */
  }

  input:focus {
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
