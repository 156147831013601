import styled from "styled-components";

export const SystemLayoutStyled = styled.div`
  .body_mainlayout {
    margin: auto;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO};
    min-height: 100vh;
  }
`;
