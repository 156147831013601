import styled from "styled-components";

export const WorkInfoType1FormStyled = styled.div`
  .f_title {
    margin-bottom: 9px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .input_row {
    margin-bottom: 20px;
    display: flex;
    column-gap: 16px;
    &.mb_14 {
      margin-bottom: 14px;
    }
    &.mb_18 {
      margin-bottom: 18px;
    }
    &.mb_27 {
      margin-bottom: 27px;
    }
    .input_col {
      flex: 1;
    }
  }
  .input_wrap {
    margin-bottom: 20px;
    &.mb_27 {
      margin-bottom: 27px;
    }
  }
  .line_horizontal {
    margin-bottom: 27px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.GRAY_10};
  }
`;
