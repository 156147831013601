import styled from "styled-components";

export const RefinanceSuccessContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    display: flex;
    flex-direction: column;
    .top_block {
      border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
      padding: 32px 16px 16px 16px;
      text-align: center;
      .title {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .desc {
        margin-bottom: 16px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .pin_success_img {
        margin-bottom: 16px;
        width: 185px;
        height: auto;
      }
      .process_label {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
    }
    .bottom_block {
      padding: 16px;
      .rec_label {
        margin-bottom: 100px;
        text-align: left;
        color: ${({ theme }) => theme.COLORS.GRAY_2};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        ul {
          margin: 0;
          padding-left: 20px;
          text-align: left;
          li {
            color: ${({ theme }) => theme.COLORS.PINK_4};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
          }
        }
      }
      .contact_label {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
    }
  }
  .footer {
    padding: 18px 12px;
  }
`;
