import React from "react";
import { PinOtpContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Forms, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class PinOtpContainer extends React.Component {
  handleSubmit = () => {
    const { onOtpSuccess, router } = this.props;
    if (!onOtpSuccess) {
      let queryString = "";
      if (router.location.search) {
        queryString = router.location.search;
      }
      this.props.router.navigate(
        !queryString
          ? ROUTE_PATH.PIN_RESET
          : `${ROUTE_PATH.PIN_RESET}${queryString}`
      );
    } else {
      onOtpSuccess();
    }
  };

  render() {
    const { phoneNumber } = this.props;
    return (
      <PinOtpContainerStyled>
        <Headers.Normal title="ยืนยัน OTP" />
        <div className="body_container">
          <Forms.PinOTPVerifyForm
            phoneNumber={phoneNumber}
            onSubmit={this.handleSubmit}
          />
        </div>
      </PinOtpContainerStyled>
    );
  }
}

export default withRouter(PinOtpContainer);
