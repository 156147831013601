import styled from "styled-components";

export const RegisCusInfoFormStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .f_input_container {
      flex: 1;
      .input_wrap {
        margin-bottom: 20px;
        &.mb_34 {
          margin-bottom: 34px;
        }
      }
    }
  }
`;
