import { useState } from "react";
import { InputOTPWidgetStyled } from "./styled";
import { moneyDDService } from "apiServices";
import { toast } from "react-toastify";
import { Displays, Icons } from "components";

const InputOTPWidget = ({
  label,
  isRequire,
  onRequestOTPSuccess,
  ...props
}) => {
  const [_isBgLoading, _setIsBgLoading] = useState(false);
  const [_value, _setValue] = useState("");

  const _renderFormat = (e) => {
    if (!e) {
      return e;
    } else {
      const subTwo = e.substring(0, 2);
      if ((subTwo === "02" || subTwo === "09") && e.length <= 11) {
        // ลบอักขระที่ไม่ใช่เลข
        const numbers = e.replace(/\D/g, "");

        // จัดรูปแบบตัวเลขเป็น 99-999-9999
        let formatted = "";

        if (numbers.length <= 2) {
          formatted = numbers;
        } else if (numbers.length <= 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
        } else if (numbers.length <= 9) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5)}`;
        } else {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5, 9)}${numbers.slice(9)}`;
        }

        // เติมเครื่องหมาย - ถ้าจำเป็น
        if (numbers.length > 2 && numbers.length <= 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
        } else if (numbers.length > 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5)}`;
        }
        return formatted;
      } else {
        // ลบอักขระที่ไม่ใช่เลข
        const numbers = e.replace(/\D/g, "");

        // จัดรูปแบบตามความยาว
        let formatted = "";

        if (numbers.length <= 3) {
          formatted = numbers;
        } else if (numbers.length <= 7) {
          formatted = `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
        } else {
          formatted = `${numbers.slice(0, 3)}-${numbers.slice(
            3,
            7
          )}-${numbers.slice(7)}`;
        }
        return formatted;
      }
    }
  };

  const _handleClickRequestOTP = async () => {
    _setIsBgLoading(true);
    let params = {
      deviceId: "RP1A.200720.011",
      mobileNumber: _value.replace(/-/g, ""),
    };
    let res = await moneyDDService.POST_LOGIN(params);
    if (res && res.respCode === 2000) {
      localStorage.setItem("moneyDDToken", res.data.accessToken);
      _setIsBgLoading(false);
      onRequestOTPSuccess && onRequestOTPSuccess({ ...params, ...res.data });
    } else {
      _setIsBgLoading(false);
      toast.error(res?.respMsg || "ไม่สามารถใช้งานได้ กรุณาติดต่อเจ้าหน้าที่");
    }
  };

  return (
    <InputOTPWidgetStyled>
      <Displays.BGLoading visible={_isBgLoading} />
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      <div className="ipotp_container">
        <div className="ipotp_block">
          <div className="ipotp_icon_wrap">
            <Icons.Phone />
          </div>
          <input
            {...props}
            value={_renderFormat(_value)}
            onChange={(e) => {
              if (e.target.value.length < 13) {
                _setValue(e.target.value);
              }
            }}
          />
        </div>
        <button
          className="btn_otp"
          type="button"
          disabled={!(_value.length === 12 || _value.length === 11)}
          onClick={_handleClickRequestOTP}
        >
          ส่ง OTP
        </button>
      </div>
    </InputOTPWidgetStyled>
  );
};

export default InputOTPWidget;
