import React from "react";
import { connect } from "react-redux";
import { Step6UploadDocContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxStep6UploadDocInfo } from "store/reducers/financeRequestReducer";
import { Displays, Forms, Headers } from "components";

class Step6UploadDocContainer extends React.Component {
  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    this.props.setReduxStep6UploadDocInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG_COMFIRMATION);
  };

  render() {
    const { financeRequestRedux } = this.props;
    return (
      <Step6UploadDocContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="step_wrap">
            <Displays.FinanceRegisStep
              title="แนบเอกสารที่เกี่ยวข้อง"
              subTitle="กรุณาอัปโหลดเอกสารที่เกี่ยวข้องสำหรับสมัครสินเชื่อ"
              stepCurrent={6}
              total={6}
            />
          </div>
          {financeRequestRedux?.occupation_type?.value === "EMPLOYEE" && (
            <Forms.UploadDocType1Form onSubmit={this.handleSubmit} />
          )}
          {financeRequestRedux?.occupation_type?.value === "SELF_EMPLOYED" && (
            <Forms.UploadDocType2Form onSubmit={this.handleSubmit} />
          )}
        </div>
      </Step6UploadDocContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxStep6UploadDocInfo: (data) =>
    dispatch(setReduxStep6UploadDocInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step6UploadDocContainer));
