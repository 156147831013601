export default function renderIcon({
  width = "18",
  height = "18",
  color = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2001_620)">
        <path
          d="M16.2636 10.4934H1.73647C0.909981 10.4934 0.242432 9.8259 0.242432 8.99941C0.242432 8.17292 0.909981 7.50537 1.73647 7.50537H16.2636C17.0901 7.50537 17.7576 8.17292 17.7576 8.99941C17.7576 9.8259 17.0901 10.4934 16.2636 10.4934Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2001_620">
          <rect
            width="17.5152"
            height="17.5152"
            fill={color}
            transform="translate(0.242432 0.242676)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
