import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ReFinanceItemStyled } from "./styled";
import { Icons } from "components";

const ReFinanceItem = ({ theme_standard, isActive, onDelete }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ReFinanceItemStyled className={customClass} active={isActive ? 1 : 0}>
      <div className="fni_row mb_8">
        <div className="fni_no">2/10</div>
        <div className="btn_fni_delete" onClick={onDelete}>
          <Icons.Bin />
        </div>
      </div>
      <div className="fni_row mb_4">
        <div className="fni_label">ผู้ให้บริการทางการเงิน</div>
        <div className="fni_label">Amount</div>
      </div>
      <div className="fni_row">
        <div className="fni_label">บริษัท กรุงศรีออโต้</div>
        <div className="fni_label">60,000.00 บาท</div>
      </div>
    </ReFinanceItemStyled>
  );
};

ReFinanceItem.propTypes = {};

export default ReFinanceItem;
