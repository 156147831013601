import styled from "styled-components";

export const CheckboxContainer = styled.div`
  .checkbox_container {
    display: flex;
    align-items: center;
    column-gap: 6px;
    cursor: pointer;
    .checkbox_label {
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .errors {
    margin-top: 2px;
    padding-left: 16px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
  a {
    color: ${({ theme }) => theme.COLORS.GREEN_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
`;
