import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TrackingStepStyled, StepperContainer } from "./styled";
import { Icons } from "components";

const TrackingStep = ({ theme_standard, currentStep, disabled, steps }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TrackingStepStyled className={customClass}>
      <div className="tks_title">สถานะการสมัครสินเชื่อของท่าน</div>
      <div className="tks_ref_label">เลขที่อ้างอิง</div>
      <div className="tks_ref_value">MDD24052400011</div>
      <div className="tsk_step">
        <StepperContainer
          className={customClass}
          disabled={disabled}
          steps={steps.length}
          activeStep={currentStep}
          percent={
            (currentStep * 100) / steps.length +
            (currentStep * (100 / steps.length)) / 2
          }
        >
          {steps.map((step, index) => (
            <div
              key={index}
              className={`step_button ${index <= currentStep ? "active" : ""}`}
            >
              <div className="step_circle">
                <Icons.RadioCircle2
                  width="20"
                  height="20"
                  active={index <= currentStep}
                />
              </div>
              {typeof step === "string" ? (
                <div className="step_label">{step}</div>
              ) : (
                <div className="step_label">
                  {step.map((e, i) => (
                    <div>{e}</div>
                  ))}
                </div>
              )}
            </div>
          ))}
          <div className="connector" />
        </StepperContainer>
      </div>
    </TrackingStepStyled>
  );
};

TrackingStep.propTypes = {};

export default TrackingStep;
