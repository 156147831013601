import { forwardRef, useState, useEffect } from "react";
import { RadioContainer } from "./styled";
import cx from "classnames";
import { Icons } from "components";

const Radio = forwardRef((props, ref) => {
  const {
    theme_standard,
    desc,
    label,
    value,
    onChange,
    errors,
    options,
    isRequire,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (value && !_value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onChange = (e) => {
    _setValue(e.value);
    onChange && onChange(e.value);
  };

  return (
    <RadioContainer className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      {desc && <div className="rd_desc">{desc}</div>}
      <div className="radio_container" ref={ref}>
        {options &&
          options.map((e, i) => (
            <div
              key={i}
              className={`radio_option_item ${
                _value === e.value ? "active" : ""
              }`}
              onClick={() => _onChange(e)}
            >
              {_value === e.value ? (
                <Icons.RadioCircle active />
              ) : (
                <Icons.RadioCircle />
              )}
              {e.label}
            </div>
          ))}
      </div>
      {errors && <div className="errors">{errors}</div>}
    </RadioContainer>
  );
});

export default Radio;
