import React from "react";
import { connect } from "react-redux";
import { Step1GeneralInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import {
  setReduxFinanceRequest,
  setReduxStep1GeneralInfo,
} from "store/reducers/financeRequestReducer";
import { moneyDDService } from "apiServices";
import { RENDER_MASTER_DATA_OPTION } from "utils/functions/getObjectKey";
import { Displays, Forms, Headers } from "components";

class Step1GeneralInfoContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [],
      target_fields: ["_occupation.employType"],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      this.setState({
        occupationLv1Options: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[0]
        ),
        isLoading: false,
      });
    }
  };

  handleChangeOccupation = (e) => {
    this.setState({
      occupationValue: e,
    });
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    // let res = await moneyDDService.POST_REGIS_STEP_1()
    this.props.setReduxStep1GeneralInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG2_CUS_INFO);
  };

  render() {
    const { isLoading, occupationValue, occupationLv1Options } = this.state;
    return (
      <Step1GeneralInfoContainerStyled>
        <Headers.TitleCarousel
          title="สมัครสินเชื่อรีไฟแนนซ์"
          type={occupationValue}
        />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_container">
            <div className="step_wrap">
              <Displays.FinanceRegisStep
                title="ข้อมูลทั่วไปของผู้ขอสินเชื่อ"
                stepCurrent={1}
                total={6}
              />
            </div>
            <Forms.EmpGeneralInfoForm
              occupationLv1Options={occupationLv1Options}
              onChangeOccupation={this.handleChangeOccupation}
              onSubmit={this.handleSubmit}
            />
          </div>
        )}
      </Step1GeneralInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxFinanceRequest: (data) => dispatch(setReduxFinanceRequest(data)),
  setReduxStep1GeneralInfo: (data) => dispatch(setReduxStep1GeneralInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step1GeneralInfoContainer));
