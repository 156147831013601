import styled from "styled-components";

export const ServiceProviderSortFormStyled = styled.div`
  .f_container {
    margin-bottom: 31px;
    .service_item_wrap {
      display: flex;
      align-items: center;
      column-gap: 4px;
      .sv_col {
        flex: 1;
      }
    }
  }
`;
