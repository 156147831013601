import styled from "styled-components";

export const LoginFormStyled = styled.div`
  padding-top: 32px;
  flex: 1;
  display: flex;
  flex-direction: column;
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .form_container {
      padding: 0 20px;
      flex: 1;
      .f_title {
        margin-bottom: 24px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .figture {
        text-align: center;
        .logo_img {
          margin-bottom: 7px;
          width: 75px;
          height: auto;
        }
      }
      .input_wrap {
        margin-bottom: 22px;
        .coutdown_label {
          color: ${({ theme }) => theme.COLORS.GRAY_14};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
      }
    }
    .btn_action {
      padding: 20px;
      .desc {
        margin-bottom: 32px;
        color: ${({ theme }) => theme.COLORS.BLACK_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        span {
          color: ${({ theme }) => theme.COLORS.BLUE_2};
          cursor: pointer;
        }
      }
    }
  }
`;
