import styled from "styled-components";

export const RequestFinanceAgainContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    padding: 25px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    .bd_block {
      flex: 1;
      border-radius: 10px;
      box-shadow: 2px 4px 15px 0px #0000001a;
      padding: 69px 48px 48px 48px;
      text-align: center;
      .title {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        &.mb_16 {
          margin-bottom: 26px;
        }
      }
      .desc {
        margin-bottom: 44px;
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_9};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .pin_success_img {
        margin-bottom: 30px;
        width: 150px;
        height: auto;
      }

      .desc_sec2 {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        &.mb_15 {
          margin-bottom: 20px;
        }
      }
    }
  }
  .footer {
    padding: 26px 20px 18px 20px;
  }
`;
