export default function renderIcon({
  width = "17",
  height = "17",
  color = "#FF0000",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.37 15.74C12.4403 15.74 15.74 12.4403 15.74 8.37C15.74 4.29966 12.4403 1 8.37 1C4.29966 1 1 4.29966 1 8.37C1 12.4403 4.29966 15.74 8.37 15.74Z"
        stroke={color}
        strokeWidth="1.11"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.1303 7.19864C11.328 7.00093 11.3184 6.67191 11.1089 6.46238C10.8993 6.25284 10.5703 6.24328 10.3726 6.44099L8.76413 8.04945L7.05941 6.34473C6.84987 6.13519 6.52085 6.12563 6.32315 6.32334C6.12544 6.52105 6.135 6.85006 6.34454 7.0596L8.04926 8.76432L6.44079 10.3728C6.24309 10.5705 6.25265 10.8995 6.46218 11.1091C6.67172 11.3186 7.00074 11.3281 7.19845 11.1304L8.80691 9.52198L10.5116 11.2267C10.7212 11.4362 11.0502 11.4458 11.2479 11.2481C11.4456 11.0504 11.436 10.7214 11.2265 10.5118L9.52179 8.8071L11.1303 7.19864Z"
        fill={color}
      />
    </svg>
  );
}
