import styled from "styled-components";

export const UploadImgFieldStyled = styled.div`
  .btn_upm_item {
    margin-bottom: 7px;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    background: ${({ theme, disabled }) =>
      disabled
        ? theme.COLORS.GRAY_1
        : "linear-gradient(102.16deg, #ED008C 57.64%, #FF9300 97.99%)"};
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    font-family: inherit;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0px;
    }
    .value_txt {
      min-width: 80px;
    }
  }
`;
