import React from "react";
import { StandardStyled } from "./styled";
import cx from "classnames";
import { useTable } from "react-table";
// import { Displays } from "components";

export const Standard = ({
  columns,
  data,
  // pagination,
  // showPagination,
  // handlePageClick,
  onClickRow,
}) => {
  const customClass = cx({});

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <StandardStyled>
      <div className={customClass}>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        onClick={() => onClickRow && onClickRow(row.original)}
                      >
                        <div>{cell.render("Cell")}</div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* {showPagination && pagination && data && data.length > 0 && (
          <div className="pagi_layout">
            <Displays.PaginationCustom
              theme_standard_pagination
              totalPages={pagination?.last_page}
              currentPageData={pagination?.current_page - 1}
              _handlePageClick={handlePageClick}
            />
          </div>
        )} */}
      </div>
    </StandardStyled>
  );
};

export default Standard;
