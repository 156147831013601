import styled from "styled-components";

export const RefinanceInfoContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    padding: 16px 20px;
    .refinane_wrap {
      margin-bottom: 16px;
    }
    .note {
      color: ${({ theme }) => theme.COLORS.RED_3};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .footer {
    padding: 18px 10px;
  }
`;
