import React from "react";
import { CusInfoFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const CusInfoForm = ({
  educationOptions,
  genderOptions,
  maritalStatusOptions,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    education_level: yup.object().nullable().required("Required"),
    gender: yup.object().nullable().required("Required"),
    marital_status: yup.object().nullable().required("Required"),
    num_working_children: yup.object().nullable().required("Required"),
    num_non_working_children: yup.object().nullable().required("Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <CusInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_input_container">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownField
                  {...field}
                  isRequire
                  label={"ระดับการศึกษา"}
                  options={educationOptions}
                  placeholder="กรุณากรอกระดับการศึกษา"
                  errors={errors.education_level?.message}
                />
              )}
              name="education_level"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownField
                  {...field}
                  isRequire
                  label={"เพศ"}
                  options={genderOptions}
                  placeholder="กรุณากรอกเพศ"
                  errors={errors.gender?.message}
                />
              )}
              name="gender"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownField
                  {...field}
                  isRequire
                  label={"สถานภาพ"}
                  options={maritalStatusOptions}
                  placeholder="กรุณากรอกสถานภาพ"
                  errors={errors.marital_status?.message}
                />
              )}
              name="marital_status"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownField
                  {...field}
                  isRequire
                  options={[
                    { label: "ไม่มี", value: "0" },
                    { label: "1คน", value: "1" },
                    { label: "2คน", value: "2" },
                    { label: "3คน", value: "3" },
                  ]}
                  // regexFormat={/^\d+$/}
                  label={"จำนวนบุตรที่ทำงานแล้ว"}
                  errors={errors.num_working_children?.message}
                />
              )}
              name="num_working_children"
              defaultValue={{ label: "ไม่มี", value: "0" }}
            />
          </div>
          <div className="input_wrap mb_34">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DropdownField
                  {...field}
                  isRequire
                  options={[
                    { label: "ไม่มี", value: "0" },
                    { label: "1คน", value: "1" },
                    { label: "2คน", value: "2" },
                    { label: "3คน", value: "3" },
                  ]}
                  label={"จำนวนบุตรที่ไม่ได้ทำงาน"}
                  errors={errors.num_non_working_children?.message}
                />
              )}
              name="num_non_working_children"
              defaultValue={{ label: "ไม่มี", value: "0" }}
            />
          </div>
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ดำเนินการต่อ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </CusInfoFormStyled>
  );
};

CusInfoForm.propTypes = {};

export default CusInfoForm;
