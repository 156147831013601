import styled from "styled-components";

export const OtpFieldStyled = styled.div`
  width: 100%;
  .otp_input_container {
    width: 100%;
    .label {
      margin-bottom: 6px;
      display: flex;
      align-items: center;
      column-gap: 8px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      span {
        color: ${({ theme }) => theme.COLORS.RED_1};
      }
      .sub_label {
        color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }
    .otp_input {
      width: 100%;
      column-gap: 9px;
      .input_item {
        border-radius: 7px;
        padding: 5% 0;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_1};
        width: 100% !important;
        background: ${({ theme, disabled }) =>
          disabled ? theme.COLORS.GRAY_1 : theme.COLORS.WHITE_1};
        color: ${({ theme }) => theme.COLORS.PINK_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
    .errors {
      margin-top: 6px;
      padding-left: 16px;
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }

  .theme_standard {
  }

  &.theme_email {
    .otp_input_container {
      .label {
        margin-bottom: 25px;
      }
    }
  }
`;
