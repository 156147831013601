import styled from "styled-components";

export const FinanceSumApproveStyled = styled.div`
  .chf_title {
    margin-bottom: 8px;
    color: ${({ theme }) => theme.COLORS.PINK_6};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .chr_container {
    border-radius: 12px;
    box-shadow: 0px 4px 8px -2px #1018281a;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    overflow: hidden;
    .chf_row {
      padding: 10px 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 6px;
      &.pd_12 {
        padding: 12px;
      }
      &.gray {
        background: ${({ theme }) => theme.COLORS.GRAY_7};
      }
      .chf_label {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        &.pink {
          color: ${({ theme }) => theme.COLORS.PINK_6};
        }
      }
      .chf_value {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        &.pink {
          border-radius: 8px;
          padding: 4px 8px;
          background: ${({ theme }) => theme.COLORS.GRAY_7};
          color: ${({ theme }) => theme.COLORS.PINK_6};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
        }
      }
    }
  }

  &.theme_standard {
  }
`;
