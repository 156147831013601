import { useState } from "react";
import { SystemLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";
import { Displays } from "components";

const SystemLayout = () => {
  const [
    _isLoading,
    // _setIsLoading
  ] = useState(false);

  return (
    <SystemLayoutStyled>
      <div className="body_mainlayout">
        {_isLoading ? <Displays.Loading /> : <Outlet />}
      </div>
    </SystemLayoutStyled>
  );
};

export default SystemLayout;
