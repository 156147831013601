import React from "react";
import { ReceiveDocContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms, Headers } from "components";

class ReceiveDocContainer extends React.Component {
  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_CONSENT);
  };
  render() {
    return (
      <ReceiveDocContainerStyled>
        <Headers.Normal title="ยืนยันการเลือกรีไฟแนนซ์" />
        <Forms.RefinanceCreditDocForm onSubmit={this.handleSubmit} />
      </ReceiveDocContainerStyled>
    );
  }
}

export default withRouter(ReceiveDocContainer);
