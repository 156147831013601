import styled from "styled-components";

export const AccountInfoCreateFormStyled = styled.div`
  .f_title1 {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.PINK_6};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .f_title2 {
    color: ${({ theme }) => theme.COLORS.GRAY_11};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .f_title3 {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.PINK_6};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .input_wrap {
    margin-bottom: 12px;
    &.mb_20 {
      margin-bottom: 20px;
    }
  }
  .line_horizontal {
    margin-bottom: 12px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.GRAY_3}80;
  }
  .upload_block {
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    padding: 12px;
  }
  .account_info_block {
    margin-bottom: 12px;
    .aib_row {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      column-gap: 6px;
      .aib_item {
        color: ${({ theme }) => theme.COLORS.GRAY_11};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
  }
`;
