import styled from "styled-components";

export const RadioBoxContainer = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .rd_desc {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .radio_container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3.5px;
    row-gap: 6px;
    .radio_option_item {
      flex-basis: calc(20% - 15px);
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_1};
      padding: 8px 5px 11px 5px;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PINK_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      cursor: pointer;
      &.active {
        background: ${({ theme }) => theme.COLORS.PINK_1};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
      }
      &.disabled {
        border-color: ${({ theme }) => theme.COLORS.GRAY_1};
        background: ${({ theme }) => theme.COLORS.GRAY_1};
        cursor: not-allowed;
      }
    }
  }

  .errors {
    margin-top: 2px;
    padding-left: 16px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  &.theme_three {
    .label {
      margin-bottom: 10px;
    }
    .radio_container {
      .radio_option_item {
        flex-basis: calc(33% - 15px);
      }
    }
  }
`;
