import React, { useEffect, useState } from "react";
import { AccountInfoFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UploadMediaWidget } from "widgets";
import { Buttons, Cards, Displays, Icons, Inputs, Modals } from "components";

const AccountInfoForm = ({
  bankOptions,
  initialValues,
  onAddFinance,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    loan_account: yup
      .array()
      .min(1)
      .of(
        yup.object().shape({
          service_provider: yup.object().nullable().required("กรุณากรอก"),
          total_debt: yup.string().required("กรุณากรอก"),
          monthly_installment: yup.string().required("กรุณากรอก"),
          // ref1: yup.string().required("กรุณากรอก"),
          // ref2: yup.string().required("กรุณากรอก"),
          latest_invoice: yup.array().required("กรุณากรอก"),
          // payment_receipt: yup.array().required("กรุณากรอก"),
        })
      ),
  });

  const {
    control,
    handleSubmit,
    reset,
    getValues,
    watch,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields,
    // append,
    remove,
  } = useFieldArray({
    control,
    name: "loan_account",
  });

  const watchField = watch();

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const [_active, _setActive] = useState();
  const [_renderModal, _setRenderModal] = useState(false);

  const _handleClickActive = (e) => {
    if (e === _active) {
      _setActive(false);
    } else {
      _setActive(e);
    }
  };

  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  return (
    <>
      <AccountInfoFormStyled>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="f_input_container">
            <div className="account_info_block">
              <div className="aci_label">
                *จำนวนบัญชีสินเชื่อที่ต้องการรีไฟแนนซ์สูงสุดไม่เกิน 10 บัญชี
              </div>
              <div className="aci_no">
                {fields.length}
                <span> / 10</span>
              </div>
            </div>
            {fields.map((item, index) => (
              <div key={String(index)} className={`finance_item is_card`}>
                <div
                  className="finance_card_block"
                  // onClick={() => _handleClickActive(index)}
                >
                  <Cards.FinanceItem
                    {...item}
                    no={index + 1}
                    serviceProvider={item?.service_provider?.label}
                    totalDebt={
                      watchField.loan_account &&
                      watchField.loan_account[index]?.total_debt
                    }
                    isActive={index === _active}
                    onClick={() => _handleClickActive(index)}
                    onDelete={() => {
                      _setRenderModal(
                        <Modals.TemplateDocumentDelete
                          desc={[
                            "คุณต้องการลบข้อมูล",
                            <>
                              <span style={{ color: "#ED018C" }}>
                                สินเชื่อ : {item.service_provider?.label}
                              </span>{" "}
                              ใช่หรือไ่ม่
                            </>,
                          ]}
                          onCancel={_handleCloseModal}
                          onConfirm={() => {
                            remove(index);
                            _handleCloseModal();
                          }}
                        />
                      );
                    }}
                    onUpdate={() => {
                      _setRenderModal(
                        <Modals.TemplateDocumentDelete
                          desc={[
                            "คุณต้องการบันทึกการแก้ไขข้อมูลในครั้งนี้ใช่หรือไม่",
                          ]}
                          onCancel={_handleCloseModal}
                          onConfirm={() => {
                            _handleCloseModal();
                          }}
                        />
                      );
                    }}
                  >
                    {index === _active && (
                      <div className="finace_form_block">
                        <div className="input_wrap">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Inputs.DropdownField
                                {...field}
                                isRequire
                                label="ผู้ให้บริการทางการเงิน"
                                options={bankOptions || []}
                                placeholder={"กรุณาเลือกผู้ให้บริการทางการเงิน"}
                                errors={
                                  errors.loan_account &&
                                  errors?.loan_account[index]?.service_provider
                                    ?.message
                                }
                              />
                            )}
                            name={`loan_account.${index}.service_provider`}
                            defaultValue=""
                          />
                        </div>
                        <div className="input_row">
                          <div className="input_col">
                            <Controller
                              control={control}
                              render={({ field }) => (
                                <Inputs.TextNumberFloat
                                  {...field}
                                  isRequire
                                  label="ยอดหนี้ทั้งหมด"
                                  regexFormat={/^\d+(\.\d+)?$/}
                                  placeholder="กรุณาระบุ"
                                  errors={
                                    errors.loan_account &&
                                    errors?.loan_account[index]?.total_debt
                                      ?.message
                                  }
                                />
                              )}
                              name={`loan_account.${index}.total_debt`}
                              defaultValue=""
                            />
                          </div>
                          <div className="input_col">
                            <Controller
                              control={control}
                              render={({ field }) => (
                                <Inputs.TextNumberFloat
                                  {...field}
                                  isRequire
                                  label="ยอดผ่อนต่อเดือน"
                                  regexFormat={/^\d+(\.\d+)?$/}
                                  placeholder="กรุณาระบุ"
                                  errors={
                                    errors.loan_account &&
                                    errors?.loan_account[index]
                                      ?.monthly_installment?.message
                                  }
                                />
                              )}
                              name={`loan_account.${index}.monthly_installment`}
                              defaultValue=""
                            />
                          </div>
                        </div>

                        <div className="input_wrap">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Inputs.TextField
                                {...field}
                                label="Ref1"
                                placeholder="กรุณาระบุ"
                                errors={
                                  errors.loan_account &&
                                  errors?.loan_account[index]?.ref1?.message
                                }
                              />
                            )}
                            name={`loan_account.${index}.ref1`}
                            defaultValue=""
                          />
                        </div>
                        <div className="input_wrap">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Inputs.TextField
                                {...field}
                                label="Ref2"
                                placeholder="กรุณาระบุ"
                                errors={
                                  errors.loan_account &&
                                  errors?.loan_account[index]?.ref2?.message
                                }
                              />
                            )}
                            name={`loan_account.${index}.ref2`}
                            defaultValue=""
                          />
                        </div>
                        <div className="info_list_wrap">
                          <Displays.InfomationList
                            data={[
                              "อัพโหลดเอกสาร รองรับไฟล์ JPG, PNG, PDF",
                              "จำเป็นต้องอัพโหลดเอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด อัพโหลดได้ไม่เกิน 6 ใบ",
                              "เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ที่ท่านอัพโหลดต้องมี QR Code หรือ Barcode ชัดเจน",
                            ]}
                          />
                        </div>
                        <div className="input_wrap">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <UploadMediaWidget
                                {...field}
                                isRequire
                                acceptType="cover"
                                label="เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด"
                                maxLength={6}
                                placeholder="อัปโหลดเอกสารที่นี่"
                                errors={
                                  errors.loan_account &&
                                  errors?.loan_account[index]?.latest_invoice
                                    ?.message
                                }
                              />
                            )}
                            name={`loan_account.${index}.latest_invoice`}
                            defaultValue=""
                          />
                        </div>
                        <Controller
                          control={control}
                          render={({ field }) => (
                            <UploadMediaWidget
                              {...field}
                              acceptType="cover"
                              label="ใบเสร็จรับเงินที่ชำระหนี้"
                              placeholder="อัปโหลดเอกสารที่นี่"
                              maxLength={6}
                              errors={
                                errors.loan_account &&
                                errors?.loan_account[index]?.payment_receipt
                                  ?.message
                              }
                            />
                          )}
                          name={`loan_account.${index}.payment_receipt`}
                          defaultValue=""
                        />
                      </div>
                    )}
                  </Cards.FinanceItem>
                </div>
              </div>
            ))}
            <button
              className="btn_add_finance"
              type="button"
              onClick={() => {
                if (fields.length < 10) {
                  _handleClickActive(fields.length);
                  // append({});
                  onAddFinance(getValues());
                } else {
                  _setRenderModal(
                    <Modals.TemplateDocumentDelete
                      title={["เพิ่มข้อมูลบัญชีสินเชื่อ", "รีไฟแนนซ์ไม่สำเร็จ"]}
                      imgSource={"/assets/images/icons/gmoney-not-add.png"}
                      desc={[
                        "คุณสามารถเพิ่มบัญชีสินเชื่อ",
                        "ได้ไม่เกิน 10 บัญชีเท่านั้น",
                      ]}
                      onConfirm={() => {
                        _handleCloseModal();
                      }}
                    />
                  );
                }
              }}
            >
              <div className="af_circle">
                <Icons.Plus width="12" height="13" />
              </div>
              <div className="af_label">
                เพิ่มบัญชีสินเชื่อที่ต้องการรีไฟแนนซ์
              </div>
            </button>
          </div>
          <Buttons.BgStandard
            type="submit"
            label="ดำเนินการต่อ"
            disabled={isSubmitting || !isValid}
          />
        </form>
      </AccountInfoFormStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal ? true : false}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </>
  );
};

AccountInfoForm.propTypes = {};

export default AccountInfoForm;
