import styled from "styled-components";

export const DropdownFieldStyled = styled.div`
  position: relative;
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    .sub_label {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .dropdown_input {
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
    padding: 6.25px 12px;
    width: calc(100% - 26px);
    min-height: 21.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: ${({ theme, disabled }) =>
      !disabled ? "transparent" : theme.COLORS.GRAY_1};
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-family: inherit;
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    cursor: pointer;
    .placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    &:focus {
    }
    .my_arrow_clasnName {
    }
  }
  .dropdown_content_block {
    position: absolute;
    left: 0px;
    margin-top: 4px;
    border-radius: 7px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
    width: 100%;
    max-height: 30vh;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    overflow: auto;
    z-index: 22;
    .option_item {
      padding: 12px 16px;
      width: calc(100% - 32px);
      display: flex;
      justify-content: space-between;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      cursor: pointer;
      &:hover {
        background: ${({ theme }) => theme.COLORS.GRAY_6};
        color: ${({ theme }) => theme.COLORS.PINK_4};
      }
    }
  }

  &.theme_standard {
  }
`;
