export default function renderIcon({
  width = "15",
  height = "18",
  color = "#C7C7C7",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.35352" cy="2" r="1.5" fill={color} />
      <circle cx="7.35352" cy="9" r="1.5" fill={color} />
      <circle cx="7.35352" cy="16" r="1.5" fill={color} />
    </svg>
  );
}
