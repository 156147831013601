import styled from "styled-components";

export const PrivacyPolicyContainerStyled = styled.div`
  border-radius: 16px;
  height: 80vh;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  overflow: auto;
  .title {
    margin-top: 12px;
    margin-bottom: 14px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .body_sec {
    flex: 1;
    padding: 16px 20px 22px 20px;
    overflow: auto;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .footer_sec {
    padding: 0px 12px 26px 12px;
    .version_label {
      margin-bottom: 20px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;
