export default function renderIcon({ width = "41", height = "41" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.0756836"
        y="0.691406"
        width="40.0582"
        height="40.0582"
        rx="8.01165"
        fill="#FDF2F9"
      />
      <path
        d="M22.1077 10.707H14.0961C13.5649 10.707 13.0554 10.9181 12.6798 11.2937C12.3042 11.6693 12.0932 12.1787 12.0932 12.7099V28.7332C12.0932 29.2644 12.3042 29.7739 12.6798 30.1495C13.0554 30.5251 13.5649 30.7362 14.0961 30.7362H26.1136C26.6448 30.7362 27.1542 30.5251 27.5298 30.1495C27.9054 29.7739 28.1165 29.2644 28.1165 28.7332V16.7158M22.1077 10.707L28.1165 16.7158M22.1077 10.707V16.7158H28.1165M24.1106 21.723H16.099M24.1106 25.7289H16.099M18.1019 17.7172H16.099"
        stroke="#ED018B"
        strokeWidth="2.00291"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
