import React from "react";
import { PrivacyPolicyContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Forms } from "components";

class PrivacyPolicyContainer extends React.Component {
  onRef = (e) => {
    this.scrollRef = e;
  };

  handleSubmit = (values) => {
    const { onCloseModal } = this.props;
    onCloseModal();
  };

  handleChangeAgreementTermCondition = (e) => {
    if (e) {
      this.scrollRef.scrollTo({
        top: this.scrollRef.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  render() {
    return (
      <PrivacyPolicyContainerStyled>
        <Forms.PrivacyPolicyForm
          onChangeAgreementTermCondition={
            this.handleChangeAgreementTermCondition
          }
          onSubmit={this.handleSubmit}
        />
      </PrivacyPolicyContainerStyled>
    );
  }
}

export default withRouter(PrivacyPolicyContainer);
