import styled from "styled-components";

export const AccountInfoFormStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .f_input_container {
      flex: 1;
      .account_info_block {
        margin-bottom: 18px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 6px;
        .aci_label {
          color: ${({ theme }) => theme.COLORS.PINK_4};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
        .aci_no {
          color: ${({ theme }) => theme.COLORS.PINK_4};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          span {
            color: ${({ theme }) => theme.COLORS.GRAY_10};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
        }
      }

      .finance_item {
        position: relative;
        overflow: hidden;
        &.is_card {
          margin-bottom: 10px;
          border-radius: 10px;
          box-shadow: 2px 4px 15px 0px #0000001a;
          .finance_card_block {
            display: block;
          }
        }
        &.active {
          &.only {
            .finance_card_block {
              .finace_form_block {
                display: block;
                padding: 0;
              }
            }
          }
          .finance_card_block {
            .finace_form_block {
              display: block;
              padding: 14px 16px 16px 16px;
            }
          }
        }
        .finance_card_block {
          display: none;
          cursor: pointer;
        }
        .finace_form_block {
          display: block;
          padding: 12px;
          .input_row {
            margin-bottom: 14px;
            display: flex;
            align-items: flex-end;
            column-gap: 16px;
            &.mb_18 {
              margin-bottom: 18px;
            }
            &.mb_27 {
              margin-bottom: 27px;
            }
            .input_col {
              flex: 1;
            }
          }
          .input_wrap {
            margin-bottom: 14px;
            &.mb_22 {
              margin-bottom: 22px;
            }
            &.mb_27 {
              margin-bottom: 27px;
            }
          }

          .info_list_wrap {
            margin-bottom: 16px;
          }
        }
      }

      .btn_add_finance {
        margin-bottom: 16px;
        border-radius: 10px;
        box-shadow: 2px 4px 15px 0px #0000001a;
        width: 100%;
        padding: 15.5px 12px;
        display: flex;
        align-items: center;
        column-gap: 11px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        font-family: inherit;
        cursor: pointer;
        .af_circle {
          border-radius: 30px;
          width: 30px;
          height: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: linear-gradient(
            102.16deg,
            #ed018c 24.66%,
            #ff9800 91.99%
          );
        }
        .af_label {
          color: ${({ theme }) => theme.COLORS.PINK_4};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
      }
    }
  }
`;
