import { ROUTE_PATH } from "utils/constants/routePath";
import TermAndConditionContainer from "containers/TermAndConditionContainer";
import Step1EmpGeneralInfoContainer from "containers/RegisFinanceSystem/Step1EmpGeneralInfoContainer";
import Step2CusInfoContainer from "containers/RegisFinanceSystem/Step2CusInfoContainer";
import Step3WorkInfoContainer from "containers/RegisFinanceSystem/Step3WorkInfoContainer";
import Step4AddressInfoContainer from "containers/RegisFinanceSystem/Step4AddressInfoContainer";
import Step5AccountInfoContainer from "containers/RegisFinanceSystem/Step5AccountInfoContainer";
import Step6UploadDocContainer from "containers/RegisFinanceSystem/Step6UploadDocContainer";
import SummaryConfirmContainer from "containers/RegisFinanceSystem/SummaryConfirmContainer";
import AgreementPublicInfoContainer from "containers/AgreementPublicInfoContainer";
import AgreementPublicInfoLogAgainContainer from "containers/AgreementPublicInfoLogAgainContainer";
import PinVerifyContainer from "containers/PinSystem/PinVerifyContainer";
import PinOtpContainer from "containers/PinSystem/PinOtpContainer";
import PinResetContainer from "containers/PinSystem/PinResetContainer";
import PinResetSuccessContainer from "containers/PinSystem/PinResetSuccessContainer";
import NCBConsentContainer from "containers/NCBConsentSystem/NCBConsentContainer";
import NCBConsentSummaryContainer from "containers/NCBConsentSystem/NCBConsentSummaryContainer";
import RegisterFinanceSuccessContainer from "containers/RegisterFinanceSuccessContainer";
import ApprovalStatusContainer from "containers/ConsiderFinanceSystem/ApprovalStatusContainer";
import RequestFinanceAgainContainer from "containers/ConsiderFinanceSystem/RequestFinanceAgainContainer";
import ResultApprovalContainer from "containers/ConsiderFinanceSystem/ResultApprovalContainer";
import ExcuteRefinanceContainer from "containers/ReFinanceSystem/ExcuteRefinanceContainer";
import BookConfirmConsentContainer from "containers/ReFinanceSystem/BookConfirmConsentContainer";
import ReFinanceVerifyPinConfirmContainer from "containers/ReFinanceSystem/ReFinanceVerifyPinConfirmContainer";
import CreditConsentContainer from "containers/ReFinanceSystem/CreditConsentContainer";
import ReceiveDocContainer from "containers/ReFinanceSystem/ReceiveDocContainer";
import RefinanceInfoContainer from "containers/ReFinanceSystem/RefinanceInfoContainer";
import RefinanceSuccessContainer from "containers/ReFinanceSystem/RefinanceSuccessContainer";
import RefinanceProcessStatusContainer from "containers/ReFinanceSystem/RefinanceProcessStatusContainer";
import CreditLimitChangeContainer from "containers/ReFinanceSystem/CreditLimitChangeContainer";
import CreditLimitConfirmContainer from "containers/ReFinanceSystem/CreditLimitConfirmContainer";
import CreditLimitConfirmPinContainer from "containers/ReFinanceSystem/CreditLimitConfirmPinContainer";
import OfferContainer from "containers/OfferContainer";
import RegisVerifyPinContainer from "containers/RegisterMemberSystem/RegisVerifyPinContainer";
import RegisCusInfoContainer from "containers/RegisterMemberSystem/RegisCusInfoContainer";

const systemRoutes = [
  {
    key: "term_condition",
    path: ROUTE_PATH.TERM_AND_CONDITION,
    element: <TermAndConditionContainer />,
  },
  {
    key: "rg1_genral_info",
    path: ROUTE_PATH.RG1_GENERAL_INFO,
    element: <Step1EmpGeneralInfoContainer />,
  },
  {
    key: "rg2_cus_info",
    path: ROUTE_PATH.RG2_CUS_INFO,
    element: <Step2CusInfoContainer />,
  },
  {
    key: "rg3_work_info",
    path: ROUTE_PATH.RG3_WORK_INFO,
    element: <Step3WorkInfoContainer />,
  },
  {
    key: "rg4_address_info",
    path: ROUTE_PATH.RG4_ADDRESS_INFO,
    element: <Step4AddressInfoContainer />,
  },
  {
    key: "rg5_account_info",
    path: ROUTE_PATH.RG5_ACCOUNT_INFO,
    element: <Step5AccountInfoContainer />,
  },
  {
    key: "rg6_upload_doc",
    path: ROUTE_PATH.RG6_UPLOAD_DOC,
    element: <Step6UploadDocContainer />,
  },
  {
    key: "rg_confirmation",
    path: ROUTE_PATH.RG_COMFIRMATION,
    element: <SummaryConfirmContainer />,
  },
  {
    key: "agreement_public",
    path: ROUTE_PATH.AGREEMENT_PUBLIC_INFO,
    element: <AgreementPublicInfoContainer />,
  },
  {
    key: "agreement_public",
    path: ROUTE_PATH.AGREEMENT_PUBLIC_INFO_LOGIN_CONFIRM,
    element: <AgreementPublicInfoLogAgainContainer />,
  },

  {
    key: "pin_verify",
    path: ROUTE_PATH.PIN_VERIFY,
    element: <PinVerifyContainer />,
  },
  {
    key: "pin_otp",
    path: ROUTE_PATH.PIN_OTP,
    element: <PinOtpContainer />,
  },
  {
    key: "pin_reset",
    path: ROUTE_PATH.PIN_RESET,
    element: <PinResetContainer />,
  },
  {
    key: "pin_reset_success",
    path: ROUTE_PATH.PIN_RESET_SUCCESS,
    element: <PinResetSuccessContainer />,
  },
  {
    key: "ncb_consent",
    path: ROUTE_PATH.NCB_CONSENT,
    element: <NCBConsentContainer />,
  },
  {
    key: "ncb_consent_summary",
    path: ROUTE_PATH.NCB_CONSENT_SUMMARY,
    element: <NCBConsentSummaryContainer />,
  },
  {
    key: "register_finance_success",
    path: ROUTE_PATH.REGISTER_FINANCE_SUCCESS,
    element: <RegisterFinanceSuccessContainer />,
  },
  {
    key: "consider_approval_status",
    path: ROUTE_PATH.APPROVAL_STATUS,
    element: <ApprovalStatusContainer />,
  },
  {
    key: "consider_request_finance_again",
    path: ROUTE_PATH.REQUEST_FINANCE,
    element: <RequestFinanceAgainContainer />,
  },
  {
    key: "consider_result_approval",
    path: ROUTE_PATH.RESULT_APPROVAL,
    element: <ResultApprovalContainer />,
  },
  {
    key: "refinance_chose",
    path: ROUTE_PATH.REFINANCE_EXCUTE,
    element: <ExcuteRefinanceContainer />,
  },
  {
    key: "refinance_consent",
    path: ROUTE_PATH.REFINANCE_CONSENT,
    element: <CreditConsentContainer />,
  },
  {
    key: "refinance_consent",
    path: ROUTE_PATH.REFINANCE_BOOK_CONSENT,
    element: <BookConfirmConsentContainer />,
  },
  {
    key: "refinance_consent",
    path: ROUTE_PATH.REFINANCE_VERIFY,
    element: <ReFinanceVerifyPinConfirmContainer />,
  },
  {
    key: "refinance_doc",
    path: ROUTE_PATH.REFINANCE_DOC,
    element: <ReceiveDocContainer />,
  },
  {
    key: "refinance_info",
    path: ROUTE_PATH.REFINANCE_INFO,
    element: <RefinanceInfoContainer />,
  },
  {
    key: "refinance_success",
    path: ROUTE_PATH.REFINANCE_SUCCESS,
    element: <RefinanceSuccessContainer />,
  },
  {
    key: "refinance_success",
    path: ROUTE_PATH.REFINANCE_PROCESS_STATUS,
    element: <RefinanceProcessStatusContainer />,
  },
  {
    key: "refinance_credit_confirm",
    path: ROUTE_PATH.REFINANCE_CREDIT_COMFIRM,
    element: <CreditLimitConfirmContainer />,
  },
  {
    key: "refinance_credit_change",
    path: ROUTE_PATH.REFINANCE_CREDIT_CHANGE,
    element: <CreditLimitChangeContainer />,
  },
  {
    key: "refinance_credit_pin_verify",
    path: ROUTE_PATH.REFINANCE_CREDIT_PIN_VERIFY,
    element: <CreditLimitConfirmPinContainer />,
  },
  {
    key: "offer",
    path: ROUTE_PATH.LANDING_PAGE,
    element: <OfferContainer />,
  },
  {
    key: "register_member",
    path: ROUTE_PATH.REGIS_CUS_VERIFY_OTP,
    element: <RegisVerifyPinContainer />,
  },
  {
    key: "register_member",
    path: ROUTE_PATH.REGIS_CUS_INFO,
    element: <RegisCusInfoContainer />,
  },
];

export default systemRoutes;
