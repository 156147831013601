export default function renderIcon({ width = "13", height = "12" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_3332_894)">
        <path
          d="M6.70898 3V6L8.70898 7M11.709 6C11.709 8.76142 9.47041 11 6.70898 11C3.94756 11 1.70898 8.76142 1.70898 6C1.70898 3.23858 3.94756 1 6.70898 1C9.47041 1 11.709 3.23858 11.709 6Z"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3332_894">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0.708984)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
