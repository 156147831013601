import styled from "styled-components";

export const TextNumberKeyboadPadStyled = styled.div`
  .pad_keyboad_row {
    margin-bottom: 21px;
    padding: 0 6%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .pad_col {
      .pad_item {
        margin: auto;
        border-radius: 59px;
        width: 59px;
        height: 59px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        color: ${({ theme }) => theme.COLORS.PINK_4};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        font-family: inherit;
        cursor: pointer;
      }
      .pad_cancel {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        cursor: pointer;
      }
      .pad_previous {
        margin: auto;
        width: fit-content;
        cursor: pointer;
      }
    }
  }

  &.theme_standard {
  }
`;
