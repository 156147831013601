import { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextNumberStyled } from "./styled";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { AlertControl } from "components/Modals/Alert";
import { Icons } from "components";

const TextNumber = forwardRef((props, ref) => {
  const {
    theme_standard,
    label,
    value,
    errors,
    maxLength,
    placeholder,
    suffixLabel,
    disabled,
    isRequire,
    onChange,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _handleChange = (e) => {
    const regex = /^\d+$/;
    if (e) {
      let valuetemp = e.replace(/,/g, "");
      if (regex.test(valuetemp)) {
        onChange(valuetemp);
        if (maxLength && Number(valuetemp) > maxLength) {
          AlertControl.show({
            title: "แจ้งเตือน",
            description: "เลือกวงเงิน * (สูงสุด 500,000.00 บาท)",
            btnLabel2: "ตกลง",
          });
          onChange(maxLength);
        }
      }
    } else {
      onChange(e);
    }
  };

  return (
    <TextNumberStyled className={customClass} err={errors ? 1 : 0}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      <div className="input_txt_block">
        <input
          ref={ref}
          value={value ? RenderCommaMoney(value, true) : ""}
          disabled={disabled}
          placeholder={placeholder}
          onChange={(e) => {
            _handleChange(e.target.value);
          }}
        />
        <div className="suffix_block" onClick={() => onChange(0)}>
          {suffixLabel}
          <Icons.DeleteInput />
        </div>
      </div>
      {errors && <div className="errors">{errors}</div>}
    </TextNumberStyled>
  );
});

TextNumber.propTypes = {};

export default TextNumber;
