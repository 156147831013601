export default function renderIcon({ width = "13", height = "13", active }) {
  return active ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 0C10.088 0 13 2.912 13 6.5C13 10.088 10.088 13 6.5 13C2.912 13 0 10.088 0 6.5C0 2.912 2.912 0 6.5 0ZM6.5 11.7C9.37755 11.7 11.7 9.37755 11.7 6.5C11.7 3.62245 9.37755 1.3 6.5 1.3C5.81689 1.29914 5.14031 1.43306 4.50903 1.69408C3.87776 1.95511 3.30417 2.3381 2.82114 2.82114C2.3381 3.30417 1.95511 3.87776 1.69408 4.50903C1.43306 5.14031 1.29914 5.81689 1.3 6.5C1.3 9.37755 3.62245 11.7 6.5 11.7ZM6.5 10.4C4.342 10.4 2.6 8.658 2.6 6.5C2.6 4.342 4.342 2.6 6.5 2.6C8.658 2.6 10.4 4.342 10.4 6.5C10.4 8.658 8.658 10.4 6.5 10.4ZM6.5 5.2C5.785 5.2 5.2 5.785 5.2 6.5C5.2 7.215 5.785 7.8 6.5 7.8C7.215 7.8 7.8 7.215 7.8 6.5C7.8 5.785 7.215 5.2 6.5 5.2Z"
        fill="#ED018C"
      />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 13C2.91005 13 0 10.0899 0 6.5C0 2.91005 2.91005 0 6.5 0C10.0899 0 13 2.91005 13 6.5C13 10.0899 10.0899 13 6.5 13ZM6.5 11.7C7.87913 11.7 9.20177 11.1521 10.177 10.177C11.1521 9.20177 11.7 7.87913 11.7 6.5C11.7 5.12087 11.1521 3.79823 10.177 2.82304C9.20177 1.84786 7.87913 1.3 6.5 1.3C5.12087 1.3 3.79823 1.84786 2.82304 2.82304C1.84786 3.79823 1.3 5.12087 1.3 6.5C1.3 7.87913 1.84786 9.20177 2.82304 10.177C3.79823 11.1521 5.12087 11.7 6.5 11.7Z"
        fill="#EEEEEE"
      />
    </svg>
  );
}
