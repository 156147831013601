import React, { useState } from "react";
import { AddressInfoFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import address from "utils/jsons/thailandAddress/address.json";
import { Buttons, Displays, Icons, Inputs } from "components";
import { AlertControl } from "components/Modals/Alert";

const AddressInfoForm = ({
  residenceTypeOptions,
  residenceStatusOptions,
  relationShipOptions,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    residence_type: yup.object().nullable().required("Required"),
    residence_duration_years: yup.string().required("กรุณาระบุจำนวนปี"),
    residence_duration_months: yup.number().when("residence_duration_years", {
      is: (residence_duration_years) => {
        return residence_duration_years === "0" || !residence_duration_years;
      },
      then: (schema) =>
        schema
          .typeError("กรุณาระบุจำนวนเดือน")
          // .min(4, "ต้องมีอายุการทำงานอย่างน้อย 4 เดือน")
          .max(11, "ตัวเลข 0-11 เท่านั้น "),
      otherwise: (schema) =>
        schema
          .typeError("กรุณาระบุจำนวนเดือน")
          .max(11, "ตัวเลข 0-11 เท่านั้น ")
          .required("กรุณาระบุจำนวนเดือน"),
    }),
    residence_status: yup.object().nullable().required("Required"),
    house_number: yup.string().required("กรุณาระบุเลขที่"),
    province: yup.object().nullable().required("Required"),
    district: yup.object().nullable().required("Required"),
    subdistrict: yup.object().nullable().required("Required"),
    postal_code: yup.string().required("Required"),
    mailing_address: yup.string().nullable().required("Required"),
    reference_info_list: yup
      .array()
      .of(
        yup.object().shape({
          // first_name: yup.string().nullable(),
          // last_name: yup.string().nullable(),
          // phone_number: yup.string().nullable(), // เพิ่มฟิลด์ phone_number
          // relationship: yup.object().nullable().required("กรุณากรอก"),
        })
      )
      .test(
        "validate-first-item",
        "กรุณาระบุชื่อบุคคลอ้างอิงเป็นภาษาไทย",
        function (value) {
          if (value && value.length > 0 && !value[0].first_name) {
            return this.createError({
              path: `${this.path}[0].first_name`,
              message: "กรุณาระบุชื่อบุคคลอ้างอิงเป็นภาษาไทย",
            });
          }
          return true;
        }
      )
      .test(
        "validate-first-item",
        "กรุณาระบุนามสกุลบุคคลอ้างอิงเป็นภาษาไทย",
        function (value) {
          if (value && value.length > 0 && !value[0].last_name) {
            return this.createError({
              path: `${this.path}[0].last_name`,
              message: "กรุณาระบุนามสกุลบุคคลอ้างอิงเป็นภาษาไทย",
            });
          }
          return true;
        }
      )
      .test("validate-first-item", "กรุณาระบุความสัมพันธ์", function (value) {
        if (value && value.length > 0 && !value[0].relationship) {
          return this.createError({
            path: `${this.path}[0].relationship`,
            message: "กรุณาระบุความสัมพันธ์",
          });
        }
        return true;
      })
      .test(
        "validate-phone-number-first-item",
        "กรุณาระบุเบอร์โทรติดต่อ",
        function (value) {
          if (value && value.length > 0 && !value[0].phone_number) {
            return this.createError({
              path: `${this.path}[0].phone_number`,
              message: "กรุณาระบุเบอร์โทรติดต่อ",
            });
          }
          return true;
        }
      )
      .test(
        "unique-phone-number",
        "กรุณาตรวจสอบหมายเลขโทรศัพท์ของบุคคลอ้างอิง หมายเลขโทรศัพท์จะต้องไม่ซ้ำกัน ",
        function (value) {
          if (value) {
            const phoneNumbers = value
              .map((item) => item.phone_number)
              .filter(Boolean);
            const uniquePhoneNumbers = new Set(phoneNumbers);
            if (phoneNumbers.length !== uniquePhoneNumbers.size) {
              return this.createError({
                path: `${this.path}`,
                message:
                  "กรุณาตรวจสอบหมายเลขโทรศัพท์ของบุคคลอ้างอิง หมายเลขโทรศัพท์จะต้องไม่ซ้ำกัน ",
              });
            }
          }
          return true;
        }
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reference_info_list: [{}],
    },
  });

  const {
    fields,
    append,
    //  remove
  } = useFieldArray({
    control,
    name: "reference_info_list",
  });

  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  const _handleChangeProvince = (e) => {
    setValue("district", "");
    setValue("subdistrict", "");
    setValue("postal_code", "");
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(" ");
    setValue("subdistrict", {
      label: splitData[1],
      value: splitData[1],
    });
    setValue("postal_code", splitData[2]);
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue("postal_code", find.zipcode);
    }
  };

  return (
    <AddressInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="ประเภทที่พักอาศัย"
                subLabel=" (ปัจจุบัน)"
                options={residenceTypeOptions}
                placeholder="กรุณาระบุประเภทที่พักอาศัย"
                errors={errors.residence_type?.message}
              />
            )}
            name="residence_type"
            defaultValue=""
          />
        </div>
        <div className="input_row">
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  isRequire
                  isInfo
                  label="ระยะเวลาที่พักอาศัย"
                  placeholder="กรุณาระบุจำนวนปี"
                  errors={errors.residence_duration_years?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("residence_duration_years");
                    trigger("residence_duration_months");
                  }}
                />
              )}
              name="residence_duration_years"
              // defaultValue={"0"}
            />
          </div>
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  label=" "
                  options={[{ label: "test", value: "1" }]}
                  placeholder="กรุณาระบุจำนวนเดือน"
                  errors={errors.residence_duration_months?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("residence_duration_months");
                  }}
                />
              )}
              name="residence_duration_months"
              // defaultValue={"0"}
            />
          </div>
        </div>
        <div className="input_wrap mb_27">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="สถานะการอาศัย"
                options={residenceStatusOptions}
                placeholder="กรุณาเลือกสถานะการอาศัย"
                errors={errors.residence_status?.message}
              />
            )}
            name="residence_status"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="f_title">ข้อมูลที่อยู่ปัจจุบัน</div>
        <div className="input_row mb_27">
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  isRequire
                  label="เลขที่"
                  regexFormat={/^[0-9/-]*$/}
                  placeholder="กรุณากรอกเลขที่"
                  errors={errors.house_number?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("house_number");
                  }}
                />
              )}
              name="house_number"
              defaultValue=""
            />
          </div>
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="ซอย"
                  placeholder="กรุณาระบุถนน"
                  errors={errors.alley?.message}
                />
              )}
              name="alley"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="ถนน"
                placeholder="กรุณาระบุถนน"
                errors={errors.street?.message}
              />
            )}
            name="street"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="จังหวัด"
                options={address.reduce((result, data, i) => {
                  if (i === 0) {
                    result.push({ label: data.province, value: data.province });
                  } else {
                    let find = result.find((e) => e.value === data.province);
                    if (!find) {
                      result.push({
                        label: data.province,
                        value: data.province,
                      });
                    }
                  }
                  return result;
                }, [])}
                placeholder="กรุณาเลือกจังหวัด"
                errors={errors.province?.message}
                onChangeCus={_handleChangeProvince}
              />
            )}
            name="province"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="เขต/อำเภอ"
                options={_districtOption}
                placeholder="กรุณาเลือกเขต/อำเภอ"
                errors={errors.district?.message}
                onChangeCus={_handleChangeDistrict}
              />
            )}
            name="district"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="แขวง/ตำบล"
                options={_subdistrictOption}
                placeholder="กรุณาเลือกแขวง/ตำบล"
                errors={errors.subdistrict?.message}
                onChangeCus={_handleChangeSubDistrict}
              />
            )}
            name="subdistrict"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                disabled
                label="รหัสไปรษณีย์"
                placeholder="กรุณาเลือกรหัสไปรษณีย์"
                errors={errors.postal_code?.message}
              />
            )}
            name="postal_code"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="f_title mb_16">ที่อยู่สำหรับจัดส่งเอกสาร</div>
        <div className="input_wrap mb_22">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Radio
                {...field}
                options={[
                  { label: "ที่อยู่ปัจจุบัน", value: "1" },
                  { label: "ที่อยู่ตามบัตรประชาชน", value: "2" },
                  { label: "ที่อยู่ที่ทำงานปัจจุบัน", value: "3" },
                ]}
                placeholder="กรุณาเลือกรหัสไปรษณีย์"
                errors={errors.mailing_address?.message}
              />
            )}
            name="mailing_address"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        {fields.map((item, index) => (
          <div key={String(index)}>
            <div className="f_title mb_4">ข้อมูลบุคคลอ้างอิง {index + 1}</div>
            {index === 0 && (
              <div className="info_wrap">
                <Displays.Infomation label="กรุณารระบุข้อมูลบุคคลอ้างอิงอย่างน้อย 1 ท่าน" />
              </div>
            )}
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire={index === 0}
                    label="ชื่อ"
                    regexFormat={/^[ก-๙]+$/}
                    placeholder="กรุณาระบุชื่อ"
                    errors={
                      errors.reference_info_list &&
                      errors?.reference_info_list[index]?.first_name?.message
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      trigger(`reference_info_list.${index}.first_name`);
                    }}
                  />
                )}
                name={`reference_info_list.${index}.first_name`}
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextField
                    {...field}
                    isRequire={index === 0}
                    label="นามสกุล"
                    regexFormat={/^[ก-๙]+$/}
                    placeholder="กรุณาระบุนามสกุล"
                    errors={
                      errors.reference_info_list &&
                      errors?.reference_info_list[index]?.last_name?.message
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      trigger(`reference_info_list.${index}.last_name`);
                    }}
                  />
                )}
                name={`reference_info_list.${index}.last_name`}
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextPhoneMask
                    {...field}
                    isRequire={index === 0}
                    label="กรุณาระบุเบอร์โทรติดต่อ"
                    placeholder="กรุณาระบุเบอร์โทรติดต่อ"
                    errors={
                      errors.reference_info_list &&
                      errors?.reference_info_list[index]?.phone_number?.message
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      trigger(`reference_info_list.${index}.phone_number`);
                    }}
                  />
                )}
                name={`reference_info_list.${index}.phone_number`}
                defaultValue=""
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.DropdownField
                    {...field}
                    isRequire={index === 0}
                    label="ความสัมพันธ์"
                    options={relationShipOptions}
                    placeholder="กรุณาเลือกความสัมพันธ์"
                    errors={
                      errors.reference_info_list &&
                      errors?.reference_info_list[index]?.relationship?.message
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      trigger(`reference_info_list.${index}.relationship`);
                    }}
                  />
                )}
                name={`reference_info_list.${index}.relationship`}
                defaultValue=""
              />
            </div>
          </div>
        ))}
        {fields && fields.length < 2 && (
          <button
            type="button"
            className="btn_add_personal"
            onClick={() => {
              if (fields.length < 2) {
                append({});
              } else {
                AlertControl.show({
                  title: "แจ้งเตือน",
                  description: "ลิมิท 2 คน",
                  btnLabel2: "ตกลง",
                });
              }
            }}
          >
            <Icons.CirclePlus />
            เพิ่มข้อมูลบุคคลอ้างอิง {fields.length + 1} <span>(ถ้ามี)</span>
          </button>
        )}
        <Buttons.BgStandard
          type="submit"
          label="ดำเนินการต่อ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </AddressInfoFormStyled>
  );
};

AddressInfoForm.propTypes = {};

export default AddressInfoForm;
