import styled from "styled-components";

export const RadioContainer = styled.div`
  .label {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .rd_desc {
    margin-top: -4px;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S9};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
  }
  .radio_container {
    .radio_option_item {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      column-gap: 12px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      cursor: pointer;
      &:last-child {
        margin-bottom: 0px;
      }
      &.active {
      }
    }
  }

  .errors {
    margin-top: 2px;
    padding-left: 16px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }
`;
