import React, { useState } from "react";
// import { useSelector } from "react-redux";
import { WorkInfoType1FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import address from "utils/jsons/thailandAddress/address.json";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const WorkInfoType1Form = ({ emplyeeTypeOptions, onSubmit }) => {
  const schema = yup.object().shape({
    workplace: yup.string().required("กรุณาระบุชื่อสถานที่ทำงาน"),
    job_position: yup.object().nullable().required("Required"),
    job_position_etc: yup.string().when("job_position", {
      is: (job_position) => {
        return job_position?.value === "999";
      },
      then: (schema) =>
        schema.typeError("กรุณาระบุจำนวนเดือน").required("Required"),
      otherwise: (schema) => schema,
    }),
    job_duration_years: yup.string().nullable().required("กรุณาระบุจำนวนปี"),
    job_duration_months: yup.number().when("job_duration_years", {
      is: (job_duration_years) => {
        return job_duration_years === "0" || !job_duration_years;
      },
      then: (schema) =>
        schema
          .typeError("กรุณาระบุจำนวนเดือน")
          .min(4, "ต้องมีอายุการทำงานอย่างน้อย 4 เดือน")
          .max(11, "ตัวเลข 0-11 เท่านั้น "),
      otherwise: (schema) =>
        schema
          .typeError("กรุณาระบุจำนวนเดือน")
          .max(11, "ตัวเลข 0-11 เท่านั้น ")
          .required("กรุณาระบุจำนวนเดือน"),
    }),
    total_work_experience_years: yup.string().required("กรุณาระบุจำนวนปี"),
    total_work_experience_months: yup
      .number()
      .when("total_work_experience_years", {
        is: (total_work_experience_years) => {
          return (
            total_work_experience_years === "0" || !total_work_experience_years
          );
        },
        then: (schema) =>
          schema
            .typeError("กรุณาระบุจำนวนเดือน")
            .min(4, "ต้องมีอายุการทำงานอย่างน้อย 4 เดือน")
            .max(11, "ตัวเลข 0-11 เท่านั้น "),
        otherwise: (schema) =>
          schema
            .typeError("กรุณาระบุจำนวนเดือน")
            .max(11, "ตัวเลข 0-11 เท่านั้น ")
            .required("กรุณาระบุจำนวนเดือน"),
      }),
    workplace_house_number: yup.string().required("กรุณาระบุเลขที่"),
    // workplace_alley: yup.string().required("Required"),
    // workplace_street: yup.string().required("Required"),
    workplace_province: yup.object().nullable().required("Required"),
    workplace_district: yup.object().nullable().required("Required"),
    workplace_subdistrict: yup.object().nullable().required("Required"),
    workplace_postal_code: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    trigger,
    watch,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchField = watch();

  // const masterDataRedux = useSelector((state) => state.masterDataRedux);

  const [_districtOption, _setDistrictOption] = useState([]);
  const [_subdistrictOption, _setSubdistrictOption] = useState([]);

  const _handleChangeProvince = (e) => {
    setValue("workplace_district", "");
    setValue("workplace_subdistrict", "");
    setValue("workplace_postal_code", "");
    _setDistrictOption(
      address.reduce((result, data, i) => {
        if (data.province === e.value) {
          // let find = result.find((e) => e.value === data.amphoe);
          // if (!find) {
          result.push({
            label: `${data.amphoe} ${data.district} ${data.zipcode}`,
            value: data.amphoe,
          });
          // }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeDistrict = (e) => {
    let splitData = e.label.split(" ");
    setValue(
      "workplace_subdistrict",
      {
        label: splitData[1],
        value: splitData[1],
      },
      { shouldValidate: true }
    );
    setValue("workplace_postal_code", splitData[2], { shouldValidate: true });
    _setSubdistrictOption(
      address.reduce((result, data, i) => {
        if (data.amphoe === e.value) {
          let find = result.find((e) => e.value === data.district);
          if (!find) {
            result.push({
              label: data.district,
              value: data.district,
              zipcode: data.zipcode,
            });
          }
        }
        return result;
      }, [])
    );
  };

  const _handleChangeSubDistrict = (e) => {
    let find = _subdistrictOption.find((f) => f.value === e.value);
    if (find) {
      setValue("workplace_postal_code", find.zipcode, { shouldValidate: true });
    }
  };

  return (
    <WorkInfoType1FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label="ชื่อสถานที่ทำงาน"
                placeholder="กรุณาระบุชื่อสถานที่สถานที่ทำงาน"
                errors={errors.workplace?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("workplace");
                }}
              />
            )}
            name="workplace"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="ตำแหน่งงาน"
                options={emplyeeTypeOptions}
                placeholder="กรุณาเลือกตำแหน่งงาน"
                errors={errors.job_position?.message}
              />
            )}
            name="job_position"
            defaultValue=""
          />
        </div>
        {watchField?.job_position?.value === "999" && (
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  isRequire
                  label="ระบุตำแหน่งงาน"
                  placeholder="กรุณาระบุตำแหน่งงาน"
                  errors={errors.job_position_etc?.message}
                />
              )}
              name="job_position_etc"
              defaultValue=""
            />
          </div>
        )}
        <div className="input_row">
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  isRequire
                  isInfo
                  label="ระยะเวลาที่ทำงานปัจจุบัน"
                  placeholder="กรุณาระบุจำนวนปี"
                  errors={errors.job_duration_years?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("job_duration_years");
                    trigger("job_duration_months");
                  }}
                />
              )}
              name="job_duration_years"
              // defaultValue="0"
            />
          </div>
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  label="  "
                  placeholder="กรุณาระบุจำนวนเดือน"
                  errors={errors.job_duration_months?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("job_duration_months");
                  }}
                />
              )}
              name="job_duration_months"
              // defaultValue="0"
            />
          </div>
        </div>
        <div className="input_row mb_14">
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  isRequire
                  isInfo
                  label="ระยะเวลาที่ทำงานทั้งหมด"
                  placeholder="กรุณาระบุจำนวนปี"
                  errors={errors.total_work_experience_years?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("total_work_experience_years");
                    trigger("total_work_experience_months");
                  }}
                />
              )}
              name="total_work_experience_years"
              // defaultValue="0"
            />
          </div>
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberField
                  {...field}
                  label="  "
                  placeholder="กรุณาระบุจำนวนเดือน"
                  errors={errors.total_work_experience_months?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("total_work_experience_months");
                  }}
                />
              )}
              name="total_work_experience_months"
              // defaultValue="0"
            />
          </div>
        </div>
        <div className="line_horizontal" />
        <div className="f_title">ที่อยู่ที่ทำงานปัจจุบัน</div>
        <div className="input_row flex_start">
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  isRequire
                  regexFormat={/^[0-9/-]*$/}
                  label="เลขที่"
                  placeholder="กรุณากรอก"
                  errors={errors.workplace_house_number?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("workplace_house_number");
                  }}
                />
              )}
              name="workplace_house_number"
              defaultValue=""
            />
          </div>
          <div className="input_col">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  label="ซอย"
                  placeholder="กรุณาระบุซอย"
                  errors={errors.workplace_alley?.message}
                />
              )}
              name="workplace_alley"
              defaultValue=""
            />
          </div>
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="ถนน"
                placeholder="กรุณาระบุถนน"
                errors={errors.workplace_street?.message}
              />
            )}
            name="workplace_street"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="จังหวัด"
                options={address.reduce((result, data, i) => {
                  if (i === 0) {
                    result.push({ label: data.province, value: data.province });
                  } else {
                    let find = result.find((e) => e.value === data.province);
                    if (!find) {
                      result.push({
                        label: data.province,
                        value: data.province,
                      });
                    }
                  }
                  return result;
                }, [])}
                placeholder="กรุณาเลือกจังหวัด"
                errors={errors.workplace_province?.message}
                onChangeCus={_handleChangeProvince}
              />
            )}
            name="workplace_province"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="เขต/อำเภอ"
                options={_districtOption}
                placeholder="กรุณาเลือกเขต/อำเภอ"
                errors={errors.workplace_district?.message}
                onChangeCus={_handleChangeDistrict}
              />
            )}
            name="workplace_district"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="แขวง/ตำบล"
                options={_subdistrictOption}
                placeholder="กรุณาเลือกแขวง/ตำบล"
                errors={errors.workplace_subdistrict?.message}
                onChangeCus={_handleChangeSubDistrict}
              />
            )}
            name="workplace_subdistrict"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_27">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                isRequire
                label="รหัสไปรษณีย์"
                placeholder="กรุณาเลือกรหัสไปรษณีย์"
                errors={errors.workplace_postal_code?.message}
                disabled
              />
            )}
            name="workplace_postal_code"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </WorkInfoType1FormStyled>
  );
};

WorkInfoType1Form.propTypes = {};

export default WorkInfoType1Form;
