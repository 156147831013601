export const getObjectKey = (obj, value) => {
  let findData = obj.find((e) => e.key === value);

  return findData;

  // return true
};

export const getObjectKey2 = (obj, value, value2) => {
  let findData = obj.find((e) => e.key === value || e.key === value2);

  return findData;

  // return true
};

export const RENDER_MASTER_DATA_OPTION = (data) => {
  let renderDataOption = [];
  if (data) {
    if (data && data.choices && data.choices.length > 0) {
      data.choices.forEach((e) => {
        renderDataOption.push({
          label: e.label.th.text,
          value: e.value,
        });
      });
    }
  }
  return renderDataOption;
};
