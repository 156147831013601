import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinancialProviderStyled } from "./styled";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const FinancialProvider = ({
  theme_standard,
  no,
  name,
  total_dept,
  installment,
  onSelect,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <FinancialProviderStyled className={customClass}>
      <div className="fpd_top_block">
        <div className="tb_row mb_8">
          <div className="tb_col">
            <div className="installment_label">ผู้ให้บริการทางการเงิน</div>
            <div className="installment_label pink">{name}</div>
          </div>
          <div className="tb_col">
            <div
              className="no_rec_block"
              onClick={() => {
                onSelect && onSelect();
              }}
            >
              {no}
            </div>
          </div>
        </div>
        <div className="tb_row">
          <div className="tb_col">
            <div className="installment_label">ยอดผ่อนต่อเดือน</div>
          </div>
          <div className="tb_col">
            <div className="installment_value">
              {RenderCommaMoney(installment)} ฿
            </div>
          </div>
        </div>
      </div>
      <div className="fpd_bottom_block">
        <div className="bb_label">ยอดสินเชื่อ</div>
        <div className="bb_value">{RenderCommaMoney(total_dept)} บาท</div>
      </div>
    </FinancialProviderStyled>
  );
};

FinancialProvider.propTypes = {};

export default FinancialProvider;
