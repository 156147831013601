import { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DropdownFieldStyled } from "./styled";
import { useComponentVisible } from "utils/functions/detectClickOutside";
import { Icons } from "components";

const DropdownField = forwardRef((props, innerRef) => {
  const {
    theme_standard,
    label,
    subLabel,
    options,
    value,
    errors,
    placeholder,
    disabled,
    isRequire,
    onChange,
    onChangeCus,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const { ref, isComponentVisible, setIsComponentVisible } =
    useComponentVisible(false);

  return (
    <DropdownFieldStyled ref={ref} className={customClass} disabled={disabled}>
      {label && (
        <div className="label">
          {label} {subLabel && <span className="sub_label">{subLabel}</span>}{" "}
          {isRequire && <span>*</span>}
        </div>
      )}
      <div className="dropdown_input" ref={innerRef}>
        <div className="">
          {value.label || <span className="placeholder">{placeholder}</span>}
        </div>
        {!isComponentVisible ? (
          <div className="my_arrow_clasnName">
            <Icons.DropdownArrowBottom />
          </div>
        ) : (
          <div className="my_arrow_clasnName">
            <Icons.DropdownArrowBottom />
          </div>
        )}
      </div>
      {isComponentVisible && (
        <div className="dropdown_content_block">
          {options &&
            options.map((e, i) => (
              <div
                key={i}
                className="option_item"
                onClick={() => {
                  setIsComponentVisible(false);
                  onChange(e);
                  onChangeCus && onChangeCus(e);
                }}
              >
                <div>{e.label}</div>
              </div>
            ))}
        </div>
      )}
      {errors && <div className="errors">{errors}</div>}
    </DropdownFieldStyled>
  );
});

DropdownField.propTypes = {};

export default DropdownField;
