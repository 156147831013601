import styled from "styled-components";

export const TemplateDocumentDeleteStyled = styled.div`
  border-radius: 12px;
  padding: 33px 24px 27px 24px;
  min-width: 236px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .title_block {
    margin-bottom: 14px;
    .title_label {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
  }
  .gmoney_figture {
    margin-bottom: 16px;
    text-align: center;
    .gmoney_img {
      width: 88px;
      height: auto;
    }
  }
  .tpdd_desc {
    margin-bottom: 23px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .tpdd_desc_list {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    &:last-child {
      margin-bottom: 23px;
    }
  }
  .tpdd_action_row {
    display: flex;
    column-gap: 5px;
    .tpdd_col {
      flex: 1;
    }
    .btn_tpdd_cancel {
      border-radius: 5px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
      padding: 6px 0px;
      width: 100%;
      text-align: center;
      background: inherit;
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S13};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      font-family: inherit;
      cursor: pointer;
    }
    .btn_tpdd_confirm {
      border-radius: 5px;
      padding: 7px 1px;
      width: 100%;
      background: linear-gradient(102.16deg, #ed008c 57.64%, #ff9300 97.99%);
      text-align: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S13};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      font-family: inherit;
      cursor: pointer;
    }
  }

  &.theme_standard {
  }
`;
