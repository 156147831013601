import styled from 'styled-components';

export const NCBConsentSummaryFormStyled = styled.div`
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .condition_block {
    flex: 1;
    border-radius: 10px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_6};
    padding: 10px;
    .input_wrap {
      margin-top: 20px;
      margin-bottom: 17px;
    }
  }
  .btn_submit_wrap {
    padding: 20px 0;
  }
`;
