import { PageLayoutStyled } from "./styled";
import { Outlet } from "react-router-dom";

const PageLayout = ({ isNotBg }) => {
  return (
    <PageLayoutStyled isNotBg={isNotBg}>
      <div className="body_mainlayout">
        <Outlet />
      </div>
    </PageLayoutStyled>
  );
};

export default PageLayout;
