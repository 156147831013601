import React from "react";
import { connect } from "react-redux";
import { Step3WorkInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxStep3WorkInfo } from "store/reducers/financeRequestReducer";
import { moneyDDService } from "apiServices";
import { RENDER_MASTER_DATA_OPTION } from "utils/functions/getObjectKey";
import { Displays, Forms, Headers } from "components";

class Step3WorkInfoContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [
        {
          field_name: "_occupation.employType",
          field_value: "EMPLOYEE",
        },
        {
          field_name: "_occupation.type",
          field_value: "03",
        },
        {
          field_name: "_occupation.subType",
          field_value: "03",
        },
      ],
      target_fields: [
        "_occupation.position",
        "_occupation.type",
        "_occupation.previousPosition",
      ],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      this.setState({
        emplyeeTypeOptions: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[0]
        ),
        isLoading: false,
      });
    }
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    this.props.setReduxStep3WorkInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG4_ADDRESS_INFO);
  };

  render() {
    const { financeRequestRedux } = this.props;
    const { isLoading, emplyeeTypeOptions } = this.state;
    return (
      <Step3WorkInfoContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_container">
            <div className="step_wrap">
              <Displays.FinanceRegisStep
                title="ข้อมูลการทำงาน"
                subTitle="กรุณาระบุข้อมูลส่วนบุคคล"
                stepCurrent={3}
                total={6}
              />
            </div>
            {financeRequestRedux?.occupation_type?.value === "EMPLOYEE" && (
              <Forms.WorkInfoType1Form
                emplyeeTypeOptions={emplyeeTypeOptions}
                onSubmit={this.handleSubmit}
              />
            )}
            {financeRequestRedux?.occupation_type?.value === "SELF_EMPLOYED" && (
              <Forms.WorkInfoType2Form
                emplyeeTypeOptions={emplyeeTypeOptions}
                onSubmit={this.handleSubmit}
              />
            )}
          </div>
        )}
      </Step3WorkInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxStep3WorkInfo: (data) => dispatch(setReduxStep3WorkInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step3WorkInfoContainer));
