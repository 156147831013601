import styled from "styled-components";

export const TextNumberBtnStyled = styled.div`
  .label {
    margin-bottom: 11px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
    .suffix {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .input_txt_nb_block {
    position: relative;
    display: flex;
    align-items: center;
    .prefix_block {
      position: absolute;
      left: 3px;
    }
    input {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_3};
      padding: 6.5px 40px 6.5px 40px;
      width: calc(100% - 24px);
      background: ${({ theme, disabled }) =>
        !disabled ? "transparent" : theme.COLORS.GRAY_8};
      text-align: center;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
      font-family: inherit;
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
      &::placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }
    .suffix_block {
      position: absolute;
      right: 3px;
      display: flex;
      align-items: center;
      column-gap: 7px;
      cursor: pointer;
    }
    .btn_block {
      border-radius: 6px;
      width: 34px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${({ theme }) => theme.COLORS.PINK_1};
      cursor: pointer;
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  &.theme_standard {
  }
`;
