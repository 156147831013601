export default function renderIcon({
  width = "9",
  height = "11",
  color = "#ED018C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98312 3.08716H0.945931C0.82449 3.08716 0.708641 3.13749 0.62555 3.22698C0.542459 3.31566 0.500913 3.4355 0.509702 3.55614L1.01784 10.5941C1.03461 10.8226 1.22477 11 1.45407 11H7.47578C7.70508 11 7.89523 10.8226 7.91201 10.5941L8.42015 3.55614C8.42894 3.4347 8.38659 3.31566 8.3043 3.22698C8.22121 3.13829 8.10536 3.08716 7.98392 3.08716H7.98312Z"
        fill={color}
      />
      <path
        d="M8.49128 1.56275H6.1863V0.996296C6.1863 0.446615 5.73969 0 5.19001 0H3.73831C3.18863 0 2.74201 0.446615 2.74201 0.996296V1.56275H0.437028C0.195744 1.56275 0 1.7585 0 1.99978C0 2.24107 0.195744 2.43681 0.437028 2.43681H8.49128C8.73257 2.43681 8.92831 2.24107 8.92831 1.99978C8.92831 1.7585 8.73257 1.56275 8.49128 1.56275ZM5.31145 1.56275H3.61687V0.996296C3.61687 0.929184 3.67119 0.874855 3.73831 0.874855H5.19001C5.25712 0.874855 5.31145 0.929184 5.31145 0.996296V1.56275Z"
        fill={color}
      />
      <path
        d="M5.9891 5.71218C6.11551 5.58577 6.11465 5.38064 5.98717 5.25317C5.8597 5.12569 5.65458 5.12483 5.52816 5.25125L4.4997 6.27971L3.46259 5.2426C3.33512 5.11513 3.13 5.11427 3.00358 5.24068C2.87717 5.3671 2.87802 5.57222 3.0055 5.6997L4.0426 6.7368L3.01414 7.76526C2.88773 7.89168 2.88859 8.0968 3.01606 8.22428C3.14354 8.35175 3.34866 8.35261 3.47508 8.2262L4.50354 7.19774L5.54064 8.23484C5.66812 8.36232 5.87324 8.36318 5.99966 8.23676C6.12607 8.11035 6.12522 7.90522 5.99774 7.77775L4.96063 6.74064L5.9891 5.71218Z"
        fill="white"
      />
    </svg>
  );
}
