import styled from "styled-components";

export const PageLayoutStyled = styled.div`
  .body_mainlayout {
    margin: auto;
    width: 100%;
    max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO};
    height: 100vh;
    background-image: ${({ isNotBg }) =>
      isNotBg ? "none" : `url("/assets/images/backgrounds/bg-splash.jpg")`};
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
`;
