import React from "react";
import { UploadDocEtcFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UploadMediaWidget } from "widgets";
import { Displays } from "components";

const UploadDocEtcForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: {
      errors,
      // isSubmitting, isValid
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <UploadDocEtcFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_info_wrap">
          <Displays.Infomation
            theme_center
            label="รองรับไฟล์ JPG, PNG, PDF ขนาดไม่เกิน 10 MB. / ไฟล์ "
          />
        </div>
        <div className="input_wrap mb_17">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                theme_update
                isRequire
                acceptType="cover"
                label="เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด"
                desc={["อัพโหลดเอกสารเพิ่มเติมของบริษัท กรุงศรี ออโต้"]}
                placeholder={"อัปโหลดเอกสารที่นี่"}
                errors={errors.test?.message}
              />
            )}
            name="test"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                theme_update
                isRequire
                acceptType="cover"
                label="เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด"
                desc={["อัพโหลดเอกสารเพิ่มเติมของธนาคาร กรุงไทย"]}
                placeholder={"อัปโหลดเอกสารที่นี่"}
                errors={errors.test?.message}
              />
            )}
            name="test"
            defaultValue=""
          />
        </div>
      </form>
    </UploadDocEtcFormStyled>
  );
};

UploadDocEtcForm.propTypes = {};

export default UploadDocEtcForm;
