import React, { useState, useEffect } from "react";
import cx from "classnames";
import { UploadMediaWidgetStyled, UploadMethodModalStyled } from "./styled";
import { Icons, Inputs, Modals } from "components";
import { AlertControl } from "components/Modals/Alert";

const UploadMediaWidget = ({
  theme_update,
  label,
  desc,
  placeholder,
  subLabel,
  isRequire,
  isInfo,
  maxLength,
  value,
  onChange,
}) => {
  const customClass = cx({
    theme_update: theme_update,
  });

  const [_renderModal, _setRenderModal] = useState(false);
  const [_value, _setValue] = useState([]);

  useEffect(() => {
    if (value !== _value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  const _handleClickUpload = () => {
    if (!maxLength || _value.length < maxLength) {
      _setRenderModal(
        <UploadMethodModalStyled>
          <div className="upm_bd" onClick={_handleCloseModal} />
          <div className="upm_body">
            <div className="btn_upm_wrap">
              <Inputs.InputCamera
                onClickUseCamera={_handleCloseModal}
                onChange={(e) => {
                  let temp = [..._value, { file: e }];
                  _setValue(temp);
                  onChange && onChange(temp);
                  _handleCloseModal();
                }}
              />
            </div>
            <div className="btn_upm_wrap">
              <Inputs.UploadImgField
                acceptType="cover"
                icon={<Icons.UploadPicture />}
                placeholder="อัลบั้มภาพถ่าย"
                onChange={(e) => {
                  let temp = [..._value, { file: e }];
                  _setValue(temp);
                  onChange && onChange(temp);
                  _handleCloseModal();
                }}
              />
            </div>
            <div className="btn_upm_wrap">
              <Inputs.UploadImgField
                acceptType="pdf"
                icon={<Icons.UploadDoc />}
                placeholder="ไฟล์เอกสาร"
                onChange={(e) => {
                  let temp = [..._value, { file: e }];
                  _setValue(temp);
                  onChange && onChange(temp);
                  _handleCloseModal();
                }}
              />
            </div>
          </div>
        </UploadMethodModalStyled>
      );
    } else {
      AlertControl.show({
        title: "เพิ่มข้อมูลไม่สำเร็จ",
        description: `คุณสามารถเพิ่มได้ไม่เกิน ${maxLength} เอกสารเท่านั้น`,
        isClose: true,
        btnLabel2: "ตกลง",
      });
    }
  };

  const onClickRemove = (index, data) => {
    _setRenderModal(
      <Modals.TemplateDocumentDelete
        onCancel={_handleCloseModal}
        onConfirm={(e) => {
          let temp = _value;
          temp.splice(index, 1);
          _setValue([...temp]);
          _handleCloseModal();
        }}
      />
    );
  };

  return (
    <>
      <UploadMediaWidgetStyled className={customClass}>
        {label && (
          <div className="upmd_label">
            {label} {subLabel && <span className="sub_label">{subLabel}</span>}{" "}
            {isInfo && <Icons.Infomation color="#2187FF" />}
            {isRequire && <span>*</span>}
          </div>
        )}
        {desc && (
          <ul className="upmd_desc_label">
            {desc.map((e, i) => (
              <li key={i}>{e}</li>
            ))}
          </ul>
        )}
        <div className="upmd_container">
          {!_value || _value.length === 0 ? (
            <div className="input_upload" onClick={_handleClickUpload}>
              <div className="circle_upload">
                <Icons.Plus />
              </div>
              <div className="ul_desc_label">{placeholder}</div>
            </div>
          ) : (
            <div className="file_upload_block">
              {_value &&
                _value.map((e, i) => (
                  <div key={i}>
                    <div className="file_row">
                      <div className="fr_left_col">
                        <Icons.Document />
                        <div>
                          <div className="f_name">image_picker_5</div>
                          <div className="f_size">2 mb bytes</div>
                        </div>
                      </div>
                      <div className="fr_right_col">
                        {/* <div
                  className="ful_icon_wrap"
                  onClick={() => _handleClickPreview(0, files)}
                >
                  <Icons.Visible />
                </div> */}
                        <div
                          className="ful_icon_wrap"
                          onClick={() => onClickRemove(i)}
                        >
                          <Icons.Bin />
                        </div>
                      </div>
                    </div>
                    <div className="file_code">
                      <Inputs.TextField
                        theme_upload
                        value={_value[i]?.secure}
                        placeholder="รหัสเปิดเอกสาร (ถ้ามี)"
                        onChange={(e) => {
                          let temp = _value;
                          temp[i].secure = e;
                          _setValue([...temp]);
                          onChange && onChange(temp);
                        }}
                      />
                    </div>
                  </div>
                ))}

              <div className="input_upload" onClick={_handleClickUpload}>
                <div className="circle_upload">
                  <Icons.Upload />
                </div>
                <div className="ul_desc_label">{placeholder}</div>
              </div>
            </div>
          )}
        </div>
      </UploadMediaWidgetStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal ? true : false}
        onCloseModal={_handleCloseModal}
      >
        {_renderModal}
      </Modals.BodyEmpty>
    </>
  );
};

export default UploadMediaWidget;
