import styled from "styled-components";

export const Step5AccountInfoContainerStyled = styled.div`
  .body_container {
    padding: 20px 16px;
    min-height: calc(100vh - 40px - ${({ theme }) => theme.ETC.HEADER_HEIGHT});
    display: flex;
    flex-direction: column;
    .step_wrap {
      margin-bottom: 14px;
    }
    .form_layout {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;
