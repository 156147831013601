import React from "react";
import { RegisCusInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms, Headers } from "components";

class RegisCusInfoContainer extends React.Component {
  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.LANDING_PAGE);
  };
  render() {
    return (
      <RegisCusInfoContainerStyled>
        <Headers.Normal title="ลงทะเบียน/เข้าสู่ระบบ" />
        <div className="body_container_layout">
          <div className="logo_figture">
            <img
              className="logo_img"
              alt="logo"
              src="/assets/images/icons/logo3.png"
            />
          </div>
          <Forms.RegisCusInfoForm onSubmit={this.handleSubmit} />
        </div>
      </RegisCusInfoContainerStyled>
    );
  }
}

export default withRouter(RegisCusInfoContainer);
