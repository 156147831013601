import styled from "styled-components";

export const FinanceItemStyled = styled.div`
  border-radius: ${({ active }) => (active ? "12px 12px 0 0" : "12px")};
  box-shadow: 0px 2.54px 2.54px 0px #0000001a;
  .fni_header_block {
    padding: 12px;
    background: ${({ theme, active }) =>
      active ? theme.COLORS.PINK_6 : theme.COLORS.WHITE_1};
    .fni_row {
      margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 6px;
      .fni_no {
        border-radius: 8px;
        padding: 5px 10px;
        background: ${({ theme, active }) =>
          active ? theme.COLORS.WHITE_1 : theme.COLORS.PINK_6};
        color: ${({ theme, active }) =>
          active ? theme.COLORS.PINK_6 : theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .btn_fni_delete {
        cursor: pointer;
      }
      .btn_save {
        border-radius: 8px;
        display: flex;
        align-items: center;
        column-gap: 4px;
        background: ${({ theme }) => theme.COLORS.WHITE_1};
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        font-family: inherit;
        cursor: pointer;
      }
    }
    .fni_label {
      color: ${({ theme, active }) =>
        active ? theme.COLORS.WHITE_1 : theme.COLORS.GRAY_11};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .fni_label_big {
      color: ${({ theme, active }) =>
        active ? theme.COLORS.WHITE_1 : theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S30};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
  }
  .fni_footer_block {
    padding: 8px 12px;
    background: ${({ theme }) => theme.COLORS.PINK_6};
    .ff_row {
      display: flex;
      column-gap: 8px;
      justify-content: space-between;
      .ff_label {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .ff_value {
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
      }
    }
  }

  &.theme_standard {
  }
`;
