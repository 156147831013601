import styled from "styled-components";

export const SummaryConfirmContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    padding: 20px 16px;
    .title {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
      &.mb_15 {
        margin-bottom: 15px;
      }
    }
    .cate_block {
      &.mb_28 {
        margin-bottom: 28px;
      }
      .cate_label {
        margin-bottom: 12px;
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .row {
        display: flex;
        justify-content: space-between;
        column-gap: 6px;
        &.bd_b {
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
        }
        .item {
          padding: 8px 0px;
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
      }
    }
  }
  .actino_layout {
    padding: 12px 16px;
  }
`;
