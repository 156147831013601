import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextNumberKeyboadPadStyled } from "./styled";
import { Icons } from "components";

const TextNumberKeyboadPad = ({ theme_standard, onClick }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TextNumberKeyboadPadStyled className={customClass}>
      <div className="pad_keyboad_row">
        {[
          {
            label: "1",
            value: "1",
          },
          {
            label: "2",
            value: "3",
          },
          {
            label: "3",
            value: "3",
          },
        ].map((e, i) => (
          <div key={i} className="pad_col">
            <button
              className="pad_item"
              onClick={() => onClick && onClick(e.value)}
            >
              {e.label}
            </button>
          </div>
        ))}
      </div>
      <div className="pad_keyboad_row">
        {[
          {
            label: "4",
            value: "4",
          },
          {
            label: "5",
            value: "5",
          },
          {
            label: "6",
            value: "6",
          },
        ].map((e, i) => (
          <div key={i} className="pad_col">
            <button
              className="pad_item"
              onClick={() => onClick && onClick(e.value)}
            >
              {e.label}
            </button>
          </div>
        ))}
      </div>
      <div className="pad_keyboad_row">
        {[
          {
            label: "7",
            value: "7",
          },
          {
            label: "8",
            value: "8",
          },
          {
            label: "9",
            value: "9",
          },
        ].map((e, i) => (
          <div key={i} className="pad_col">
            <button
              className="pad_item"
              onClick={() => onClick && onClick(e.value)}
            >
              {e.label}
            </button>
          </div>
        ))}
      </div>
      <div className="pad_keyboad_row">
        <div className="pad_col">
          <div
            className="pad_cancel"
            onClick={() => onClick && onClick("cancel")}
          >
            ยกเลิก
          </div>
        </div>
        <div className="pad_col">
          <div className="pad_item" onClick={() => onClick && onClick("0")}>
            0
          </div>
        </div>
        <div className="pad_col">
          <div className="pad_previous" onClick={() => onClick && onClick("1")}>
            <Icons.Subtract2 width="40" height="29"/>
          </div>
        </div>
      </div>
    </TextNumberKeyboadPadStyled>
  );
};

TextNumberKeyboadPad.propTypes = {};

export default TextNumberKeyboadPad;
