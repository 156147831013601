import styled from "styled-components";

export const UploadDocEtcFormStyled = styled.div`
  .f_info_wrap {
    margin-bottom: 14px;
  }
  .input_wrap {
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    padding: 12px 12px 16px 12px;
    &.mb_17 {
      margin-bottom: 17px;
    }
  }
`;
