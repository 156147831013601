import apiMoneyDDService from "../apiMoneyDDService";

const apiPath = "/api";
const apiPath2 = "/v1/api";

export const moneyDDService = {
  POST_LOGIN: (params) => {
    return apiMoneyDDService.post(`${apiPath}/auth/sign-in`, params);
  },
  POST_LOGIN_VERIFY_OTP: (params) => {
    return apiMoneyDDService.post(`${apiPath}/auth/verify`, params);
  },

  POST_LOAN_CALCULATE: (params) => {
    return apiMoneyDDService.post(`${apiPath}/core/loancal`, params);
  },

  POST_GET_MASTER_DATA_LIST: (params) => {
    return apiMoneyDDService.post(
      `${apiPath2}/bff-loan/get-case-fields`,
      params
    );
  },

  POST_REGIS_STEP_1: (params) => {
    return apiMoneyDDService.post(
      `${apiPath2}/bff-loan/product/4/v1/answer/1`,
      params
    );
  },

  POST_CORE_LOAN: (params) => {
    return apiMoneyDDService.post(`${apiPath}/core/loancal`, params);
  },

  GET_OCUUPATION_TYPE_LV3_LIST: (company_id, stringQuery) => {
    return apiMoneyDDService.get(
      `${apiPath}/companies/${company_id}/locations${
        stringQuery ? stringQuery : ""
      }`
    );
  },

  GET_MY_PROFILE: () => {
    return apiMoneyDDService.get(`${apiPath2}/bff-core/user-profile`);
  },

  // PATCH_LOCATION_UPDATE: (company_id, params) => {
  //   return apiService.patch(
  //     `${apiPath}/companies/${company_id}/locations`,
  //     params
  //   );
  // },
  // DELETE_LOCATION: (location_id) => {
  //   return apiService.delete(`${apiPath}/locations/${location_id}`);
  // },
};
