import styled from "styled-components";

export const CusInfoRecStyled = styled.div`
  border-radius: 14px;
  padding: 15px 6px;
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .cir_left_col {
    flex-basis: 88px;
    flex-shrink: 0;
    img {
      width: 100%;
      height: auto;
    }
  }
  .cir_right_col {
    margin-left: -10px;
    flex: 1;
    display: flex;
    column-gap: 8px;
    .rc_left_col {
      .circle_no {
        border-radius: 20px;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: ${({ theme }) => theme.COLORS.PINK_4};
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
    .rc_right_col {
      .cir_title {
        margin-bottom: 2px;
        text-align: left;
        color: ${({ theme }) => theme.COLORS.PINK_4};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .cir_desc {
        padding-left: 16px;
        margin: 0px;
        text-align: left;
        color: ${({ theme }) => theme.COLORS.GRAY_9};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        li {
          list-style-type: disc;
        }
      }
    }
  }

  &.theme_standard {
  }
`;
