import React from "react";
import { CreditConsentContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms, Headers } from "components";

class CreditConsentContainer extends React.Component {
  handleSubmit = (values) => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_BOOK_CONSENT);
  };

  render() {
    return (
      <CreditConsentContainerStyled>
        <Headers.Normal title="ยืนยันการเลือกรีไฟแนนซ์" />
        <div className="title">สัญญาสินเชื่อ</div>
        <div className="body_sec">
          ข้าพเจ้าได้อ่านและเข้าใจรายละเอียดในข้อกำหนดและเงื่อนไข ของผลิตภัณฑ์
          ดังนี้ 1. ประเภทและรูปแบบผลิตภัณฑ์ที่ให้บริการ บริษัท เงินดีดี จำกัด
          ("บริษัท") ให้บริการและเสนอขาย ผลิตภัณฑ์สินเชื่อกู๊ดมันนี่
          ที่ซึ่งเป็นสินเชื่อแบบไม่มีหลัก ทรัพย์หรือบุคคลค้ำประกัน
          โดยคิดอัตราดอกเบี้ยแบบลดต้น ลดดอกสูงสุดไม่เกินตามที่กฎหมายกำหนด*
          ได้แก่ 1) สินเชื่อส่วนบุคคลกู๊ดมันนี่ (สินเชื่อส่วนบุคคลภายใต้การ
          กำกับ) มีวัตถุประสงค์เพื่อเพิ่มสภาพคล่องในการดำรงชีพ /
          เพื่อการประกอบอาชีพ 2) สินเชื่อนาโนกู๊ดมันนี่
          (สินเชื่อรายย่อยเพื่อการประกอบ อาชีพภายใต้การกำกับ)
          มีวัตถุประสงค์เพื่อการประกอบ อาชีพ ทั้งนี้ รูปแบบของผลิตภัณฑ์ทั้ง 2
          ประเภท สามารถแบ่งย่อย ได้ 2 รูปแบบ ได้แก่ 1)
          การให้กู้ยืมเงินในลักษณะที่มีกำหนดระยะเวลา (Term เoan)
          หรือชื่อที่ปรากฎในแอปพลิเคชัน Good Money เรียก ว่า
          "รับเงินก้อนครั้งเดียว" หรือชื่ออื่นใดที่บริษัทจะกำหนดใน ภายหลัง 2)
          การให้กู้ยืมเงินในลักษณะการให้วงเงินสินเชื่อแบบ หมุนเวียน
          หรือชื่อที่ปรากฎในแอปพลิเคชัน Good Money เรียกว่า "รับวงเงินหมุนเวียน"
          หรือชื่ออื่นใดที่บริษัทจะ กำหนดในภายหลัง
        </div>
        <div className="footer_sec">
          <Forms.TermAndConditionForm onSubmit={this.handleSubmit} />
        </div>
      </CreditConsentContainerStyled>
    );
  }
}

export default withRouter(CreditConsentContainer);
