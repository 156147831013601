import styled from "styled-components";

export const ReFinanceInfoStyled = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
  box-shadow: 0px 4px 8px -2px #1018281a;
  .fni_top_block {
    border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    padding: 4px 12px;
    .fni_title {
      margin-bottom: 6px;
      color: ${({ theme }) => theme.COLORS.BLACK_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
    .fni_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 6px;
      &.mb_6 {
        margin-bottom: 6px;
      }
      &.mb_9 {
        margin-bottom: 9px;
      }
      .fni_label {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        &.pink {
          color: ${({ theme }) => theme.COLORS.PINK_6};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        }
      }
      .fni_value {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
        &.pink {
          color: ${({ theme }) => theme.COLORS.PINK_6};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        }
      }
    }
  }

  .fni_bottom_block {
    padding: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    .bb_label {
      color: ${({ theme }) => theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .bb_value {
      border-radius: 8px;
      padding: 4px 8px;
      background: ${({ theme }) => theme.COLORS.GRAY_7};
      color: ${({ theme }) => theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
    }
  }

  &.theme_standard {
  }
`;
