import styled from "styled-components";

export const InputCameraStyled = styled.div`
  .btn_upm_item {
    margin-bottom: 7px;
    border-radius: 16px;
    padding: 16px;
    width: 100%;
    background: ${({ theme, disabled }) =>
      disabled
        ? theme.COLORS.GRAY_1
        : "linear-gradient(102.16deg, #ED008C 57.64%, #FF9300 97.99%)"};
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 32px;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    font-family: inherit;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0px;
    }
    .value_txt {
      min-width: 80px;
    }
  }

  &.theme_standard {
  }
`;

export const CameraContainer = styled.div`
  margin: auto;
  width: 100vw;
  max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO};
  height: 100vh;
  background: ${({ theme }) => theme.COLORS.BLACK_1};
  .btn_take_phone {
    position: absolute;
    bottom: 0px;
    border-radius: 70px;
    width: 70px;
    height: 70px;
    left: 0px;
    right: 0px;
    bottom: 25px;
    margin: auto;
    cursor: pointer;
  }
`;
