import React, { useState } from "react";
import { PinOTPVerifyFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Countdown from "react-countdown";
import { MASK_PHONE_NUMBER } from "utils/functions/phoneNumber";
// import { moneyDDService } from "apiServices";
// import { toast } from "react-toastify";
import { Inputs } from "components";

const PinOTPVerifyForm = ({ phoneNumber, onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    // setError,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [_countdown, _setCountdown] = useState(Date.now() + 50000);
  const [_resendBtnDisabled, _setResendBtnDisabled] = useState(true);
  const [_requestOTPValue, _setRequestOTPValue] = useState();
  const [_isOTPVerify, _setIsOTPVerify] = useState(false);
  const [_isRequestOTPOnce, _setIsRequestOTPOnce] = useState(false);

  const _onCompleteCoutdownOTP = ({ onSubmit }) => {
    _setResendBtnDisabled(false);
  };

  const _handleVerifyOTP = async (e) => {
    onSubmit(e);

    // if (e) {
    //   let params = {
    //     otpValue: e,
    //   };
    //   let res = await moneyDDService.POST_LOGIN_VERIFY_OTP(params);
    //   if (res && res.respCode === 2000) {
    //     _setIsOTPVerify(true);
    //     onSubmit(params);
    //   } else {
    //     setError(`otp`, {
    //       type: "custom",
    //       message: "รหัสยินยันของคุณไม่ถูกต้อง",
    //     });
    //     _setIsOTPVerify(false);
    //     toast.error(res?.respMsg || "รหัสยินยันของคุณไม่ถูกต้อง");
    //   }
    // }
  };

  return (
    <PinOTPVerifyFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_title">ยืนยัน OTP</div>
        <div className="f_desc">รหัส OTP จะถูกส่งไปที่หมายเลข</div>
        <div className="phone_label">
          {MASK_PHONE_NUMBER(phoneNumber || "0635454654")}
        </div>
        <Countdown
          // date={Date.now() + 60000 * 5}
          date={_countdown}
          renderer={({ seconds }) => (
            <div className="coutdown_label">
              (<span>{_requestOTPValue?.reference_Key} </span>
              {`${seconds < 10 ? `0${seconds}` : seconds}`}วิ)
            </div>
          )}
          onComplete={_onCompleteCoutdownOTP}
        />
        <div className="ref_code">
          ยืนยัน OTP <span className="red">*</span>{" "}
          <span className="code_ref_label">Ref. (GNRABP)</span>
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.OtpField
                {...field}
                // disabled={!_requestOTPValue}
                onChangeCus={_handleVerifyOTP}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("otp");
                }}
                errors={errors.otp?.message}
              />
            )}
            name="otp"
            defaultValue=""
          />
        </div>
        {/* <Controller
          control={control}
          render={({ field }) => (
            <Inputs.TextField
              {...field}
              // placeholder={t('test')}
              // errors={errors.phone?.message}
            />
          )}
          name="test"
          defaultValue=""
        />
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        /> */}
      </form>
    </PinOTPVerifyFormStyled>
  );
};

PinOTPVerifyForm.propTypes = {};

export default PinOTPVerifyForm;
