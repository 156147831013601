import React, { useEffect } from "react";
import { ServiceProviderSortFormStyled } from "./styled";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Cards, Icons } from "components";

// ฟังก์ชันในการจัดเรียงแถวใหม่เมื่อมีการย้าย
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const ServiceProviderSortForm = ({ initialValues, onUnSelect, onSubmit }) => {
  const schema = yup.object().shape({
    // test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    // formState: { errors, isSubmitting, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    fields,
    // append,
    // move,
    //  remove
  } = useFieldArray({
    control,
    name: "service_provider_list",
  });

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const value = getValues("service_provider_list");
    const updatedData = reorder(
      value,
      result.source.index,
      result.destination.index
    );
    // setData(updatedData);
    setValue("service_provider_list", updatedData);
  };

  return (
    <ServiceProviderSortFormStyled>
      <DragDropContext onDragEnd={onDragEnd}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Droppable droppableId="droppable-table">
            {(provided) => (
              <div
                className="f_container"
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {fields.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={String(index)}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        key={String(index)}
                        className="service_item_wrap"
                        // onClick={() => move(index, index + 1)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={{
                          ...provided.draggableProps.style,
                        }}
                        // onClick={() => {
                        //   if (index !== 0) {
                        //     move(index, index - 1);
                        //   }
                        // }}
                      >
                        <Icons.Move />
                        <div className="sv_col">
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Cards.FinancialProvider
                                isMove
                                name={item.name}
                                total_dept={item.total_dept}
                                installment={item.installment}
                                no={index + 1}
                                onSelect={() => onUnSelect(index, item)}
                              />
                            )}
                            name={`service_provider_list.${index}`}
                            defaultValue=""
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
          {/* <Buttons.BgStandard type="submit" label={"ทำการรีไฟแนนซ์"} /> */}
        </form>
      </DragDropContext>
    </ServiceProviderSortFormStyled>
  );
};

ServiceProviderSortForm.propTypes = {};

export default ServiceProviderSortForm;
