import styled from "styled-components";

export const TextFieldPrefixIconFieldStyled = styled.div`
  .label {
    margin-bottom: 12px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .input_block {
    position: relative;
    .ip_icon {
      position: absolute;
      top: 15.5px;
      left: 17px;
    }
    input {
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.COLORS.PINK_8};
      padding: 14px 12px 14px 48px;
      width: calc(100% - 62px);
      background: ${({ theme, disabled }) =>
        !disabled ? "transparent" : theme.COLORS.GRAY_8};
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-family: inherit;
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      &::placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }
    input:focus {
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  .theme_standard {
  }
`;
