import styled from "styled-components";

export const InputOTPWidgetStyled = styled.div`
  position: relative;
  .label {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.ORANGE_1};
    }
  }
  .ipotp_container {
    border-radius: 12px;
    border: 1px solid ${({ theme }) => theme.COLORS.PINK_6};
    padding: 8px 6px 8px 14px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    .ipotp_block {
      flex: 1;
      display: flex;
      align-items: center;
      column-gap: 8px;
      input {
        border: none;
        width: 100%;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        font-family: inherit;
      }
    }

    .btn_otp {
      border-radius: 8px;
      padding: 11px 14px;
      background: ${({ theme }) => theme.COLORS.PINK_6};
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
      font-family: inherit;
      cursor: pointer;
      &:disabled {
        background: ${({ theme }) => theme.COLORS.GRAY_1};;
      }
    }
  }
`;
