import React from "react";
import { connect } from "react-redux";
import { AgreementPublicInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { AlertControl } from "components/Modals/Alert";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxFinanceRequestAgreementPublicInfo } from "store/reducers/financeRequestReducer";
import { Forms, Headers } from "components";

class AgreementPublicInfoContainer extends React.Component {
  handleSubmit = (values) => {
    switch (values.test) {
      case "1":
        AlertControl.show({
          description: [
            "คุณยินยอมให้เปิดเผยข้อมูลเครดิต",
            "เพื่อประกอบการพิจารณาสินเชื่อ",
          ],
          btnLabel1: "ยกเลิก",
          btnLabel2: "ยืนยัน",
          onEvent2: () => {
            this.props.setReduxFinanceRequestAgreementPublicInfo(true)
            this.props.router.navigate(ROUTE_PATH.AGREEMENT_PUBLIC_INFO_LOGIN_CONFIRM);
          },
        });
        break;
      case "2":
        AlertControl.show({
          description: [
            "คุณไม่ยินยอมให้เปิดเผยข้อมูลเครดิต",
            "เพื่อประกอบการพิจารณาสินเชื่อ",
          ],
          btnLabel1: "ยกเลิก",
          btnLabel2: "ยืนยัน",
          onEvent2: () => {
            this.props.setReduxFinanceRequestAgreementPublicInfo(false)
            this.props.router.navigate(ROUTE_PATH.AGREEMENT_PUBLIC_INFO_LOGIN_CONFIRM);
          },
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <AgreementPublicInfoContainerStyled>
        <Headers.Logo />
        <div className="title">
          ความยินยอมในการเปิดเผยข้อมูล โดยวิธีการผ่านระบบอินเทอร์เน็ต
        </div>
        <div className="body_sec">
          <Forms.AgreementPublicInfoForm onSubmit={this.handleSubmit} />
        </div>
      </AgreementPublicInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxFinanceRequestAgreementPublicInfo: (data) =>
    dispatch(setReduxFinanceRequestAgreementPublicInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AgreementPublicInfoContainer));
