import styled from "styled-components";

export const FinanceSumStyled = styled.div`
  border-radius: 10px;
  padding: 22px 16px 17px 16px;
  background: ${({ theme }) => theme.COLORS.PINK_1};
  .fns_row {
    margin-bottom: 10px;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    column-gap: 6px;
    .fns_sub_row {
      display: flex;
      column-gap: 16px;
    }
  }
  .fns_label {
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    &.txt_right {
      text-align: right;
    }
  }
  .fns_sum_label {
    text-align: right;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.BOLD};
  }
  .fns_rec {
    border-radius: 10px;
    padding: 13px 16px;
    display: flex;
    column-gap: 7px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .sr_left_col {
      padding-top: 3px;
      flex-basis: 12px;
      flex-shrink: 0px;
    }
    .sr_right_col {
      flex: 1;
      .result_label {
        color: ${({ theme }) => theme.COLORS.GRAY_2};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .condition_label {
        color: ${({ theme }) => theme.COLORS.PINK_4};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
    }
  }

  &.theme_standard {
  }
`;
