export default function renderIcon({
  width = "11",
  height = "7",
  color = "#ED018C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2007_365)">
        <path
          d="M10.7203 3.00345C9.89494 1.20655 7.75058 0 5.38058 0H5.36971C3.00645 0.00449787 0.864716 1.21068 0.0416053 3.00345L0 3.09341L0.0416053 3.18412C0.866965 4.98139 3.01132 6.1872 5.38133 6.1872H5.3922C7.7562 6.18345 9.89719 4.97727 10.7203 3.18412L10.7619 3.09341L10.7203 3.00345ZM5.39145 5.7554H5.38133C3.21785 5.7554 1.26315 4.69053 0.477149 3.09341C1.26128 1.50079 3.21223 0.435169 5.37046 0.431796H5.38133C7.54405 0.431796 9.4995 1.49742 10.2855 3.09379C9.50063 4.68716 7.5493 5.75278 5.39107 5.75577L5.39145 5.7554Z"
          fill={color}
        />
        <path
          d="M5.40365 0.772949C4.15324 0.772949 3.13672 1.79022 3.13672 3.04063C3.13672 4.29103 4.15324 5.3083 5.40365 5.3083C6.65405 5.3083 7.67132 4.29103 7.67132 3.04063C7.67132 1.79022 6.65405 0.772949 5.40365 0.772949ZM5.40365 4.87651C4.39125 4.87651 3.56851 4.05265 3.56851 3.04063C3.56851 2.0286 4.39162 1.20474 5.40365 1.20474C6.41567 1.20474 7.23953 2.0286 7.23953 3.04063C7.23953 4.05265 6.41567 4.87651 5.40365 4.87651Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2007_365">
          <rect width="10.7619" height="6.1872" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
