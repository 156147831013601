import styled from "styled-components";

export const InfomationListStyled = styled.div`
  border-radius: 9px;
  padding: 10px 22px;
  display: flex;
  column-gap: 5px;
  background: ${({ theme }) => theme.COLORS.PINK_5};
  .info_icon_wrap {
    padding-top: 2px;
  }
  .info_block {
    margin: 0;
    padding-left: 16px;
    .info_label {
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }

  &.theme_standard {
  }

  &.theme_title {
    padding: 8px;
    flex-direction: column;
    .info_title {
      margin-bottom: 4px;
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .info_block {
      padding-left: 21px;
    }
  }
`;
