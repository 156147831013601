import React, { useState } from "react";
import { EmpGeneralInfoFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RENDER_MASTER_DATA_OPTION } from "utils/functions/getObjectKey";
import { moneyDDService } from "apiServices";
import { Buttons, Cards, Inputs } from "components";

const EmpGeneralInfoForm = ({
  occupationLv1Options,
  onChangeOccupation,
  onSubmit,
}) => {
  const schema = yup.object().shape({
    occupation_type: yup.object().nullable().required("Required"),
    occupation: yup.object().nullable().required("Required"),
    job_category: yup.object().nullable().required("Required"),
    monthly_income: yup
      .number()
      .typeError("กรุณาระบุรายได้ต่อเดือน")
      .min(10000, "รายได้เริ่มต้น 10,000 บาท *")
      .required("กรุณาระบุรายได้ต่อเดือน"),
    monthly_expenses_debt: yup
      .string()
      .required("กรุณาระบุรายจ่ายทั่วไปต่อเดือน"),
    credit_limit: yup.string().required("Required"),
    installment_period: yup.string().required("Required"),
    payment_due_date: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchField = watch();

  const [_occupationOptions, _setOccupationOptions] = useState([]);
  const [_jobCategoryOptions, _setJobCategoryOptions] = useState([]);

  const _handleChangeOccupationType = async (e) => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [
        {
          field_name: "_occupation.employType",
          field_value: e.value,
        },
      ],
      target_fields: ["_occupation.type"],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      setValue("occupation", "");
      setValue("job_category", "");
      _setOccupationOptions(
        RENDER_MASTER_DATA_OPTION(res.data?.target_fields[0])
      );
      onChangeOccupation && onChangeOccupation(e);
    }
  };

  const _handleChangeOccupation = async (e) => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [
        {
          field_name: "_occupation.type",
          field_value: e.value,
        },
      ],
      target_fields: ["_occupation.subType"],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      setValue("job_category", "");
      _setJobCategoryOptions(
        RENDER_MASTER_DATA_OPTION(res.data?.target_fields[0])
      );
    }
  };

  const _handleChangeCredit = () => {
    setValue("installment_period", "");
    setValue("payment_due_date", "");
  };

  const _renderSpilce = (occupationLv1Options) => {
    let temp = [...occupationLv1Options];
    temp.splice(1, 1);
    return temp;
  };

  return (
    <EmpGeneralInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form_title">ข้อมูลเกี่ยวกับอาชีพ</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="ลักษณะอาชีพ"
                options={
                  occupationLv1Options
                    ? _renderSpilce(occupationLv1Options)
                    : []
                }
                placeholder="กรุณาเลือก"
                errors={errors.occupation_type?.message}
                onChangeCus={_handleChangeOccupationType}
              />
            )}
            name="occupation_type"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="อาชีพ"
                options={_occupationOptions}
                placeholder="กรุณาเลือก"
                errors={errors.occupation?.message}
                onChangeCus={_handleChangeOccupation}
              />
            )}
            name="occupation"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_15">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="ประเภทอาชีพ"
                options={_jobCategoryOptions}
                placeholder="เลือกประเภทอาชีพ"
                errors={errors.job_category?.message}
              />
            )}
            name="job_category"
            defaultValue=""
          />
        </div>
        {watchField?.occupation_type &&
          (watchField?.occupation_type.value === "SELF_EMPLOYED" ? (
            <>
              <div className="slip_title">หลักฐานการแสดงรายได้</div>
              <ul className="slip_list">
                <li>ใช้เอกสารจดทะเบียนบริษัท</li>
                <li>ใช้รายการเดินบัญชี 6 เดือน</li>
              </ul>
            </>
          ) : (
            <>
              <div className="slip_title">หลักฐานการแสดงรายได้</div>
              <ul className="slip_list">
                <li>ใช้สลิปเงินเดือน</li>
              </ul>
            </>
          ))}

        <div className="input_wrap mb_12">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextNumber
                {...field}
                isRequire
                label="รายได้ต่อเดือน"
                placeholder="กรุณากรอก"
                errors={errors.monthly_income?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("monthly_income");
                }}
              />
            )}
            name="monthly_income"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextNumber
                {...field}
                isRequire
                label="รายจ่ายและภาระหนี้ต่อเดือน"
                placeholder="กรุณากรอก"
                errors={errors.monthly_expenses_debt?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("monthly_expenses_debt");
                }}
              />
            )}
            name="monthly_expenses_debt"
            defaultValue=""
          />
        </div>
        <div className="form_title mb_20">เลือกจำนวนเงินและระยะเวลาการผ่อน</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextNumberBtn
                {...field}
                isRequire
                label="เลือกวงเงิน"
                labelSuffix="(สูงสุด 500,000.00  บาท)"
                maxLength={500000}
                placeholder="กรุณากรอก"
                errors={errors.credit_limit?.message}
                onChangeCus={_handleChangeCredit}
              />
            )}
            name="credit_limit"
            defaultValue={"10000"}
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.RadioBox
                {...field}
                isRequire
                label="เลือกระยะเวลาการผ่อน"
                desc="(วงเงินกู้ 10,000 - 100,000 บาท ผ่อนสูงสุด 48 เดือน วงเงินกู้ มากกว่า 100,000 บาท ผ่อนสูงสุด 60 เดือน)"
                options={RENDER_DATA(watchField?.credit_limit)}
                errors={errors.installment_period?.message}
              />
            )}
            name="installment_period"
            defaultValue=""
          />
        </div>
        <div className="input_wrap mb_23">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.RadioBox
                {...field}
                isRequire
                theme_three
                label="เลือกวันครบกำหนดชำระ"
                options={[
                  {
                    label: "ทุกวันที่ 2",
                    value: "6",
                  },
                  {
                    label: "ทุกวันที่ 17",
                    value: "12",
                  },
                  {
                    label: "ทุกวันที่ 27",
                    value: "27",
                  },
                ]}
                errors={errors.payment_due_date?.message}
              />
            )}
            name="payment_due_date"
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Cards.FinanceSum
            occupation_type={watchField?.occupation_type?.value}
            credit_limit={watchField?.credit_limit}
            installment_period={watchField?.installment_period}
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </EmpGeneralInfoFormStyled>
  );
};

const RENDER_DATA = (e) => {
  const isFull = !(!e ? false : e >= 100000);
  return [
    {
      label: "6",
      value: "6",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "18",
      value: "18",
    },
    {
      label: "24",
      value: "24",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "36",
      value: "36",
    },
    {
      label: "42",
      value: "42",
    },
    {
      label: "48",
      value: "48",
    },
    {
      label: "54",
      value: "54",
      disabled: isFull,
    },
    {
      label: "60",
      value: "60",
      disabled: isFull,
    },
  ];
};

EmpGeneralInfoForm.propTypes = {};

export default EmpGeneralInfoForm;
