import React from "react";
import { NCBConsentSummaryContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Forms, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class NCBConsentSummaryContainer extends React.Component {
  handleSubmit = () => {
    this.props.router.navigate(ROUTE_PATH.REGISTER_FINANCE_SUCCESS);
  };

  render() {
    return (
      <NCBConsentSummaryContainerStyled>
        <Headers.Logo />
        <div className="title">
          ความยินยอมในการเปิดเผยข้อมูล โดยวิธีการผ่านระบบอินเทอร์เน็ต
        </div>
        <div className="body_sec">
          <Forms.NCBConsentSummaryForm onSubmit={this.handleSubmit} />
        </div>
      </NCBConsentSummaryContainerStyled>
    );
  }
}

export default withRouter(NCBConsentSummaryContainer);
