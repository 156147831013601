import ApproveFail from "./ApproveFail";
import ApproveSuccess from "./ApproveSuccess";
import ArrowLeft from "./ArrowLeft";
import ArrowLeftCircle from "./ArrowLeftCircle";
import Bin from "./Bin";
import Bin2 from "./Bin2";
import Checkbox from "./Checkbox";
import CheckMark from "./CheckMark";
import CirclePlus from "./CirclePlus";
import Close from "./Close";
import DeleteInput from "./DeleteInput";
import Document from "./Document";
import Download from "./Download";
import DropdownArrowBottom from "./DropdownArrowBottom";
import Email from "./Email";
import Infomation from "./Infomation";
import Infomation2 from "./Infomation2";
import Loading from "./Loading";
import Move from "./Move";
import Passed from "./Passed";
import Pending from "./Pending";
import Phone from "./Phone";
import Plus from "./Plus";
import RadioCircle from "./RadioCircle";
import RadioCircle2 from "./RadioCircle2";
import Secure from "./Secure";
import Subtract from "./Subtract";
import Subtract2 from "./Subtract2";
import Upload from "./Upload";
import UploadCamera from "./UploadCamera";
import UploadDoc from "./UploadDoc";
import UploadPicture from "./UploadPicture";
import Visible from "./Visible";

const EXPORT = {
  ApproveFail,
  ApproveSuccess,
  ArrowLeft,
  ArrowLeftCircle,
  Bin,
  Bin2,
  Checkbox,
  CheckMark,
  CirclePlus,
  Close,
  DeleteInput,
  Document,
  Download,
  DropdownArrowBottom,
  Email,
  Infomation,
  Infomation2,
  Loading,
  Move,
  Passed,
  Pending,
  Phone,
  Plus,
  RadioCircle,
  RadioCircle2,
  Secure,
  Subtract,
  Subtract2,
  Upload,
  UploadCamera,
  UploadDoc,
  UploadPicture,
  Visible,
};

export default EXPORT;
