import React from "react";
import { OfferContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Headers } from "components";

class OfferContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.TERM_AND_CONDITION);
  };
  render() {
    return (
      <OfferContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="scroll_container">
          <div className="offer_container">
            <img
              className="offer_img"
              alt="offer img"
              src="/assets/images/mockups/offer.jpg"
            />
            <div className="contentd_block">
              <div className="article_layout">
                <div className="title">วัตถุประสงค์</div>
                <div className="desc">
                  เพื่อนำไปชำระหนี้ในรูปแบบการปิดชำระยอดสินเชื่อ
                </div>
              </div>
              <div className="article_layout">
                <div className="title">วงเงินสินเชื่อ</div>
                <ul className="desc_list">
                  <li>
                    สำหรับผู้ขอสินเชื่อที่มีรายได้น้อยกว่า 30,000 บาท
                    จะได้รับวงเงินอนุมัติสูงสุดไม่เกิน 1.5 เท่าของรายได้ (Net
                    Income)
                  </li>
                  <li>
                    สำหรับผู้ขอสินเชื่อที่มีรายได้ 30,000 บาทขึ้นไป
                    จะได้รับวงเงินอนุมัติสูงสุดไม่เกิน 5 เท่าของรายได้ (Net
                    Income)
                  </li>
                  <li>
                    วงเงินอนุมัติสูงสุดไม่เกิน 500,000 บาท (ห้าแสนบาทถ้วน)
                  </li>
                </ul>
              </div>
              <div className="article_layout">
                <div className="title">คุณสมบัติของผู้ขอสินเชื่อ</div>
                <div className="desc fw_regular">สำหรับผู้มีรายได้ประจำ</div>
                <ul className="desc_list mb_16">
                  <li>สัญชาติไทย อายุ 20 ปีบริบูรณ์ ถึง 60 ปีบริบูรณ์</li>
                  <li>เป็นพนักงานประจำ</li>
                  <li>รายได้ตั้งแต่ 10,000 บาท</li>
                  <li>อายุงานตั้งแต่ 6 เดือนขึ้นไป</li>
                </ul>
                <div className="desc fw_regular">สำหรับเจ้าของกิจการ</div>
                <ul className="desc_list mb_16">
                  <li>สัญชาติไทย อายุ 20 ปีบริบูรณ์ ถึง 60 ปีบริบูรณ์</li>
                  <li>เป็นเจ้าของกิจการจดทะเบียนบริษัท</li>
                  <li>รายได้ตั้งแต่ 10,000 บาท</li>
                  <li>ประกอบกิจการตั้งแต่ 1 ปีขึ้นไป</li>
                </ul>
              </div>
              <div className="article_layout">
                <div className="title">เอกสารประกอบการสมัคร</div>
                <div className="desc fw_regular">สำหรับผู้มีรายได้ประจำ</div>
                <ul className="desc_list mb_16">
                  <li>สำเนาบัตรประชาชน</li>
                  <li>
                    หลักฐานแสดงรายได้ เช่น สำเนาใบแจ้งเงินเดือนปัจจุบัน (pay
                    slip) หรือ หนังสือรับรองเงินเดือน
                  </li>
                  <li>
                    สำเนารายการเดินบัญชีเงินฝาก (Bank Statement) ย้อนหลัง 6
                    เดือน
                  </li>
                  <li>
                    สำเนาหน้าสมุดบัญชีเงินฝากที่เปิดไว้กับธนาคารในประเทศไทยสำหรับรับโอนเงินสินเชื่อภายหลังการอนุมัติ
                  </li>
                  <li>รายงานข้อมูลเครดิตบูโรแบบมีคะแนน</li>
                  <li>
                    ใบเรียกเก็บเงิน หรือใบแจ้งหนี้ที่มี QR Code หรือ Bar Code
                    เดือนล่าสุด และ/หรือ ใบเสร็จรับเงิน ที่ชำระหนี้ ย้อนหลัง 6
                    เดือนติดต่อกัน
                  </li>
                  <li>หลักฐานแสดงรายได้อื่น ๆ (ถ้ามี)</li>
                </ul>
                <div className="desc fw_regular">สำหรับเจ้าของกิจการ</div>
                <ul className="desc_list">
                  <li>สำเนาบัตรประชาชน</li>
                  <li>
                    สำเนารายการเดินบัญชีเงินฝาก (Bank Statement) 6 เดือน
                    (รายการเดินบัญชีเงินฝากซึ่งเปิดในนามส่วนบุคคลสำหรับรับรายได้จากการประกอบธุรกิจหรือประกอบอาชีพ)
                  </li>
                  <li>
                    สำเนาหน้าสมุดบัญชีเงินฝากที่เปิดไว้กับธนาคารในประเทศไทยสำหรับรับโอนเงินสินเชื่อภายหลังการอนุมัติ
                  </li>
                  <li>
                    สำเนาหลักฐานการประกอบอาชีพ เช่น สำเนาทะเบียนพาณิชย์
                    สำเนาจดทะเบียนภาษีมูลค่าเพิ่ม สำเนาหนังสือจดทะเบียนบริษัท
                    จดทะเบียนมาแล้วอย่างน้อย 1 ปี
                  </li>
                  <li>
                    หลักฐานที่แสดงถึงสภาพการประกอบกิจการหรือดำเนินธุรกิจโดยชัดแจ้ง
                    เช่น รูปถ่ายร้านค้า, ร้านค้าออนไลน์ (Website, Facebook,
                    Line)
                  </li>
                  <li>รายงานข้อมูลเครดิตบูโรแบบมีคะแนน</li>
                  <li>
                    ใบเรียกเก็บเงิน หรือใบแจ้งหนี้ที่มี QR Code หรือ Bar Code
                    เดือนล่าสุด และ/หรือ ใบเสร็จรับเงินที่ชำระหนี้ ย้อนหลัง 6
                    เดือนติดต่อกัน
                  </li>
                  <li>หลักฐานแสดงรายได้อื่น ๆ (ถ้ามี)</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="action_layout">
          <Buttons.BgStandard
            label="สมัครสินเชื่อ"
            onClick={this.handleClickNext}
          />
        </div>
      </OfferContainerStyled>
    );
  }
}

export default withRouter(OfferContainer);
