import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinanceRegisStepStyled } from "./styled";

const FinanceRegisStep = ({
  theme_standard,
  title,
  subTitle,
  stepCurrent,
  total,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  return (
    <FinanceRegisStepStyled className={customClass}>
      <div className="financ_regis_container">
        <div className="frs_left_col">{title}</div>
        <div className="frs_right_col">
          <div className="frs_no_label">ขั้นตอน</div>
          <div className="frs_no">
            <span className="highlight">{stepCurrent}</span>/{total}
          </div>
        </div>
      </div>
      {subTitle && <div className="frc_sub_title">{subTitle}</div>}
    </FinanceRegisStepStyled>
  );
};

FinanceRegisStep.propTypes = {};

export default FinanceRegisStep;
