import styled from "styled-components";

export const TextNumberStyled = styled.div`
  .label {
    margin-bottom: 2px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .input_txt_block {
    position: relative;
    display: flex;
    align-items: center;
    input {
      border-radius: 8px;
      border: 1px solid
        ${({ theme, err }) => (err ? theme.COLORS.RED_1 : theme.COLORS.PINK_3)};
      padding: 6.5px 64px 6.5px 12px;
      width: calc(100% - 24px);
      background: ${({ theme, disabled }) =>
        !disabled ? "transparent" : theme.COLORS.GRAY_8};
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-family: inherit;
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      &::placeholder {
        color: ${({ theme }) => theme.COLORS.GRAY_10};
      }
    }
    .suffix_block {
      position: absolute;
      right: 8px;
      display: flex;
      align-items: center;
      column-gap: 7px;
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      cursor: pointer;
    }
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  &.theme_standard {
  }
`;
