import { forwardRef } from "react";
import { CheckboxContainer } from "./styled";
import cx from "classnames";
import { Icons } from "components";

const Checkbox = forwardRef((props, ref) => {
  const { theme_standard, label, value, onChange, onChangeCus, errors } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _onChange = () => {
    const temp = value ? false : true;
    onChange(temp);
    onChangeCus && onChangeCus(temp);
  };

  return (
    <CheckboxContainer className={customClass}>
      <div ref={ref} className="checkbox_container">
        <div onClick={_onChange}>
          {value ? (
            <Icons.Checkbox isActive width="20" height="20" />
          ) : (
            <Icons.Checkbox width="20" height="20" />
          )}
        </div>
        <div className="checkbox_label">
          <span onClick={_onChange}>{label}</span>
        </div>
      </div>
      {errors && <div className="errors">{errors}</div>}
    </CheckboxContainer>
  );
});

// ({
//   theme_standard,
//   inputRef,
//   label,
//   linkLabel,
//   value,
//   onChange,
//   onClickLink,
//   errors,
// }) => {

export default Checkbox;
