import styled from "styled-components";

export const CreditLimitEditFormStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  form {
    flex: 1;
    display: flex;
    flex-direction: column;
    .f_container {
      padding: 0px 20px;
      flex: 1;
      .form_title {
        margin-bottom: 20px;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
      .input_wrap {
        margin-bottom: 20px;
        &.mb_26 {
          margin-bottom: 26px;
        }
      }
      .summay_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 6px;
        .s_label {
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
        .s_value {
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
        }
      }
    }
    .f_action {
      padding: 12px 20px;
    }
  }
`;
