import React, { useEffect } from "react";
import { AccountInfoCreateFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { UploadMediaWidget } from "widgets";
import { Buttons, Displays, Inputs } from "components";

const AccountInfoCreateForm = ({ bankOptions, initialValues, onSubmit }) => {
  const schema = yup.object().shape({
    service_provider: yup.object().nullable().required("กรุณากรอก"),
    total_debt: yup.string().required("กรุณาระบุยอดหนี้ทั้งหมด"),
    monthly_installment: yup.string().required("กรุณาระบุยอดผ่อนต่อเดือน"),
    // ref1: yup.string().required("กรุณากรอก"),
    // ref2: yup.string().required("กรุณากรอก"),
    latest_invoice: yup.array().required("กรุณากรอก"),
    // payment_receipt: yup.array().required("กรุณากรอก"),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, isValid },
    watch,
    trigger,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchField = watch();

  useEffect(() => {
    if (initialValues) {
      reset({ ...initialValues });
    }
  }, [initialValues]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AccountInfoCreateFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_title1">
          กรุณาระบุข้อมูลสินเชื่อที่ต้องการรีไฟแนนซ์
        </div>
        <div className="f_title2">ผู้ให้บริการทางการเงิน</div>
        <div className="f_title3">กรุณาเลือกผู้ให้บริการทางการเงิน</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.DropdownField
                {...field}
                isRequire
                label="ผู้ให้บริการทางการเงิน"
                options={bankOptions || []}
                placeholder={"กรุณาเลือกผู้ให้บริการทางการเงิน"}
                errors={errors?.service_provider?.message}
              />
            )}
            name={`service_provider`}
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextNumberFloat
                {...field}
                isRequire
                label="ยอดหนี้ทั้งหมด"
                regexFormat={/^\d+(\.\d+)?$/}
                placeholder="กรุณาระบุ"
                errors={errors?.total_debt?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("total_debt");
                }}
              />
            )}
            name={`total_debt`}
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextNumberFloat
                {...field}
                isRequire
                label="ยอดผ่อนต่อเดือน"
                regexFormat={/^\d+(\.\d+)?$/}
                placeholder="กรุณาระบุ"
                errors={errors?.monthly_installment?.message}
                onChange={(e) => {
                  field.onChange(e);
                  trigger("monthly_installment");
                }}
              />
            )}
            name={`monthly_installment`}
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="Ref1"
                placeholder="กรุณาระบุ"
                errors={errors?.ref1?.message}
              />
            )}
            name={`ref1`}
            defaultValue=""
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="Ref2"
                placeholder="กรุณาระบุ"
                errors={errors?.ref2?.message}
              />
            )}
            name={`ref2`}
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="input_wrap">
          <Displays.InfomationList
            theme_title
            type="title_label"
            title="หมายเหตุ"
            data={[
              "อัพโหลดเอกสาร รองรับไฟล์ JPG, PNG, PDF",
              "จำเป็นต้องอัพโหลดเอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด อัพโหลดได้ไม่เกิน 6 ใบ",
              "เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ที่ท่านอัพโหลดต้องมี QR Code หรือ Barcode ชัดเจน",
            ]}
          />
        </div>
        <div className="input_wrap">
          <div className="upload_block">
            <Controller
              control={control}
              render={({ field }) => (
                <UploadMediaWidget
                  {...field}
                  theme_no_boder
                  isRequire
                  acceptType="cover"
                  label="เอกสารใบเรียกเก็บเงินหรือใบแจ้งหนี้ล่าสุด"
                  maxLength={6}
                  placeholder="อัปโหลดเอกสารที่นี่"
                  errors={errors?.latest_invoice?.message}
                />
              )}
              name={`latest_invoice`}
              defaultValue={[]}
            />
          </div>
        </div>
        <div className="input_wrap mb_20">
          <div className="upload_block">
            <Controller
              control={control}
              render={({ field }) => (
                <UploadMediaWidget
                  {...field}
                  theme_no_boder
                  acceptType="cover"
                  label="ใบเสร็จรับเงินที่ชำระหนี้"
                  maxLength={6}
                  placeholder="อัปโหลดเอกสารที่นี่"
                  errors={errors?.payment_receipt?.message}
                />
              )}
              name={`payment_receipt`}
              defaultValue=""
            />
          </div>
        </div>
        <div className="account_info_block">
          <div className="aib_row">
            <div className="aib_item">ผู้ให้บริการทางการเงิน</div>
            <div className="aib_item">
              {watchField?.service_provider?.label || ""}
            </div>
          </div>
          <div className="aib_row">
            <div className="aib_item">ยอดหนี้คงเหลือทั้งหมด</div>
            <div className="aib_item">
              {watchField?.total_debt
                ? RenderCommaMoney(watchField?.total_debt)
                : ""}{" "}
              บาท
            </div>
          </div>
        </div>
        <Buttons.BgStandard
          type="submit"
          label="บันทึก"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </AccountInfoCreateFormStyled>
  );
};

AccountInfoCreateForm.propTypes = {};

export default AccountInfoCreateForm;
