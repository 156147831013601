import React from "react";
import { connect } from "react-redux";
import { Step2CusInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { moneyDDService } from "apiServices";
import { setReduxStep2PesonalInfo } from "store/reducers/financeRequestReducer";
import { RENDER_MASTER_DATA_OPTION } from "utils/functions/getObjectKey";
import { Displays, Forms, Headers } from "components";

class Step2CusInfoContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [],
      target_fields: [
        "_customerInfo.educationLevel",
        "gender",
        "_customerInfo.maritalStatus",
      ],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      this.setState({
        educationOptions: RENDER_MASTER_DATA_OPTION(res.data?.target_fields[0]),
        genderOptions: RENDER_MASTER_DATA_OPTION(res.data?.target_fields[1]),
        maritalStatusOptions: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[2]
        ),
        isLoading: false,
      });
    }
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    this.props.setReduxStep2PesonalInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG3_WORK_INFO);
  };

  render() {
    const { isLoading, educationOptions, genderOptions, maritalStatusOptions } =
      this.state;
    return (
      <Step2CusInfoContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_container">
            <div className="step_wrap">
              <Displays.FinanceRegisStep
                title="ข้อมูลส่วนบุคคล"
                subTitle="กรุณาระบุข้อมูลส่วนบุคคล"
                stepCurrent={2}
                total={6}
              />
            </div>
            <div className="form_layout">
              <Forms.CusInfoForm
                educationOptions={educationOptions}
                genderOptions={genderOptions}
                maritalStatusOptions={maritalStatusOptions}
                onSubmit={this.handleSubmit}
              />
            </div>
          </div>
        )}
      </Step2CusInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxStep2PesonalInfo: (data) => dispatch(setReduxStep2PesonalInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step2CusInfoContainer));
