import React from "react";
import { NCBConsentSummaryFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const NCBConsentSummaryForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <NCBConsentSummaryFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="condition_block">
          เงื่อนไขในการให้ความยินยอมเปิดเผยข้อมูลผ่านระบบ อินเทอร์เน็ต
          ข้าพเจ้าทราบดีว่า การให้ความยินยอมผ่านระบบ อินเทอร์เน็ต จะมีลักษณะเป็น
          "ข้อมูลอิเล็กทรอนิกส์" และเป็นข้อความที่ได้สร้าง ส่ง รับ เก็บรักษา
          หรือ ประมวลผลด้วยวิธีทางอิเล็กทรอนิกส์ ซึ่งจะมีผล
          เป็นการให้ความยินยอมในการเปิดเผยหรือใช้ข้อมูลของ ข้าพเจ้า
          ตามกฎหมายว่าด้วยการประกอบธุรกิจข้อมูล
          เครดิตและข้าพเจ้าจะไม่ยกเลิกเพิกถอนหรือปฏิเสธ ความยินยอมนี้
          เพราะเหตุที่เป็นข้อมูลอิเล็กทรอนิกส์
          วิธีการให้ความยินยอมเปิดเผยข้อมูลผ่านระบบ อินเทอร์เน็ต 1.
          ผู้ให้ความยินยอมจะต้องกรอกข้อมูลที่ถูกต้อง 2.
          ผู้ให้ความยินยอมอาจส่งผ่านความยินยอมด้วย ตนเอง
          หรือมอบหมายให้บุคคลใดส่งผ่านก็ได้ 3.
          การให้ความยินยอมโดยวิธีการผ่านระบบอินเทอร์เน็ต
          ให้ถือว่ามีการลงลายมือชื่อแล้ว เมื่อได้ต่ำเนินการตาม
          ขั้นตอนการยืนยันตัวตนและการใช้รหัสอ้างอิง ข้าพเจ้า ชื่อ
          .................. นามสกุล ..................... วัน/เดือน/ปี พ.ศ.
          ................ บัตรประจำตัวประชาชน หรือหนังสือเดินทาง เลขที่
          .......................... หมายเลขโทรศัพท์เคลื่อนที่
          ............................. อีเมล
          .....................@moneydd.co.th ประเภทสินเชื่อ 1.
          สินเชื่อส่วนบุคคลกู๊ดมันนี่ (05) 2. สินเชื่อนาโนกู๊ดมันนี่ (37)
          หมายเลขอ้างอิง MDD24052400021
          วันเวลาที่ทำรายการความยินยอมในการเปิดเผยข้อมูล 24 w.ค. 2567 17:35:45
          ช่องทางที่ทำรายการ Good Money
          ความยินยอมนี้จัดทำขึ้นด้วยความสมัครใจของข้าพเจ้า
          และส่งผ่านระนบอินเทอร์เน็ตให้แก่ บริษัท ข้อมูลเครดิต แห่งชาติ จำกัด
          (บริษัท) เพื่อเป็นหลักฐานว่า ข้าพเจ้าตกลงยนยอมให้บริษัท
          ข้อมูลเครดิตแห่งชาติ จำกัด (บริษัท) เปิดเผยหรือให้ข้อมูลของข้าพเจ้าแก่
          บริษัท เงินดีดี จำกัด ซึ่งเป็นสมาชิกหรือผู้ใช้บริการ ของบริษัท
          เพื่อประโยชน์ในการวิเคราะห์สินเชื่อ การ ออกบัตรเครดิต
          ตามคำขอสินเชื่อ/ขอออกบัตรเครติต
          ของข้าพเจ้าที่ให้ใว้กับบริษัทดังกล่าวข้างต้น รวมทั้ง
          เพื่อประโยชน์ในการทบทวนสินเชื่อ ต่ออายุสัญญาสิน เชื่อ/บัตรเครดิต
          การบริหารและป้องกันความเสี่ยงตาม ข้อกำหนดของธนาคารแห่งประเทศไทย
          และให้ถือว่า ความยินยอมเปิดเผยข้อมูลที่ทำขึ้นผ่านระบบ อินเทอร์เน็ตนี้
          เมื่อประมวลผลและจัดพิมพ์ขึ้นจากข้อมูล อิเล็กทรอนิกส์แล้ว
          ไม่ว่าในรูปแบบใดๆ เป็นหลักฐานใน
          การให้ความยินยอมด้วยตนเองของข้าพเจ้าเช่น เดียวกัน อนึ่ง
          ก่อนให้ความยินยอม ข้าพเจ้าได้กราบถึงวิธีการ
          และเงื่อนไขของวิธีการให้ความยินยอบในการเปิดเผย
          หรือให้ข้อมูลผ่านระบบอินเทอร์เน็ต ซึ่งระบุไว้ด้านบน
          ของความยินยอมนี้อย่างชัดเจนแล้ว หมายเหตุ : ข้อมูลที่บริษัท
          ข้อมูลเครติตแห่งชาติ จำกัด
          เปิดเผยให้แก่สถาบันการเงินที่เป็นสมาชิกหรือผู้ใช้ บริการ
          เป็นเพียงองค์ประกอบหนึ่งในการพิจารณาสิน เชื่อของสถาบันการเงิน
          แต่การเปิดเผยข้อมูลดังกล่าว
          เป็นสิทธิของเจ้าของข้อมูลที่จะให้ความยินยอมหรือไม่ ก็ได้
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Radio2
                  {...field}
                  options={[
                    { label: "ยินยอม", value: "1" },
                    { label: "ไม่ยินยอม", value: "2" },
                  ]}
                  errors={errors.phone?.message}
                />
              )}
              name="test"
              defaultValue=""
            />
          </div>
        </div>
        <div className="btn_submit_wrap">
          <Buttons.BgStandard
            type="submit"
            label="เสร็จสิ้น"
            disabled={isSubmitting || !isValid}
          />
        </div>
      </form>
    </NCBConsentSummaryFormStyled>
  );
};

NCBConsentSummaryForm.propTypes = {};

export default NCBConsentSummaryForm;
