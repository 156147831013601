import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinanceSumApproveStyled } from "./styled";

const FinanceSumApprove = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <FinanceSumApproveStyled className={customClass}>
      <div className="chf_title">เลือกสินเชื่อที่ต้องการรีไฟแนนซ์</div>
      <div className="chr_container">
        <div className="chf_row gray">
          <div className="chf_label">วงเงินที่ได้รับการอนุมัติ</div>
          <div className="chf_value">300,000.00 บาท</div>
        </div>
        <div className="chf_row">
          <div className="chf_label">ยอดเงินที่ต้องการรีไฟแนนซ์</div>
          <div className="chf_value">180,000.00 บาท</div>
        </div>
        <div className="chf_row gray">
          <div className="chf_label">Duty Stamp 0.05% of loan amount</div>
          <div className="chf_value">100.00 บาท</div>
        </div>
        <div className="chf_row">
          <div className="chf_label">NDID fee</div>
          <div className="chf_value">100.00 บาท</div>
        </div>
        <div className="chf_row gray">
          <div className="chf_label">NCB fee</div>
          <div className="chf_value">100.00 บาท</div>
        </div>
        <div className="chf_row pd_12">
          <div className="chf_label pink">ยอดเงินที่ต้องการรีไฟแนนซ์สุทธิ</div>
          <div className="chf_value pink">177,000.00 บาท</div>
        </div>
      </div>
    </FinanceSumApproveStyled>
  );
};

FinanceSumApprove.propTypes = {};

export default FinanceSumApprove;
