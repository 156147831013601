export default function renderIcon({
  width = "17",
  height = "17",
  color = "#24BD3C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.36933 15.7387C12.4393 15.7387 15.7387 12.4393 15.7387 8.36933C15.7387 4.29936 12.4393 1 8.36933 1C4.29936 1 1 4.29936 1 8.36933C1 12.4393 4.29936 15.7387 8.36933 15.7387Z"
        stroke={color}
        strokeWidth="1.1054"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4021 6.15894L7.59379 10.9442L5.4082 8.76908"
        stroke={color}
        strokeWidth="1.19632"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
