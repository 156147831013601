export const MASK_PHONE_NUMBER = (input) => {
  let formatted = "";
  if (input) {
    // ลบอักขระที่ไม่ใช่ตัวเลข
    const numbers = input.replace(/\D/g, "");

    // ตรวจสอบว่ามีจำนวนตัวเลขอย่างน้อย 4 ตัว
    if (numbers.length < 4) {
      return "Invalid input";
    }

    // ปิดบังตัวเลขทุกตัว ยกเว้น 4 ตัวสุดท้าย
    const masked = numbers.slice(0, -4).replace(/\d/g, "x") + numbers.slice(-4);

    // แทรกเครื่องหมาย - เพื่อให้เป็นรูปแบบ xxx-xxx-xxxx
    formatted = masked;
    if (numbers.length > 3) {
      formatted = `${masked.slice(0, 3)}-${masked.slice(3, 6)}-${masked.slice(
        6
      )}`;
    }
  }
  return formatted;
};
