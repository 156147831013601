import axios from 'axios';
import { BASE_API } from './apiConfig';

const getConfig = (token) => {
  const config = {
    baseURL: BASE_API,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
  return config;
};

const getConfigFormData = (token, callback) => {
  const config = {
    baseURL: BASE_API,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    onUploadProgress: function (progressEvent) {
      let percentCompleted = Math.round(
        (progressEvent.loaded * 100) / progressEvent.total
      );
      console.log('upload percent', percentCompleted);
      callback && callback(percentCompleted);
    },
  };
  return config;
};

const axiosSuccess = (result) => {
  // console.log('axios success =>', result)
  return result.data;
};

const axiosError = (error) => {
  console.log('axios error =>', error);
  // return error.response.data;
  return error.response.data;
};

const axiosService = async (type, url, params, callback) => {
  const config = getConfig(localStorage.getItem('token'));
  const configFormData = getConfigFormData(
    localStorage.getItem('token'),
    callback
  );
  switch (type) {
    case 'get':
      if (params) config.params = params;
      return axios.get(url, config).then(axiosSuccess).catch(axiosError);
    case 'post':
      return axios
        .post(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'put':
      return axios
        .put(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'patch':
      return axios
        .patch(url, params, config)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'delete':
      return axios
        .delete(url, { ...config, data: params })
        .then(axiosSuccess)
        .catch(axiosError);
    case 'post_formdata':
      return axios
        .post(url, params, configFormData)
        .then(axiosSuccess)
        .catch(axiosError);
    case 'put_formdata':
      return axios
        .put(url, params, configFormData)
        .then(axiosSuccess)
        .catch(axiosError);

    default:
      return false;
  }
};

const Export = {
  get: (url, params) => axiosService('get', url, params),
  post: (url, params) => axiosService('post', url, params),
  put: (url, params) => axiosService('put', url, params),
  delete: (url, params) => axiosService('delete', url, params),
  post_formdata: (url, params, callback) =>
    axiosService('post_formdata', url, params, callback),
  put_formdata: (url, params, callback) =>
    axiosService('put_formdata', url, params, callback),
  patch: (url, params) => axiosService('patch', url, params),
};

export default Export;
