import React from "react";
import { RefinanceCreditDocFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Icons, Inputs } from "components";

const RefinanceCreditDocForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    // test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RefinanceCreditDocFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_body_container">
          <div className="f_title">อีเมลสำหรับรับเอกสาร</div>
          <div className="f_sub_title">
            สำหรับบริษัท เงินดีดี จำกัด ส่งเอกสารสัญญาสินเชื่อในรูปแบบ
            อิเล็กทรอนิกส์ และข้อมูลเอกสารใบเสร็จรับเงิน
          </div>
          <div className="input_row">
            <div className="input_col">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.TextFieldPrefixIconField
                    {...field}
                    isRequire
                    label="อีเมล"
                    icon={<Icons.Email />}
                    placeholder={"กรุณากรอก"}
                    errors={errors.email?.message}
                  />
                )}
                name="email"
                defaultValue=""
              />
            </div>
            <div className="btn_sent_otp">ส่ง OTP</div>
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.OtpField
                  {...field}
                  theme_email
                  isRequire
                  label="ยืนยัน OTP"
                  placeholder={""}
                  errors={errors.otp?.message}
                />
              )}
              name="otp"
              defaultValue=""
            />
          </div>
        </div>
        <div className="f_footer">
          <Buttons.BgStandard
            type="submit"
            label="ยอมรับ"
            disabled={isSubmitting || !isValid}
          />
        </div>
      </form>
    </RefinanceCreditDocFormStyled>
  );
};

RefinanceCreditDocForm.propTypes = {};

export default RefinanceCreditDocForm;
