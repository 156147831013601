export default function renderIcon({
  width = "41",
  height = "41",
  color = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5423 20.6517H14.8756M14.8756 20.6517L20.709 26.485M14.8756 20.6517L20.709 14.8184"
        stroke={color}
        strokeWidth="1.67"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
