import Logo from "./Logo";
import Normal from "./Normal";
import TitleCarousel from "./TitleCarousel";

const EXPORT = {
  Logo,
  Normal,
  TitleCarousel,
};

export default EXPORT;
