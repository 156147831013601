import { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextNumberFieldStyled } from "./styled";
import { Icons } from "components";

const TextNumberField = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_label_horizontal,
    label,
    regexFormat = /^\d+$/,
    disabled,
    isRequire,
    isInfo,
    min,
    errors,
    onChange,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
    theme_label_horizontal: theme_label_horizontal,
  });

  const _onChange = (e) => {
    const regex = regexFormat;
    if (!e.target.value) {
      onChange(e.target.value);
    } else {
      if (regex.test(e.target.value)) {
        if (e.target.value.length > 1 && e.target.value[0] === "0") {
          onChange(e.target.value.slice(1));
        } else {
          onChange(e.target.value);
        }
      }
    }
  };

  return (
    <TextNumberFieldStyled disabled={disabled} err={errors ? 1 : 0}>
      <div className={`input_text_container ${customClass}`}>
        {label && (
          <div className="label">
            {label} {isInfo && <Icons.Infomation color="#2187FF" />}{" "}
            {isRequire && <span>*</span>}{" "}
          </div>
        )}
        <input {...props} min={min} disabled={disabled} onChange={_onChange} />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextNumberFieldStyled>
  );
});

TextNumberField.propTypes = {};

export default TextNumberField;
