export default function renderIcon({
  width = "22",
  height = "22",
  color = "#ED018C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.1673 5.49935C20.1673 4.49102 19.3423 3.66602 18.334 3.66602H3.66732C2.65898 3.66602 1.83398 4.49102 1.83398 5.49935M20.1673 5.49935V16.4993C20.1673 17.5077 19.3423 18.3327 18.334 18.3327H3.66732C2.65898 18.3327 1.83398 17.5077 1.83398 16.4993V5.49935M20.1673 5.49935L11.0007 11.916L1.83398 5.49935"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
