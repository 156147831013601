export default function renderIcon({ width = "15", height = "15" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2692_75)">
        <path
          d="M2.71472 3.53009H3.88309M3.88309 3.53009H13.23M3.88309 3.53009V11.7086C3.88309 12.0185 4.00618 12.3157 4.22529 12.5348C4.4444 12.7539 4.74158 12.877 5.05145 12.877H10.8933C11.2031 12.877 11.5003 12.7539 11.7194 12.5348C11.9385 12.3157 12.0616 12.0185 12.0616 11.7086V3.53009H3.88309ZM5.63564 3.53009V2.36172C5.63564 2.05186 5.75873 1.75468 5.97784 1.53557C6.19695 1.31645 6.49413 1.19336 6.804 1.19336H9.14073C9.4506 1.19336 9.74778 1.31645 9.96689 1.53557C10.186 1.75468 10.3091 2.05186 10.3091 2.36172V3.53009M6.804 6.451V9.9561M9.14073 6.451V9.9561"
          stroke="#ED018B"
          strokeWidth="1.50218"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2692_75">
          <rect
            width="14.0204"
            height="14.0204"
            fill="white"
            transform="translate(0.962219 0.0253906)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
