import { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextNumberBtnStyled } from "./styled";
import { RenderCommaMoney } from "utils/functions/FormatMoney";
import { Icons } from "components";
import { AlertControl } from "components/Modals/Alert";

const TextNumberBtn = forwardRef((props, ref) => {
  const {
    theme_standard,
    label,
    labelSuffix,
    value,
    errors,
    placeholder,
    maxLength,
    isRequire,
    onChange,
    onChangeCus,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _handleAdd = () => {
    const temp = Number(value) + 10000;
    onChange(temp);
    onChangeCus && onChangeCus(temp);
  };

  const _handleSubtract = () => {
    const temp = Number(value) - 10000;
    onChange(temp);
    onChangeCus && onChangeCus(temp);
  };

  const _handleChange = (e) => {
    const regex = /^\d+$/;
    if (e) {
      let valuetemp = e.replace(/,/g, "");
      if (regex.test(valuetemp)) {
        onChange(valuetemp);
        onChangeCus && onChangeCus(valuetemp);
        if (Number(valuetemp) > maxLength) {
          AlertControl.show({
            title: "แจ้งเตือน",
            description: "เลือกวงเงิน * (สูงสุด 500,000.00 บาท)",
            btnLabel2: "ตกลง",
          });
          onChange(maxLength);
          onChangeCus && onChangeCus(maxLength);
        }
      }
    } else {
      onChange(e);
      onChangeCus && onChangeCus(e);
    }
  };

  return (
    <TextNumberBtnStyled className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}{" "}
          {labelSuffix && <span className="suffix">{labelSuffix}</span>}
        </div>
      )}
      <div className="input_txt_nb_block">
        <div className="prefix_block">
          <button type="button" className="btn_block" onClick={_handleSubtract}>
            <Icons.Subtract />
          </button>
        </div>
        <input
          ref={ref}
          // disabled={true}
          // type="number"
          value={value ? RenderCommaMoney(value, true) : ""}
          placeholder={placeholder}
          onChange={(e) => {
            _handleChange(e.target.value);
          }}
        />
        <div className="suffix_block">
          <button type="button" className="btn_block" onClick={_handleAdd}>
            <Icons.Plus />
          </button>
        </div>
      </div>
      {errors && <div className="errors">{errors}</div>}
    </TextNumberBtnStyled>
  );
});

TextNumberBtn.propTypes = {};

export default TextNumberBtn;
