import styled from "styled-components";

export const PinOTPVerifyFormStyled = styled.div`
  .f_title {
    margin-bottom: 50px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .f_desc {
    margin-bottom: 12px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .phone_label {
    margin-bottom: 19px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .coutdown_label {
    margin-bottom: 66px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .ref_code {
    margin-bottom: 14px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    .red {
      color: ${({ theme }) => theme.COLORS.RED_1};
    }
    .code_ref_label {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .input_wrap {
  }
`;
