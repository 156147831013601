export const ROUTE_PATH = {
  ROOT: "/",
  LOGIN: "/login",
  SYSTEM: "system",
  REGIS_CUS_VERIFY_OTP: "/system/regis/member/verify-otp",
  REGIS_CUS_INFO: "/system/regis/member/cus-info",
  LANDING_PAGE: "/system/landing-page",
  TERM_AND_CONDITION: "/system/term-condition",
  RG1_GENERAL_INFO: "/system/regis-finance/general-info",
  RG2_CUS_INFO: "/system/regis-finance/cus-info",
  RG3_WORK_INFO: "/system/regis-finance/work-info",
  RG4_ADDRESS_INFO: "/system/regis-finance/address-info",
  RG5_ACCOUNT_INFO: "/system/regis-finance/account-info",
  RG6_UPLOAD_DOC: "/system/regis-finance/upload-doc",
  RG_COMFIRMATION: "/system/regis-finance/comfirmation",
  AGREEMENT_PUBLIC_INFO: "/system/agreement-public",
  AGREEMENT_PUBLIC_INFO_LOGIN_CONFIRM: "/system/agreement-public/login-confirn",
  PIN_VERIFY: "/system/pin-verify",
  PIN_OTP: "/system/pin-otp",
  PIN_RESET: "/system/pin-reset",
  PIN_RESET_SUCCESS: "/system/pin-reset-success",
  NCB_CONSENT: "/system/ncb-consent",
  NCB_CONSENT_SUMMARY: "/system/ncb-consent-summary",
  REGISTER_FINANCE_SUCCESS: "/system/register-finance-success",
  APPROVAL_STATUS: "/system/consider/approval-status",
  REQUEST_FINANCE: "/system/consider/request-finance",
  RESULT_APPROVAL: "/system/consider/result-approval",
  REFINANCE_EXCUTE: "/system/refinance/excute",
  REFINANCE_CONSENT: "/system/refinance/consent",
  REFINANCE_BOOK_CONSENT: "/system/refinance/consent/book",
  REFINANCE_VERIFY: "/system/refinance/verify",
  REFINANCE_DOC: "/system/refinance/doc",
  REFINANCE_INFO: "/system/refinance/info",
  REFINANCE_SUCCESS: "/system/refinance/success",
  REFINANCE_PROCESS_STATUS: "/system/refinance/status",
  REFINANCE_CREDIT_COMFIRM: "/system/refinance/credit/comfirm",
  REFINANCE_CREDIT_CHANGE: "/system/refinance/credit/change",
  REFINANCE_CREDIT_PIN_VERIFY: "/system/refinance/credit/pin-verify",
};
