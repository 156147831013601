import styled from "styled-components";

export const TrackingStepStyled = styled.div`
  .tks_title {
    margin-bottom: 19px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .tks_ref_label {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .tks_ref_value {
    margin-bottom: 43px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }

  &.theme_standard {
  }
`;

export const StepperContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .step_button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .step_circle {
      padding: 0px 8px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      z-index: 1;
    }
    .step_label {
      position: absolute;
      top: 120%;
      white-space: nowrap;
      text-align: center;
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }

    &.active {
    }
  }
  .connector {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background: ${({ theme }) => theme.COLORS.GRAY_6};
    z-index: 0;
    &::before {
      content: "";
      position: absolute;
      top: -1px;
      left: 0;
      width: ${({ percent }) => (percent ? `${percent}%` : "0")};
      height: 100%;
      background: ${({ theme }) => theme.COLORS.PINK_4};
      transition: width 0.3s ease;
    }
  }
`;
