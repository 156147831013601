import styled from "styled-components";

export const FinancialProviderStyled = styled.div`
  border-radius: 12px;
  box-shadow: 0px -0.99px 3.96px 0px #1018281a;
  overflow: hidden;
  .fpd_top_block {
    padding: 12px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .tb_row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 6px;
      &.mb_8 {
        margin-bottom: 8px;
      }
      .tb_col {
        .installment_label {
          color: ${({ theme }) => theme.COLORS.GRAY_11};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
          &.pink {
            color: ${({ theme }) => theme.COLORS.PINK_6};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
        }
        .installment_value {
          color: ${({ theme }) => theme.COLORS.GRAY_11};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        }
        .no_rec_block {
          border-radius: 8px;
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: ${({ theme }) => theme.COLORS.PINK_6};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
        }
      }
    }
  }
  .fpd_bottom_block {
    padding: 8px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    background: ${({ theme }) => theme.COLORS.PINK_6};
    .bb_label {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .bb_value {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
    }
  }

  &.theme_standard {
    .fpd_top_block {
      .tb_col {
        .no_rec_block {
          border: 1px solid ${({ theme }) => theme.COLORS.GRAY_16};
          background: ${({ theme }) => theme.COLORS.WHITE_1};
        }
      }
    }
    .fpd_bottom_block {
      border-top: 1px solid ${({ theme }) => theme.COLORS.GRAY_16};
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      .bb_label {
        color: ${({ theme }) => theme.COLORS.PINK_1};
      }
      .bb_value {
        color: ${({ theme }) => theme.COLORS.PINK_1};
      }
    }
  }
`;
