import styled from "styled-components";

export const ExcuteRefinanceContainerStyled = styled.div`
  .body_container {
    padding: 16px 20px;
    .line_horizontal {
      margin: 16px 0px;
      height: 1px;
      background: ${({ theme }) => theme.COLORS.GRAY_10};
    }
    .title {
      color: ${({ theme }) => theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S19};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
    .sub_title {
      margin-bottom: 16px;
      color: ${({ theme }) => theme.COLORS.GRAY_9};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .service_layout {
      .service_item_wrap {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
    .service_provideer_list {
      margin-bottom: 32px;
      .svp_title {
        margin-bottom: 8px;
        color: ${({ theme }) => theme.COLORS.GRAY_15};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
    }
  }
`;
