import styled from "styled-components";

export const InfomationStyled = styled.div`
  border-radius: 9px;
  padding: 9px 25px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  background: ${({ theme }) => theme.COLORS.PINK_5};
  .info_label {
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }

  &.theme_standard {
  }

  &.theme_center {
    justify-content: center;
  }
`;
