import styled from "styled-components";

export const DateTimeStyled = styled.div`
  .label {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .input_date_time {
    border-radius: 8px;
    border: 1px solid
      ${({ theme, disabled, err }) =>
        !disabled
          ? err
            ? theme.COLORS.RED_1
            : theme.COLORS.PINK_3
          : theme.COLORS.GRAY_1};
    padding: 6.5px 12px;
    width: calc(100% - 26px);
    background: ${({ theme, disabled }) =>
      !disabled ? "transparent" : theme.COLORS.GRAY_1};
    color: ${({ theme, disabled }) =>
      disabled ? theme.COLORS.GRAY_2 : theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-family: inherit;
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    &::placeholder {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
    }
  }
  input:focus {
  }

  .errors {
    margin-top: 2px;
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    color: ${({ theme }) => theme.COLORS.RED_1};
  }

  &.theme_standard {
  }
`;
