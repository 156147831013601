import ApproveStatus from "./ApproveStatus";
import BGLoading from "./BGLoading";
import FinanceRegisStep from "./FinanceRegisStep";
import Infomation from "./Infomation";
import InfomationList from "./InfomationList";
import Loading from "./Loading";
import ServiceStatus from "./ServiceStatus";
import TrackingStep from "./TrackingStep";

const EXPORT = {
  ApproveStatus,
  BGLoading,
  FinanceRegisStep,
  Infomation,
  InfomationList,
  Loading,
  ServiceStatus,
  TrackingStep,
};

export default EXPORT;
