export default function renderIcon({ width = "15", height = "15", isActive }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_i_2001_262)">
        <rect
          x="0.25"
          y="0.25"
          width="14.5"
          height="14.5"
          rx="1.75"
          stroke="#EEEEEE"
          strokeWidth="0.5"
          fill={isActive ? "#ED008C" : "none"}
        />
      </g>
      <defs>
        <filter
          id="filter0_i_2001_262"
          x="-2"
          y="-2"
          width="17"
          height="17"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="-2" dy="-2" />
          <feGaussianBlur stdDeviation="1.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect1_innerShadow_2001_262"
          />
        </filter>
      </defs>
    </svg>
  );
}
