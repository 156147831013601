import styled from "styled-components";

export const TitleCarouselStyled = styled.div`
  position: relative;
  padding: 0px 22px 30px 22px;
  background-image: url("/assets/images/backgrounds/header-normal.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .header_sec {
    padding: 36px 10px 2px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .btn_back_header {
      position: absolute;
      left: 0px;
      padding: 10px 14px;
      background: none;
      cursor: pointer;
    }
    .title_header {
      text-align: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
    }
  }
  .header_carousel_sec {
    .carousel {
      overflow: unset;
      .control-dots {
        bottom: -30px;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 4px;
        z-index: 22;
      }
    }
  }

  .theme_standard {
  }
`;

export const Dot = styled.div`
  border-radius: 4px;
  width: ${({ active }) => (active ? "9px" : "7px")};
  height: ${({ active }) => (active ? "9px" : "7px")};
  background: ${({ theme, active }) =>
    active ? theme.COLORS.WHITE_1 : theme.COLORS.PINK_2};
  cursor: pointer;
`;
