import styled from "styled-components";

export const IconSquarePrefixStyled = styled.button`
  padding: 0px;
  display: flex;
  align-items: center;
  column-gap: 14px;
  background: inherit;
  color: ${({ theme }) => theme.COLORS.PINK_4};
  font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
  font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  font-family: inherit;
  cursor: pointer;
  .icq_square {
    border-radius: 3px;
    width: 28px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.COLORS.PINK_4};
  }

  &.theme_standard {
  }
`;
