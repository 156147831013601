import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { NormalStyled } from "./styled";
import { useNavigate } from "react-router-dom";
import { Icons } from "components";

const Normal = ({
  theme_standard,
  theme_bg_transparent,
  title,
  onBackCustom,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_bg_transparent: theme_bg_transparent,
  });

  let navigate = useNavigate();

  const _handleClickBack = () => {
    if (!onBackCustom) {
      navigate(-1);
    } else {
      onBackCustom();
    }
  };

  return (
    <NormalStyled className={customClass}>
      <button className="btn_back_header" onClick={_handleClickBack}>
        <Icons.ArrowLeft />
      </button>
      <div className="title_header">{title}</div>
    </NormalStyled>
  );
};

Normal.propTypes = {};

export default Normal;
