import React, { useState } from "react";
import { LoginFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import { moneyDDService } from "apiServices";
import Countdown from "react-countdown";
import { PrivacyPolicyContainer } from "containers/LoginContainer/Modals";
import { InputOTPWidget } from "widgets";
import { Buttons, Inputs, Modals } from "components";

const LoginForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    otp_code: yup.string().required("OTP code is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setError,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [
    _countdown,
    //  _setCountdown
  ] = useState(Date.now() + 59000);
  const [_renderModal, _setRenderModal] = useState(false);
  const [_requestOtpData, _setRequestOtpData] = useState(false);
  const [
    ,
    // _resendBtnDisabled
    _setResendBtnDisabled,
  ] = useState(true);
  const [
    _requestOTPValue,
    //  _setRequestOTPValue
  ] = useState();

  // MODAL PDPA
  // ========================================
  // ========================================
  const _handleClickShowPDPA = () => {
    _setRenderModal(true);
  };
  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  // OTP FLOW
  // ========================================
  // ========================================
  const _handleRequestOTPSuccess = (e) => {
    _setRequestOtpData(e);
  };
  const _onCompleteCoutdownOTP = ({ onSubmit }) => {
    _setResendBtnDisabled(false);
  };

  const _handleSubmit = async (values) => {
    let params = {
      otpValue: values.otp_code,
    };
    let res = await moneyDDService.POST_LOGIN_VERIFY_OTP(params);
    if (res && res.respCode === 2000) {
      onSubmit({ ...params, ..._requestOtpData });
    } else {
      setError(`otp_code`, {
        type: "custom",
        message: "รหัสยินยันของคุณไม่ถูกต้อง",
      });
      toast.error(res?.respMsg || "รหัสยินยันของคุณไม่ถูกต้อง");
    }
  };

  return (
    <>
      <LoginFormStyled>
        <form onSubmit={handleSubmit(_handleSubmit)}>
          <div className="form_container">
            <div className="f_title">ลงทะเบียน/เข้าสู่ระบบ</div>
            <div className="figture">
              <img
                className="logo_img"
                alt="logo icon"
                src={"/assets/images/icons/sms.png"}
              />
            </div>
            <div className="input_wrap">
              <InputOTPWidget
                isRequire
                label="เบอร์โทรศัพท์"
                placeholder={"เบอร์โทรศัพท์"}
                onRequestOTPSuccess={_handleRequestOTPSuccess}
              />
            </div>
            <div className="input_wrap">
              <Controller
                control={control}
                render={({ field }) => (
                  <Inputs.OtpField
                    {...field}
                    isRequire
                    label="ยืนยัน OTP"
                    subLabel={
                      !_requestOtpData
                        ? ""
                        : `Ref. (${_requestOtpData?.refOtp})`
                    }
                    nodeLabel={
                      <Countdown
                        // date={Date.now() + 60000 * 5}
                        date={_countdown}
                        renderer={({ seconds }) => (
                          <div className="coutdown_label">
                            Ref. (GNRABP)
                            {_requestOTPValue?.reference_Key} (
                            {`${seconds < 10 ? `0${seconds}` : seconds}`}วิ)
                          </div>
                        )}
                        onComplete={_onCompleteCoutdownOTP}
                      />
                    }
                    disabled={!_requestOtpData}
                    errors={errors.otp_code?.message}
                  />
                )}
                name="otp_code"
                defaultValue=""
              />
            </div>
          </div>
          <div className="btn_action">
            <div className="desc">
              <div>เพื่อความปลอดภัยในการทำธุรกรรม</div>
              โปรดศึกษารายละเอียดเพิ่มเติมได้ที่
              <span onClick={_handleClickShowPDPA}>
                {" "}
                นโยบายความเป็นส่วนตัว 
              </span>
            </div>
            <Buttons.BgStandard
              type="submit"
              label="ยืนยัน"
              disabled={isSubmitting || !isValid}
            />
          </div>
        </form>
      </LoginFormStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal ? true : false}
        onCloseModal={_handleCloseModal}
      >
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            width: "calc(100vw - 40px)",
            maxWidth: 390,
            height: "80vh",
          }}
        >
          <PrivacyPolicyContainer onCloseModal={_handleCloseModal} />
        </div>
      </Modals.BodyEmpty>
    </>
  );
};

LoginForm.propTypes = {};

export default LoginForm;
