import React from "react";
import { UploadDocType2FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { UploadMediaWidget } from "widgets";
import { Buttons, Displays, Inputs } from "components";

const UploadDocType2Form = ({ onSubmit }) => {
  const schema = yup.object().shape({
    card_id: yup.array().required("Required"),
    // name_change_certificate: yup.array().required("Required"),
    current_salary_slip: yup.array().required("Required"),
    bank_statement: yup.array().required("Required"),
    // credit_bureau_report: yup.array().required("Required"),
    // credit_score: yup.string().required("Required"),
    // other_documents: yup.array().required("Required"),
    // referral_code: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <UploadDocType2FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="info_wrap">
          <Displays.Infomation
            theme_center
            label="รองรับไฟล์ JPG, PNG, PDF ขนาดไม่เกิน 10 MB. / ไฟล์ "
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                isRequire
                acceptType="cover"
                label="สำเนาบัตรประชาชน"
                placeholder="อัปโหลดเอกสารที่นี่"
                errors={errors.card_id?.message}
              />
            )}
            name="card_id"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                acceptType="cover"
                label="ใบรับรองการเปลี่ยนชื่อหรือนามสกุล"
                subLabel=" (ถ้ามี)"
                placeholder="อัปโหลดเอกสารที่นี่"
                errors={errors.name_change_certificate?.message}
              />
            )}
            name="name_change_certificate"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="f_label">
          สลิปเงินเดือนปัจจุบันหรือหนังสือรับรองเงินเดือน <span>*</span>
        </div>
        <div className="info_wrap">
          <Displays.InfomationList
            data={[
              "ท่านต้องอัปโหลดสลิปเงินเดือนล่าสุด",
              "สามารถอัปโหลดสลิปเงินเดือนที่มีค่าคอมมิชชั่น ค่าล่วงเวลา หรือโบนัส เพิ่มเติมได้",
            ]}
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                isRequire
                acceptType="cover"
                placeholder="อัปโหลดเอกสารที่นี่"
                maxLength={6}
                errors={errors.current_salary_slip?.message}
              />
            )}
            name="current_salary_slip"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="f_label">
          รายการเดินบัญชี 6 เดือนล่าสุด <span className="sub_label">(สูงสุดไม่เกิน 6 ไฟล์)</span><span>*</span>
        </div>
        <div className="info_wrap">
          <Displays.InfomationList
            data={[
              "รายการเดินบัญชีเดือนล่าสุดต้องเป็นเดือนปัจจุบัน หรือเดือนก่อนหน้าเท่านั้น",
              "ท่านต้องมีรายการเดินบัญชีต่อเนื่องติดกันอย่างน้อย 6 เดือน",
            ]}
          />
        </div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                isRequire
                acceptType="cover"
                placeholder="อัปโหลดเอกสารที่นี่"
                maxLength={6}
                errors={errors.bank_statement?.message}
              />
            )}
            name="bank_statement"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <UploadMediaWidget
                {...field}
                acceptType="cover"
                label="เอกสารอื่นๆ"
                subLabel=" (ถ้ามี)"
                placeholder="อัปโหลดเอกสารที่นี่"
                errors={errors.other_documents?.message}
              />
            )}
            name="other_documents"
            defaultValue=""
          />
        </div>
        <div className="line_horizontal" />
        <div className="title_rec">รหัสผู้แนะนำ</div>
        <div className="sub_title_rec">ระบุรหัสผู้แนะนำของท่าน</div>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="รหัสผู้แนะนำ"
                placeholder="กรุณาระบุรหัสผู้แนะนำ"
                errors={errors.referral_code?.message}
              />
            )}
            name="referral_code"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ดำเนินการต่อ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </UploadDocType2FormStyled>
  );
};

UploadDocType2Form.propTypes = {};

export default UploadDocType2Form;
