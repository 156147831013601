import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { BgStandardStyled } from "./styled";

const BgStandard = ({
  theme_stadard,
  disabled,
  label,
  type = "button",
  onClick,
}) => {
  const customClass = cx({
    theme_stadard: theme_stadard,
  });
  return (
    <BgStandardStyled
      className={customClass}
      disabled={disabled}
      type={type}
      onClick={onClick}
    >
      {label}
    </BgStandardStyled>
  );
};

BgStandard.propTypes = {};

export default BgStandard;
