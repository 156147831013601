export default function renderIcon({
  width = "12",
  height = "12",
  color = "#A0A0A0",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9308 2.43342C11.3464 2.01786 11.3436 1.34356 10.9245 0.924503C10.5054 0.505447 9.83115 0.502626 9.41559 0.91819L6.03472 4.29905L2.62545 0.889779C2.2064 0.470723 1.5321 0.467901 1.11653 0.883466C0.700971 1.29903 0.703792 1.97333 1.12285 2.39238L4.53212 5.80166L1.15126 9.18252C0.735695 9.59808 0.738516 10.2724 1.15757 10.6914C1.57663 11.1105 2.25093 11.1133 2.66649 10.6977L6.04735 7.31689L9.45662 10.7262C9.87568 11.1452 10.55 11.148 10.9655 10.7325C11.3811 10.3169 11.3783 9.64261 10.9592 9.22355L7.54996 5.81428L10.9308 2.43342Z"
        fill={color}
      />
    </svg>
  );
}
