import React, { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextFieldStyled } from "./styled";

const TextField = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_label_horizontal,
    theme_upload,
    label,
    disabled,
    errors,
    regexFormat,
    isRequire,
    onChange,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_label_horizontal: theme_label_horizontal,
    theme_upload: theme_upload,
  });

  const _onChange = (e) => {
    if (!regexFormat) {
      onChange(e.target.value);
    } else {
      const regex = regexFormat;
      if (!e.target.value) {
        onChange(e.target.value);
      } else {
        if (regex.test(e.target.value)) {
          onChange(e.target.value);
        }
      }
    }
  };

  return (
    <TextFieldStyled disabled={disabled} err={errors ? 1 : 0}>
      <div className={`input_text_container ${customClass}`}>
        {label && (
          <div className="label">
            {label} {isRequire && <span>*</span>}
          </div>
        )}
        <input {...props} disabled={disabled} onChange={_onChange} />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextFieldStyled>
  );
});

TextField.propTypes = {};

export default TextField;
