import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ServiceStatusStyled } from "./styled";
import { Icons } from "components";

const ServiceStatus = ({ theme_standard, status }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const _renderStatus = (e) => {
    switch (e) {
      case "pending":
        return {
          label: "รอดำเนินการ",
          icon: <Icons.Pending />,
          bgColor: "#FDB022",
        };

      case "complete":
        return {
          label: "ชำระเสร็จสิ้น",
          icon: <Icons.Passed />,
          bgColor: "#32D583",
        };

      case "reject":
        return {
          label: "ผิดพลาด",
          icon: <Icons.Infomation color="white" width="12" height="12" />,
          bgColor: "#FA5252",
        };

      default:
        return {
          label: "ไม่ได้ระบุ",
          icon: <Icons.Infomation color="white" width="12" height="12" />,
          bgColor: "#FA5252",
        };
    }
  };
  const _render = _renderStatus(status);
  return (
    <ServiceStatusStyled className={customClass} bgColor={_render.bgColor}>
      {_render.icon}
      {_render.label}
    </ServiceStatusStyled>
  );
};

ServiceStatus.propTypes = {};

export default ServiceStatus;
