import React from "react";
import { PinResetSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import queryString from "query-string";
import { Buttons, Headers } from "components";

class PinResetSuccessContainer extends React.Component {
  handleClickNext = () => {
    const { router } = this.props;
    const { redirect } = queryString.parse(router.location.search);
    this.props.router.navigate(redirect ? redirect : ROUTE_PATH.PIN_VERIFY);
  };

  render() {
    return (
      <PinResetSuccessContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="bd_block">
            <div className="title">เปลี่ยนรหัส PIN สำเร็จ!</div>
            <img
              className="pin_success_img"
              alt="pin change success"
              src="/assets/images/icons/gmoney-pin-success.png"
            />
            <div className="desc">ท่านได้เปลี่ยนรหัส PIN สำเร็จแล้ว</div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard label="ยินยัน" onClick={this.handleClickNext} />
        </div>
      </PinResetSuccessContainerStyled>
    );
  }
}

export default withRouter(PinResetSuccessContainer);
