import styled from "styled-components";

export const CreditLimitChangeContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    padding: 20px 20px;
    .header_layout {
      margin-bottom: 10px;
      .title {
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
  }
`;
