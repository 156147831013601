export default function renderIcon({
  width = "15",
  height = "15",
  color = "#ED018B",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6603 4.19531L6.23433 10.6213L3.31342 7.70041"
        stroke={color}
        strokeWidth="2.00291"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
