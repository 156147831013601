export default function renderIcon({
  width = "30",
  height = "22",
  color = "#ED008C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.584419 12.7384L5.90878 20.4583C6.49164 21.3036 7.56644 21.8245 8.72804 21.8245H26.5613C28.4567 21.8245 29.9945 20.5306 29.9945 18.935V2.88954C29.9945 1.29388 28.4567 0 26.5613 0H8.72804C7.56644 0 6.49164 0.520861 5.90878 1.36623L0.584419 9.08613C-0.194806 10.2147 -0.194806 11.6098 0.584419 12.7384Z"
        fill="white"
      />
      <path
        d="M22.1651 7.82588C22.5686 7.42234 22.5659 6.76756 22.159 6.36062C21.752 5.95369 21.0972 5.95095 20.6937 6.35449L17.4107 9.63753L14.1 6.32691C13.6931 5.91997 13.0383 5.91723 12.6348 6.32077C12.2313 6.72431 12.234 7.3791 12.6409 7.78603L15.9515 11.0967L12.6685 14.3797C12.265 14.7832 12.2677 15.438 12.6746 15.8449C13.0816 16.2519 13.7364 16.2546 14.1399 15.8511L17.4229 12.568L20.7336 15.8787C21.1405 16.2856 21.7953 16.2883 22.1988 15.8848C22.6024 15.4813 22.5996 14.8265 22.1927 14.4195L18.8821 11.1089L22.1651 7.82588Z"
        fill={color}
      />
    </svg>
  );
}
