import React from 'react';
import cx from 'classnames';
// import PropTypes from 'prop-types'
import { InfomationStyled } from './styled';
import { Icons } from 'components';

const Infomation = ({ theme_standard, theme_center, label }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_center: theme_center,
  });
  return (
    <InfomationStyled className={customClass}>
      <Icons.Infomation color="#ED018C" />
      <div className="info_label">{label}</div>
    </InfomationStyled>
  );
};

Infomation.propTypes = {};

export default Infomation;
