import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TemplateRefinanceSuccessStyled } from "./styled";
import { Icons } from "components";

const TemplateRefinanceSuccess = ({ theme_standard, onConfirm }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TemplateRefinanceSuccessStyled className={customClass}>
      <div className="gmoney_figture">
        <img
          className="gmoney_img"
          alt="gmoney"
          src="/assets/images/icons/success.png"
        />
      </div>
      <div className="tpdd_title">ยินดีด้วย!</div>
      <div className="tpdd_desc">สินเชื่อของท่านที่ต้องการรีไฟแนนซ์</div>
      <div className="tpdd_desc">มีการชำระเงินเสร็จสิ้นเรียบร้อยแล้ว </div>
      <div className="tpdd_desc highlight">จำนวน 299,700.00 บาท </div>
      <div className="tpdd_desc mb_23">วันที่ 18 มิ.ย. 2567 เวลา 10.00 น. </div>
      <div className="tpdd_informaion">
        <Icons.Infomation width="16" height="16" color="#ED018B" />
        <div className="tpdd_label">
          ชำระเงิน 60,000.00 บาท ไม่สำเร็จ สถาบันการเงิน : ธนาคารไทยพาณิชย์
        </div>
      </div>
      <div className="tpdd_action_row">
        <div className="tpdd_col">
          <button
            type="button"
            className="btn_tpdd_confirm"
            onClick={onConfirm}
          >
            ตกลง
          </button>
        </div>
        {/* <div className="tpdd_col">
          <button
            type="button"
            className="btn_tpdd_cancel"
            onClick={onConfirm}
          >
            ยืนยัน
          </button>
        </div> */}
      </div>
    </TemplateRefinanceSuccessStyled>
  );
};

TemplateRefinanceSuccess.propTypes = {};

export default TemplateRefinanceSuccess;
