import React from "react";
import { ReFinanceVerifyPinConfirmContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import PinVerifyContainer from "containers/PinSystem/PinVerifyContainer";
import { ROUTE_PATH } from "utils/constants/routePath";

class ReFinanceVerifyPinConfirmContainer extends React.Component {
  handleVerifySuccess = (values) => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_SUCCESS);
  };

  render() {
    return (
      <ReFinanceVerifyPinConfirmContainerStyled>
        <PinVerifyContainer
          resetRedirect={ROUTE_PATH.REFINANCE_VERIFY}
          onVerifySuccess={this.handleVerifySuccess}
        />
      </ReFinanceVerifyPinConfirmContainerStyled>
    );
  }
}

export default withRouter(ReFinanceVerifyPinConfirmContainer);
