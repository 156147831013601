import styled from "styled-components";

export const RefinanceCreditDocFormStyled = styled.div`
  form {
    min-height: calc(100vh - 75px);
    display: flex;
    flex-direction: column;
    .f_body_container {
      flex: 1;
      padding: 32px 24px;
      .f_title {
        margin-bottom: 6px;
        color: ${({ theme }) => theme.COLORS.PINK_4};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
      }
      .f_sub_title {
        margin-bottom: 31px;
        color: ${({ theme }) => theme.COLORS.GRAY_9};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .input_row {
        margin-bottom: 59px;
        display: flex;
        align-items: flex-end;
        column-gap: 2px;
        .input_col {
          flex: 1;
        }
      }
      .input_wrap {
        margin-bottom: 12px;
      }
      .btn_sent_otp {
        padding: 18px;
        text-decoration: underline;
        color: ${({ theme }) => theme.COLORS.GRAY_10};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        cursor: pointer;
      }
    }
    .f_footer {
      padding: 20px 16px;
    }
  }
`;
