import React, { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextPhoneMaskStyled } from "./styled";

const TextPhoneMask = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_label_horizontal,
    label,
    disabled,
    errors,
    value,
    isRequire,
    onChange,
    // onChangeCus,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_label_horizontal: theme_label_horizontal,
  });

  const _onChange = (e) => {
    if (e.target.value.length < 13) {
      onChange(e);
    }
  };

  const _renderFormat = (e) => {
    if (!e) {
      return e;
    } else {
      const subTwo = e.substring(0, 2);
      if ((subTwo === "02" || subTwo === "09") && e.length <= 11) {
        // ลบอักขระที่ไม่ใช่เลข
        const numbers = e.replace(/\D/g, "");

        // จัดรูปแบบตัวเลขเป็น 99-999-9999
        let formatted = "";

        if (numbers.length <= 2) {
          formatted = numbers;
        } else if (numbers.length <= 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
        } else if (numbers.length <= 9) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5)}`;
        } else {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5, 9)}${numbers.slice(9)}`;
        }

        // เติมเครื่องหมาย - ถ้าจำเป็น
        if (numbers.length > 2 && numbers.length <= 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(2)}`;
        } else if (numbers.length > 5) {
          formatted = `${numbers.slice(0, 2)}-${numbers.slice(
            2,
            5
          )}-${numbers.slice(5)}`;
        }
        return formatted;
      } else {
        // ลบอักขระที่ไม่ใช่เลข
        const numbers = e.replace(/\D/g, "");

        // จัดรูปแบบตามความยาว
        let formatted = "";

        if (numbers.length <= 3) {
          formatted = numbers;
        } else if (numbers.length <= 7) {
          formatted = `${numbers.slice(0, 3)}-${numbers.slice(3)}`;
        } else {
          formatted = `${numbers.slice(0, 3)}-${numbers.slice(
            3,
            7
          )}-${numbers.slice(7)}`;
        }
        return formatted;
      }
    }
  };

  return (
    <TextPhoneMaskStyled disabled={disabled}>
      <div className={`input_text_container ${customClass}`}>
        {label && (
          <div className="label">
            {label} {isRequire && <span>*</span>}
          </div>
        )}
        <input
          {...props}
          value={_renderFormat(value)}
          disabled={disabled}
          maskChar=""
          onChange={(e) => {
            _onChange(e);
          }}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextPhoneMaskStyled>
  );
});

TextPhoneMask.propTypes = {};

export default TextPhoneMask;
