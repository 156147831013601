export default function renderIcon({
  width = "11",
  height = "13",
  color = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2016_850)">
        <path
          d="M4.41437 9.05611L2.68652 7.32826L3.63341 6.38091L4.28398 7.03149L6.73157 3.85901L7.79249 4.6769L4.41437 9.05611Z"
          fill={color}
        />
        <path
          d="M5.21066 12.1267L5.13214 12.1127C5.07839 12.1034 3.80529 11.8664 2.53546 10.7644C1.3792 9.76046 0 7.81575 0 4.25303V2.0265H0.446334C3.24165 2.0265 4.86995 0.348655 4.88631 0.331829L5.20178 0L5.5266 0.32295C5.54295 0.339307 7.27781 2.02696 9.97545 2.02696H10.4218V4.25349C10.4218 5.81543 10.1582 7.1993 9.63895 8.36678C9.21691 9.31506 8.6271 10.1217 7.88586 10.7653C6.61649 11.8669 5.34292 12.1038 5.28918 12.1136L5.21066 12.1277V12.1267ZM0.893135 2.90701V4.25303C0.893135 6.82167 1.63111 8.77525 3.08648 10.0596C4.00392 10.8695 4.93772 11.1476 5.21112 11.2158C5.48266 11.1485 6.41693 10.87 7.3353 10.0596C8.79068 8.77525 9.52865 6.82167 9.52865 4.25303V2.90655C7.37409 2.77802 5.85001 1.74935 5.21767 1.23805C4.60121 1.75589 3.11359 2.78269 0.893135 2.90748V2.90701Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2016_850">
          <rect width="10.4218" height="12.1267" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
}
