import React from "react";
import { ResultApprovalContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Displays, Headers, Icons } from "components";

class ResultApprovalContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REQUEST_FINANCE);
  };

  render() {
    return (
      <ResultApprovalContainerStyled>
        <Headers.Normal theme_bg_transparent title="ยื่นเรื่องไฟแนนซ์" />
        <div className="body_container">
          <Cards.FinanceInfo />
          <div className="block">
            <Displays.TrackingStep
              currentStep={1}
              steps={[
                "ขอสินเชื่อ",
                ["พิจารณา", "สินเชื่อ"],
                ["ผลการอนุมัติ", "สินเชื่อ"],
              ]}
            />

            <div className="approve_f">
              <Displays.ApproveStatus
                theme_standard
                icon={<Icons.ApproveSuccess />}
                label="วงเงินของท่านได้รับการอนุมัติ"
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard
            label="ทำรายการต่อ"
            onClick={this.handleClickNext}
          />
        </div>
      </ResultApprovalContainerStyled>
    );
  }
}

export default withRouter(ResultApprovalContainer);
