import styled from "styled-components";

export const AddressInfoFormStyled = styled.div`
  .f_title {
    margin-bottom: 9px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    &.mb_4 {
      margin-bottom: 4px;
    }
    &.mb_16 {
      margin-bottom: 16px;
    }
  }
  .input_row {
    margin-bottom: 14px;
    display: flex;
    column-gap: 16px;
    &.mb_18 {
      margin-bottom: 18px;
    }
    &.mb_27 {
      margin-bottom: 27px;
    }
    .input_col {
      flex: 1;
    }
  }
  .input_wrap {
    margin-bottom: 20px;
    &.mb_22 {
      margin-bottom: 22px;
    }
    &.mb_27 {
      margin-bottom: 27px;
    }
  }
  .line_horizontal {
    margin-bottom: 21px;
    height: 1px;
    background: ${({ theme }) => theme.COLORS.GRAY_3}80;
  }
  .info_wrap {
    margin-bottom: 12px;
  }
  .btn_add_personal {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    column-gap: 3px;
    background: inherit;
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    font-family: inherit;
    cursor: pointer;
    span {
      color: ${({ theme }) => theme.COLORS.GRAY_10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;
