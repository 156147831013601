import styled from "styled-components";

export const ReFinanceItemStyled = styled.div`
  border-radius: ${({ active }) => (active ? "10px 10px 0 0" : "10px")};
  box-shadow: 0px 2.54px 2.54px 0px #0000001a;
  padding: 14px 16px 18px 16px;
  background: linear-gradient(268.06deg, #ed018c -60.21%, #ee008e 119.88%);
  .fni_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    &.mb_4 {
      margin-bottom: 4px;
    }
    &.mb_8 {
      margin-bottom: 8px;
    }
    .fni_no {
      border-radius: 10px;
      padding: 2px 8px;
      background: ${({ theme }) => theme.COLORS.WHITE_2};
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S8};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.BOLD};
    }
    .btn_fni_delete {
      cursor: pointer;
    }
    .fni_label {
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }

  &.theme_standard {
  }
`;
