import styled from "styled-components";

export const TemplateRefinanceSuccessStyled = styled.div`
  border-radius: 12px;
  padding: 33px 24px 27px 24px;
  min-width: 236px;
  background: ${({ theme }) => theme.COLORS.WHITE_1};
  .gmoney_figture {
    margin-bottom: 12px;
    text-align: center;
    .gmoney_img {
      width: 120px;
      height: auto;
    }
  }
  .tpdd_title {
    margin-bottom: 14px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .tpdd_desc {
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_2};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    &.highlight {
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    &.mb_23 {
      margin-bottom: 23px;
    }
  }
  .tpdd_informaion {
    margin-bottom: 26px;
    border-radius: 4px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 12px;
    background: ${({ theme }) => theme.COLORS.PINK_5};
    .tpdd_label {
      max-width: 154px;
      color: ${({ theme }) => theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .tpdd_action_row {
    display: flex;
    justify-content: center;
    column-gap: 5px;
    .tpdd_col {
      flex: 1;
      text-align: center;
    }
    .btn_tpdd_confirm {
      border-radius: 7px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_3};
      padding: 6px 0px;
      width: 100%;
      max-width: 126px;
      text-align: center;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      color: ${({ theme }) => theme.COLORS.GRAY_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      font-family: inherit;
      cursor: pointer;
    }
    .btn_tpdd_cancel {
      border-radius: 5px;
      padding: 7px 1px;
      width: 100%;
      background: linear-gradient(102.16deg, #ed008c 57.64%, #ff9300 97.99%);
      text-align: center;
      color: ${({ theme }) => theme.COLORS.WHITE_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S13};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      font-family: inherit;
      cursor: pointer;
    }
  }

  &.theme_standard {
  }
`;
