import React from "react";
import { PrivacyPolicyFormStyled } from "./styled";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons } from "components";

const PrivacyPolicyForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    // is_term_condition: yup
    //   .boolean()
    //   .required("Accept is required")
    //   .oneOf([true], "Accept is required"),
  });

  const {
    // control,
    handleSubmit,
    formState: {
      // errors,
      isSubmitting,
      isValid,
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <PrivacyPolicyFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pvpl_title">นโยบายความเป็นส่วนตัว (Privacy Policy)</div>
        <div className="pvpl_content">
          บริษัท เงินดีดี จำกัด (“บริษัท”)
          มีความใส่ใจในความเป็นส่วนตัวของผู้ใช้บริการ
          จึงได้จัดให้มีนโยบายความเป็นส่วนตัวฉบับนี้เพื่อให้ผู้ใช้บริการ
          (“ท่าน”) ได้รับทราบถึงนโยบายที่เกี่ยวข้องในการเก็บรวบรวม ใช้
          หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
          ประเภทข้อมูลและวัตถุประสงค์ในการดำเนินการดังกล่าว
          รวมถึงรายละเอียดเกี่ยวกับระยะเวลาในการเก็บรักษาข้อมูล
          การเปิดเผยข้อมูลส่วนบุคคลให้แก่บุคคลภายนอก สิทธิของท่าน
          การรักษาความลับและความปลอดภัยของข้อมูลส่วนบุคคลของท่าน
          และวิธีการที่ท่านสามารถติดต่อบริษัทเพื่อให้เป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล
          พ.ศ. 2562  และกฎหมายที่เกี่ยวข้อง
        </div>
        <div className="pvpl_title_topic">ขอบเขต</div>
        <div className="pvpl_content">
          นโยบายนี้มีผลบังคับใช้กับท่าน โดยในที่นี้ได้แก่ (1)
          ลูกค้าซึ่งใช้หรือเคยใช้ผลิตภัณฑ์ และ/หรือ บริการ (2)
          ผู้ติดต่อสอบถามข้อมูลผลิตภัณฑ์ และ/หรือ บริการ (3)
          ผู้ที่รับทราบข้อมูลผลิตภัณฑ์ และ/หรือ บริการผ่านช่องทางต่าง ๆ (4)
          ผู้ที่ได้รับการเสนอ หรือชักชวนจากบริษัทให้ใช้หรือรับผลิตภัณฑ์ และ/หรือ
          บริการ (5)
          ข้อมูลบุคคลธรรมดาที่บริษัทได้รับเนื่องจากเป็นผู้เกี่ยวข้องกับลูกค้าของบริษัท
          เช่น กรรมการ ผู้ถือหุ้น ผู้รับประโยชน์ที่แท้จริง พนักงาน ผู้ค้ำประกัน
          ผู้ให้หลักประกัน
          และผู้แทนโดยชอบด้วยกฎหมายของลูกค้าองค์กรธุรกิจเดิมและปัจจุบัน เป็นต้น
          และ บุคคลที่ไม่ใช่ลูกค้าของบริษัท
          ซึ่งบุคคลดังกล่าวรวมถึงบุคคลธรรมดาที่ยังมิได้มีผลิตภัณฑ์หรือบริการกับบริษัท
          แต่บริษัทอาจมีความจำเป็นต้องเก็บ รวบรวม ใช้
          หรือประมวลผลข้อมูลส่วนบุคคลของท่าน เช่น ผู้ติดต่อ ลูกจ้าง พนักงาน
          เจ้าหน้าที่ บุคลากร บุคคลที่บริษัทแนะนำหรืออ้างอิง ผู้ลงทุน
          ผู้ค้ำประกัน ผู้จำนอง ผู้ให้หลักประกัน คู่ค้า
          บุคคลที่ได้ชำระเงินให้แก่หรือรับเงินจากลูกค้าของบริษัท
          บุคคลที่เข้าชมเว็บไซต์หรือแอปพลิเคชันของบริษัท ผู้สมัครงาน
          ผู้ค้ำประกันหรือผู้ให้หลักประกัน ผู้รับผลประโยชน์ที่แท้จริง
          ที่ปรึกษาด้านวิชาชีพ รวมถึงกรรมการ ผู้ลงทุนและผู้ถือหุ้นของบริษัท
          และตัวแทนโดยชอบด้วยกฎหมายของบุคคลดังกล่าว และบุคคลใด ๆ
          ที่เกี่ยวข้องกับการทำธุรกรรมกับบริษัทหรือลูกค้าของบริษัท (6)
          บุคคลทั่วไปที่บริษัทมีความสัมพันธ์ ปฏิสัมพันธ์ ติดต่อกันโดยประการอื่น
          หรือให้ข้อมูลส่วนบุคคลกับบริษัท
          หรือที่บริษัทได้รับข้อมูลส่วนบุคคลมาทั้งทางตรงและทางอ้อมไม่ว่าผ่านช่องทางใด
          ทั้งนี้ในบางกรณีบริษัทอาจมีการวางลิงก์หรือนำท่านเข้าสู่แพลตฟอร์มของบุคคลภายนอกได้
          ซึ่งหากท่านได้เข้าสู่แพลตฟอร์มของบุคคลภายนอกแล้ว
          การประมวลผลข้อมูลส่วนบุคคลของท่านจะเป็นไปตามนโยบายความเป็นส่วนตัวของบุคคลภายนอกนั้นทั้งสิ้น
          ซึ่งในกรณีดังกล่าวนั้นบริษัทจะแจ้งให้ท่านทราบเมื่อมีการออกจากแพลตฟอร์มของบริษัท
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ตกลง"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </PrivacyPolicyFormStyled>
  );
};

PrivacyPolicyForm.propTypes = {};

export default PrivacyPolicyForm;
