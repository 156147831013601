import React from "react";
import { RegisCusInfoFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const RegisCusInfoForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    id_number: yup.string().required("New password is required"),
    birthday: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <RegisCusInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_input_container">
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextField
                  {...field}
                  isRequire
                  label="ID Number"
                  placeholder={"กรุณากรอก ID Number"}
                  errors={errors.id_number?.message}
                />
              )}
              name="id_number"
              defaultValue=""
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.DateTime
                  {...field}
                  isRequire
                  label="วันเดือนปีเกิด"
                  placeholder={"กรุณาเลือกวันเดือนปีเกิด"}
                  errors={errors.birthday?.message}
                />
              )}
              name="birthday"
              defaultValue=""
            />
          </div>
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </RegisCusInfoFormStyled>
  );
};

RegisCusInfoForm.propTypes = {};

export default RegisCusInfoForm;
