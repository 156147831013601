import React, { useMemo, useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinanceSumStyled } from "./styled";
import { moneyDDService } from "apiServices";
import { Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const FinanceSum = ({
  theme_standard,
  credit_limit,
  installment_period,
  occupation_type,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_data, _setData] = useState(false);

  const _renderLoanType = (e) => {
    switch (e) {
      case "EMPLOYEE":
        return "P_LOAN";
      case "FREELANCE":
        return "NANO";
      case "SELF_EMPLOYED":
        return "P_LOAN";
      default:
        return "P_LOAN";
    }
  };

  const _calculateLoan = async () => {
    _setData(false);
    let params = {
      loanamount: Number(credit_limit),
      numberofperiods: Number(installment_period),
      loan_type: _renderLoanType(occupation_type),
    };
    let res = await moneyDDService.POST_CORE_LOAN(params);
    if (res && res.respCode === 2000) {
      _setData(res.data);
    }
  };

  useMemo(() => {
    if (credit_limit && installment_period && occupation_type) {
      _calculateLoan();
    } else {
      _setData(false);
    }
  }, [credit_limit, installment_period, occupation_type]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <FinanceSumStyled className={customClass}>
      <div className="fns_row">
        <div className="fns_label">วงเงินที่คาดว่าจะได้รับ</div>
        <div className="fns_sub_row">
          <div className="fns_label">
            {!_data || !_data?.recommendcreditline
              ? "-"
              : RenderCommaMoney(_data.recommendcreditline, true)}
          </div>
          <div className="fns_label">บาท</div>
        </div>
      </div>
      <div className="fns_row">
        <div className="fns_label">ระยะเวลาผ่อน</div>
        <div className="fns_sub_row">
          <div className="fns_label">
            {!_data || !_data?.installment
              ? "-"
              : RenderCommaMoney(_data.installment, true)}
          </div>
          <div className="fns_label">เดือน</div>
        </div>
      </div>
      <div className="fns_row">
        <div className="fns_label">อัตราดอกเบี้ย</div>
        <div className="fns_sub_row">
          <div className="fns_label">
            {!_data || !_data?.interestRate
              ? "-"
              : `${RenderCommaMoney(_data.interestRate, true)}%`}
          </div>
          <div className="fns_label">ต่อปี</div>
        </div>
      </div>
      <div className="fns_row">
        <div className="fns_label">ยอดที่ผ่อนต่อเดือน</div>
        <div>
          <div className="fns_sum_label">
            {!_data || !_data?.resultpayment
              ? "-"
              : RenderCommaMoney(_data.resultpayment, true)}
          </div>
          <div className="fns_label txt_right">บาทต่อเดือน</div>
        </div>
      </div>
      <div className="fns_rec">
        <div className="sr_left_col">
          <Icons.Infomation />
        </div>
        <div className="sr_right_col">
          <div className="result_label">
            ผลการคำนวณเป็นเพียงการประเมินความสามารถในการกู้ เบื้องต้นเท่านั้น
          </div>
          <div className="condition_label">
            * การพิจารณาสินเชื่ออยู่ภายใต้ดุลพินิจของบริษัทเงื่อนไขๆ
            เป็นไปตามที่บริษัทกำหนด
          </div>
        </div>
      </div>
    </FinanceSumStyled>
  );
};

FinanceSum.propTypes = {};

export default FinanceSum;
