import styled from "styled-components";

export const Step4AddressInfoContainerStyled = styled.div`
  .body_container {
    padding: 20px 16px;
    .step_wrap {
      margin-bottom: 25px;
    }
  }
`;
