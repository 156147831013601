import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinanceItemStyled } from "./styled";
import { Icons } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

const FinanceItem = ({
  theme_standard,
  children,
  no,
  serviceProvider,
  totalDebt,
  isActive,
  onClick,
  onDelete,
  onUpdate,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <FinanceItemStyled className={customClass} active={isActive ? 1 : 0}>
      <div className="fni_header_block">
        <div className="fni_row mb_8">
          <div className="fni_no">{no}/10</div>
          {isActive ? (
            <button type="button" className="btn_save" onClick={onUpdate}>
              บันทึก
              <Icons.CheckMark />
            </button>
          ) : (
            <div className="btn_fni_delete" onClick={onDelete}>
              <Icons.Bin color />
            </div>
          )}
        </div>
        <div className="fni_label" onClick={onClick}>
          ผู้ให้บริการทางการเงิน
        </div>
        <div className="fni_label_big" onClick={onClick}>
          {serviceProvider}
        </div>
      </div>
      {children}
      <div className="fni_footer_block">
        <div className="ff_row">
          <div className="ff_label">ยอดสินเชื่อ</div>
          <div className="ff_value">{RenderCommaMoney(totalDebt)} บาท</div>
        </div>
      </div>
    </FinanceItemStyled>
  );
};

FinanceItem.propTypes = {};

export default FinanceItem;
