import React from "react";
import { connect } from "react-redux";
import { Step5AccountInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxStep5AccountInfo } from "store/reducers/financeRequestReducer";
import { userService } from "apiServices";
import { Displays, Forms, Headers } from "components";

class Step5AccountInfoContainer extends React.Component {
  state = {
    step: 1,
    tempValues: [],
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let res = await userService.GET_BANK_LIST();
    if (res && res.success) {
      this.setState({
        bankOptions: res.data
          .filter((e) => e.status)
          .map((f) => ({ label: f.bankName, value: f.id })),
        isLoading: false,
      });
    }
  };

  handleBack = () => {
    const { step } = this.state;
    if (step === 2) {
      this.setState({
        step: 1,
      });
    } else {
      this.props.router.navigate(-1);
    }
  };

  handleAddFinance = (e) => {
    this.setState({
      initialValues: e,
      step: 2,
    });
  };

  handleCreateSubmit = (values) => {
    const { initialValues } = this.state;
    if (initialValues && initialValues?.loan_account) {
      let temp = initialValues.loan_account;
      temp.push(values);
      this.setState({
        initialValues: {
          loan_account: [...temp],
        },
        step: 1,
      });
    } else {
      this.setState({
        initialValues: {
          loan_account: [values],
        },
        step: 1,
      });
    }
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    this.props.setReduxStep5AccountInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG6_UPLOAD_DOC);
  };

  render() {
    const { initialValues, bankOptions, step } = this.state;
    return (
      <Step5AccountInfoContainerStyled>
        <Headers.Normal
          title="สมัครสินเชื่อรีไฟแนนซ์"
          onBackCustom={this.handleBack}
        />
        <div className="body_container">
          {step === 1 && (
            <>
              <div className="step_wrap">
                <Displays.FinanceRegisStep
                  title="เพิ่มข้อมูลบัญชีสินเชื่อที่ต้องการรีไฟแนนซ์"
                  subTitle="กรุณาระบุข้อมูลสินเชื่อของผู้สมัคร"
                  stepCurrent={5}
                  total={6}
                />
              </div>
              <div className="form_layout">
                <Forms.AccountInfoForm
                  bankOptions={bankOptions}
                  initialValues={initialValues}
                  onAddFinance={this.handleAddFinance}
                  onSubmit={this.handleSubmit}
                />
              </div>
            </>
          )}
          {step === 2 && (
            <Forms.AccountInfoCreateForm
              bankOptions={bankOptions}
              onSubmit={this.handleCreateSubmit}
            />
          )}
        </div>
      </Step5AccountInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxStep5AccountInfo: (data) => dispatch(setReduxStep5AccountInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step5AccountInfoContainer));
