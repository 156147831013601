import React, { forwardRef, useState, useEffect } from "react";
import cx from "classnames";
import { UploadImgFieldStyled } from "./styled";
import { useDropzone } from "react-dropzone";

const UploadImgField = forwardRef((props, ref) => {
  const {
    theme_standard,
    acceptType,
    maxMBFileSizeWarning,
    name,
    fieldName,
    placeholder,
    icon,
    value,
    onChange,
    onChangeCustom,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });
  const [files, setFiles] = useState([]);

  const renderAcceptType = (type) => {
    switch (type) {
      case "cover":
        return {
          "image/jpeg": [".jpeg", ".png"],
        };
      case "png":
        return {
          "image/png": [".png"],
        };
      case "audio":
        return ".mp3,audio/mpeg3";
      case "video":
        return { "video/": [] };
      case "csv":
        return { "text/csv": [".csv"] };
      case "json":
        return { "application/JSON": [".json"] };
      case "pdf":
        return { "'application/pdf": [".pdf"] };
      default:
        break;
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { ...renderAcceptType(acceptType) },
    onDrop: async (acceptedFiles) => {
      if (acceptType !== "csv") {
        if (maxMBFileSizeWarning) {
          let checkFileLimit = acceptedFiles.filter(
            (e) =>
              Number((e.size / 1024 / 1024).toFixed(2)) >
              Number(maxMBFileSizeWarning)
          );
          if (checkFileLimit && checkFileLimit.length > 0) {
            alert("ไฟล์มีขนาดใหญ่ ผู้ใช้อาจต้องใช้เวลานานในการแสดงผล");
          }
        }
        // const imageResize = await resizeFile(acceptedFiles);
        let fileTemp = [
          ...files.filter((e) => !e.isNewUpload),
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              isNewUpload: true,
              fieldName: fieldName || name,
            })
          ),
        ];
        setFiles(fileTemp);
        onChange && onChange(fileTemp);
        onChangeCustom && onChangeCustom(fileTemp);
      } else {
        let fileTemp = [
          ...files,
          ...acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              isNewUpload: true,
              fieldName: fieldName || name,
            })
          ),
        ];
        setFiles(fileTemp);
        onChange && onChange(fileTemp);
        onChangeCustom && onChangeCustom(fileTemp);
      }
    },
  });

  useEffect(() => {
    if (
      value &&
      value.length === 1 &&
      value[0].media_uri &&
      !value[0].isDelete
    ) {
      setFiles(value);
    }
    if (value === false) {
      setFiles([]);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <UploadImgFieldStyled className={customClass}>
        <button
          type="button"
          {...getRootProps({ className: "input_upload" })}
          className="btn_upm_item"
        >
          <input ref={ref} {...getInputProps()} />
          {icon}
          <div className="value_txt">{placeholder}</div>
        </button>
      </UploadImgFieldStyled>
    </>
  );
});

UploadImgField.propTypes = {};

export default UploadImgField;
