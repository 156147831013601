import React from "react";
import { AuthenTestStep2FormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const AuthenTestStep2Form = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: {
      //  errors,
      isSubmitting,
      isValid,
    },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <AuthenTestStep2FormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.TextField
                {...field}
                label="OTP code"
                // placeholder={t('test')}
                // errors={errors.phone?.message}
              />
            )}
            name="test"
            defaultValue=""
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </AuthenTestStep2FormStyled>
  );
};

AuthenTestStep2Form.propTypes = {};

export default AuthenTestStep2Form;
