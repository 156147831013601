import styled from "styled-components";

export const ServiceStatusStyled = styled.div`
  border-radius: 16px;
  padding: 2px 8px;
  width: fit-content;
  display: flex;
  align-items: center;
  column-gap: 4px;
  background: ${({ theme, bgColor }) =>
    bgColor ? bgColor : theme.COLORS.BLACK_1};
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
  font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};

  &.theme_standard {
  }
`;
