import styled from "styled-components";

export const AgreementPublicInfoContainerStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .title {
    padding: 18px 16px 0px 16px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .body_sec {
    flex: 1;
    padding: 10px 16px 26px 16px;
    overflow: auto;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
`;
