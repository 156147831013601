import styled from "styled-components";

export const BgStandardStyled = styled.button`
  border-radius: 16px;
  padding: 10px 16px;
  width: 100%;
  background: ${({ theme, disabled }) =>
    disabled
      ? theme.COLORS.GRAY_1
      : "linear-gradient(102.16deg, #ED008C 57.64%, #FF9300 97.99%)"};
  color: ${({ theme, disabled }) =>
    disabled ? theme.COLORS.WHITE_1 : theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
  font-family: inherit;
  font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  &:hover {
    transition: 0.7s;
  }

  .theme_stadard {
  }
`;
