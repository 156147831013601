import styled from "styled-components";

export const FinanceRegisStepStyled = styled.div`
  .financ_regis_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 6px;
    .frs_left_col {
      color: ${({ theme }) => theme.COLORS.PINK_1};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
    .frs_right_col {
      .frs_no_label {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
      }
      .frs_no {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_10};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        .highlight {
          color: ${({ theme }) => theme.COLORS.PINK_4};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        }
      }
    }
  }
  .frc_sub_title {
    color: ${({ theme }) => theme.COLORS.GRAY_9};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }

  &.theme_standard {
  }
`;
