import styled from "styled-components";

export const RegisCusInfoContainerStyled = styled.div`
  .body_container_layout {
    padding: 20px 16px;
    min-height: calc(100vh - 40px - ${({ theme }) => theme.ETC.HEADER_HEIGHT});
    display: flex;
    flex-direction: column;
    .logo_figture {
      text-align: center;
      .logo_img {
        width: 149px;
        height: auto;
      }
    }
    .app_name {
      margin-bottom: 45px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;
