import React from "react";
import { connect } from "react-redux";
import { SummaryConfirmContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxFinanceRequestClear } from "store/reducers/financeRequestReducer";
import { Buttons, Headers } from "components";
import { RenderCommaMoney } from "utils/functions/FormatMoney";

class SummaryConfirmContainer extends React.Component {
  handleClickConfirm = () => {
    this.props.router.navigate(ROUTE_PATH.AGREEMENT_PUBLIC_INFO);
  };

  render() {
    const { financeRequestRedux } = this.props;
    const sumDept =
      financeRequestRedux &&
      financeRequestRedux.loan_account &&
      financeRequestRedux.loan_account.length > 0
        ? financeRequestRedux.loan_account.reduce((result, data) => {
            result += data.total_debt;
            return result;
          }, 0)
        : 0;

    return (
      <SummaryConfirmContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="title">ตรวจสอบข้อมูล</div>
          <div className="title mb_15">การสมัครสินเชื่อรีไฟแนนซ์</div>
          <div className="cate_block mb_28">
            <div className="cate_label">ข้อมูลทั่วไปของผู้ขอสินเชื่อ</div>
            <div className="row bd_b">
              <div className="item">คำนำหน้า</div>
            </div>
            <div className="row bd_b">
              <div className="item">ชื่อ</div>
            </div>
            <div className="row bd_b">
              <div className="item">นามสกุล</div>
            </div>
            <div className="row bd_b">
              <div className="item">เบอร์โทรศัพท์</div>
            </div>
            <div className="row bd_b">
              <div className="item">ประเภทอาชีพ</div>
              <div className="item">
                {financeRequestRedux?.occupation_type?.label}
              </div>
            </div>
            <div className="row bd_b">
              <div className="item">อาชีพ</div>
              <div className="item">
                {financeRequestRedux?.occupation?.label}
              </div>
            </div>
            <div className="row bd_b">
              <div className="item">ลักษณะอาชีพ</div>
              <div className="item">
                {financeRequestRedux?.job_category?.label}
              </div>
            </div>
            <div className="row bd_b">
              <div className="item">รายได้ต่อเดือน</div>
              <div className="item">{financeRequestRedux?.monthly_income}</div>
            </div>
            <div className="row">
              <div className="item">รายจ่ายและภาระหนี้ต่อเดือน</div>
              <div className="item">
                {financeRequestRedux?.monthly_expenses_debt}
              </div>
            </div>
          </div>
          <div className="cate_block mb_28">
            <div className="cate_label">สินเชื่อที่ต้องการ</div>
            <div className="row bd_b">
              <div className="item">ยอดรวมสินเชื่อที่ต้องการรีไฟแนนซ์</div>
              <div className="item">{RenderCommaMoney(sumDept, true)} บาท</div>
            </div>
            <div className="row">
              <div className="item">วงเงินที่ต้องการ</div>
              <div className="item">
                {RenderCommaMoney(financeRequestRedux?.credit_limit, true)} บาท
              </div>
            </div>
          </div>
        </div>
        <div className="actino_layout">
          <Buttons.BgStandard
            label="ยืนยัน"
            onClick={this.handleClickConfirm}
          />
        </div>
      </SummaryConfirmContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxFinanceRequestClear: (data) =>
    dispatch(setReduxFinanceRequestClear(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SummaryConfirmContainer));
