import React from "react";
import { connect } from "react-redux";
import { Step4AddressInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxStep4AddressInfo } from "store/reducers/financeRequestReducer";
import { moneyDDService } from "apiServices";
import { RENDER_MASTER_DATA_OPTION } from "utils/functions/getObjectKey";
import { Displays, Forms, Headers } from "components";

class Step4AddressInfoContainer extends React.Component {
  state = {
    isLoading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async () => {
    let params = {
      product_name: "moneydd.pLoan",
      source_fields: [],
      target_fields: [
        "_customerInfo.residenceType",
        "_customerInfo.residenceStatus",
        "_refPersons.1.relationship",
        "_refPersons.2.relationship"
      ],
    };
    let res = await moneyDDService.POST_GET_MASTER_DATA_LIST(params);
    if (res && res.respCode === 2000) {
      this.setState({
        residenceTypeOptions: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[0]
        ),
        residenceStatusOptions: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[1]
        ),
        relationShipOptions: RENDER_MASTER_DATA_OPTION(
          res.data?.target_fields[2]
        ),
        isLoading: false,
      });
    }
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    this.props.setReduxStep4AddressInfo(params);
    this.props.router.navigate(ROUTE_PATH.RG5_ACCOUNT_INFO);
  };

  render() {
    const {
      isLoading,
      residenceTypeOptions,
      residenceStatusOptions,
      relationShipOptions,
    } = this.state;
    return (
      <Step4AddressInfoContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        {isLoading ? (
          <Displays.Loading />
        ) : (
          <div className="body_container">
            <div className="step_wrap">
              <Displays.FinanceRegisStep
                title="ข้อมูลที่อยู่ปัจจุบัน"
                subTitle="กรุณาระบุข้อมูลที่อยู่ปัจจุบัน"
                stepCurrent={4}
                total={6}
              />
            </div>
            <Forms.AddressInfoForm
              residenceTypeOptions={residenceTypeOptions}
              residenceStatusOptions={residenceStatusOptions}
              relationShipOptions={relationShipOptions}
              onSubmit={this.handleSubmit}
            />
          </div>
        )}
      </Step4AddressInfoContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({
  financeRequestRedux: state.financeRequestRedux,
});

const mapDispatchToProps = (dispatch) => ({
  setReduxStep4AddressInfo: (data) => dispatch(setReduxStep4AddressInfo(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Step4AddressInfoContainer));
