import styled from "styled-components";

export const CreditLimitConfirmContainerStyled = styled.div`
  .body_container {
    padding: 16px 20px;
    .header_layout {
      margin-bottom: 16px;
      .title {
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
    .info_layout {
      margin-bottom: 24px;
      .info_row {
        padding: 8px 12px;
        display: flex;
        justify-content: space-between;
        column-gap: 6px;
        &.mb_16 {
          margin-bottom: 16px;
        }
        &.bd_bt {
          border-bottom: 0.5px solid ${({ theme }) => theme.COLORS.GRAY_10};
        }
        &.pink {
          padding: 6px 12px;
          background: ${({ theme }) => theme.COLORS.PINK_7};
        }
        .label {
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
          &.pink {
            color: ${({ theme }) => theme.COLORS.PINK_4};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
        }
        .value {
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          &.pink {
            color: ${({ theme }) => theme.COLORS.PINK_4};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
          &.md_16 {
            font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
          }
        }
      }
    }
    .action_layout {
      text-align: center;
      .btn_confirm {
        margin-bottom: 9px;
        border-radius: 12px;
        padding: 5px 12px;
        width: 100%;
        max-width: 279px;
        background: linear-gradient(102.16deg, #ed008c 57.64%, #ff9300 97.99%);
        color: ${({ theme }) => theme.COLORS.WHITE_1};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        font-family: inherit;
        cursor: pointer;
      }
      .btn_change {
        border-radius: 12px;
        border: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
        padding: 5px 12px;
        width: 100%;
        max-width: 279px;
        background: transparent;
        color: ${({ theme }) => theme.COLORS.GRAY_10};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        font-family: inherit;
        cursor: pointer;
      }
    }
  }
`;
