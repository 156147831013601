import React, { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextMaskStyled } from "./styled";
import InputMask from "react-input-mask";

const TextMask = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_label_horizontal,
    label,
    disabled,
    errors,
    isRequire,
    onChange,
    onChangeCus,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_label_horizontal: theme_label_horizontal,
  });

  return (
    <TextMaskStyled disabled={disabled}>
      <div className={`input_text_container ${customClass}`}>
        {label && (
          <div className="label">
            {label} {isRequire && <span>*</span>}
          </div>
        )}
        <InputMask
          {...props}
          disabled={disabled}
          mask={"999-9999-999"}
          maskChar=""
          onChange={(e) => {
            onChange(e);
            onChangeCus && onChangeCus(e);
          }}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextMaskStyled>
  );
});

TextMask.propTypes = {};

export default TextMask;
