import styled from "styled-components";

export const EmpGeneralInfoFormStyled = styled.div`
  .form_title {
    margin-bottom: 26px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    &.mb_20 {
      margin-bottom: 20px;
    }
  }
  .input_wrap {
    margin-bottom: 20px;
    &.mb_12 {
      margin-bottom: 12px;
    }
    &.mb_23 {
      margin-bottom: 23px;
    }
    .input_reccomment {
      margin-top: 6px;
      color: ${({ theme }) => theme.COLORS.RED_2};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .slip_title {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .slip_list {
    margin: 0px;
    margin-bottom: 16px;
    padding-left: 32px;
    li {
      color: ${({ theme }) => theme.COLORS.PINK_4};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;
