import React from "react";
import { RegisVerifyPinContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import PinVerifyContainer from "containers/PinSystem/PinVerifyContainer";
import { ROUTE_PATH } from "utils/constants/routePath";
import {} from "components";

class RegisVerifyPinContainer extends React.Component {
  handleVerifySuccess = (values) => {
    this.props.router.navigate(ROUTE_PATH.REGIS_CUS_INFO);
  };

  render() {
    return (
      <RegisVerifyPinContainerStyled>
        <PinVerifyContainer
          resetRedirect={ROUTE_PATH.REGIS_CUS_VERIFY_OTP}
          onVerifySuccess={this.handleVerifySuccess}
        />
      </RegisVerifyPinContainerStyled>
    );
  }
}

export default withRouter(RegisVerifyPinContainer);
