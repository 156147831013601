import styled from "styled-components";

export const ApprovalStatusContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    padding: 22px 20px 22px 20px;
    .finance_info_layout {
      margin-bottom: 22px;
    }
    .block {
      border-radius: 12px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
      box-shadow: 0px 4px 8px -2px #1018281a;
      padding: 34px 12px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      overflow: hidden;
      .tracking_step_wrap {
        margin-bottom: 66px;
        padding: 0 34px;
      }
      .approve_f {
        margin-top: 34px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer {
    padding: 18px 10px;
  }
`;
