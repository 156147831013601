import React from "react";
import { RefinanceInfoContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Headers, Tables } from "components";

class RefinanceInfoContainer extends React.Component {
  state = {};

  componentDidMount() {}

  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_DOC);
  };

  render() {
    return (
      <RefinanceInfoContainerStyled>
        <Headers.Normal title="ดำเนินการยื่นเรื่องรีไฟแนนซ์" />
        <div className="body_container">
          <div className="refinane_wrap">
            <Cards.ReFinanceInfo />
          </div>
          <div className="refinane_wrap">
            <Tables.Standard columns={COLUMN_LIST} data={DATA} />
          </div>
          <div className="note">
            หมายเหตุ :
            บริษัทอาจไม่สามารถดำเนินการโอนเงินปิดบัญชีไปยังผู้ให้บริการ
            ทางการเงินตามรายการที่แสดง ไม่ว่าทั้งหมดหรือบางส่วน
            ทั้งนี้ให้เป็นไปตามข้อกำหนด และเงื่อนไขของบริษัท
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard label="ยืนยัน" onClick={this.handleClickNext} />
        </div>
      </RefinanceInfoContainerStyled>
    );
  }
}

const COLUMN_LIST = [
  {
    Header: "สินเชื่อที่ต้องการรีไฟแนนซ์", // หัวข้อใหญ่สุด
    columns: [
      {
        Header: <div style={{ textAlign: "left" }}>ชื่อสถานบัน</div>,
        accessor: "name",
        // Cell: (props) => <div>{moment(props.value).format("DD MMM YYYY")}</div>,
      },
      {
        Header: <div>ยอดหนี้</div>,
        accessor: "price",
        Cell: (props) => (
          <div style={{ textAlign: "center" }}> {props.value}</div>
        ),
      },
      {
        Header: <div style={{ textAlign: "right" }}>วงเงินรีไฟแนนซ์</div>,
        accessor: "finance",
        Cell: (props) => (
          <div style={{ textAlign: "right" }}> {props.value}</div>
        ),
      },
    ],
  },
];

const DATA = [
  {
    name: "ธนาคารกรุงไทย",
    price: "60,000.00",
    finance: "60,000.00",
  },
  {
    name: "ธนาคารกรุงไทย",
    price: "60,000.00",
    finance: "60,000.00",
  },
  {
    name: "ธนาคารกรุงไทย",
    price: "60,000.00",
    finance: "60,000.00",
  },
];

export default withRouter(RefinanceInfoContainer);
