import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TitleCarouselStyled, Dot } from "./styled";
import { useNavigate } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Cards, Icons } from "components";

const TitleCarousel = ({ theme_standard, title, type, src }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  let navigate = useNavigate();

  const _handleClickBack = () => {
    navigate(-1);
  };

  const _Dot = (onClick, isSelected) => {
    // return <span>1</span>;

    return <Dot active={isSelected ? 1 : 0} onClick={onClick} />;
  };

  const _renderData = () => {
    if (type) {
      return (
        <Carousel showThumbs={false} showStatus={false} renderIndicator={_Dot}>
          <Cards.CusInfoRec
            title={type.label}
            src={
              type.value === "SELF_EMPLOYED"
                ? "/assets/images/icons/avatar2.png"
                : "/assets/images/icons/avatar1.png"
            }
          />
          <Cards.CusInfoRec
            title={type.label}
            src={
              type.value === "SELF_EMPLOYED"
                ? "/assets/images/icons/avatar2.png"
                : "/assets/images/icons/avatar1.png"
            }
          />
        </Carousel>
      );
    } else {
      return (
        <Carousel showThumbs={false} showStatus={false} renderIndicator={_Dot}>
          <Cards.CusInfoRec
            title="พนักงานประจำ / ข้าราชการ / พนักงานประจำและมีอาชีพอิสระ"
            src="/assets/images/icons/avatar1.png"
          />

          <Cards.CusInfoRec
            title="พนักงานประจำ / ข้าราชการ / พนักงานประจำและมีอาชีพอิสระ"
            src="/assets/images/icons/avatar1.png"
          />
        </Carousel>
      );
    }
  };

  const cardRender = _renderData(type);
  return (
    <TitleCarouselStyled className={customClass}>
      <div className="header_sec">
        <button className="btn_back_header" onClick={_handleClickBack}>
          <Icons.ArrowLeft />
        </button>
        <div className="title_header">{title}</div>
      </div>
      <div className="header_carousel_sec">{cardRender}</div>
    </TitleCarouselStyled>
  );
};

TitleCarousel.propTypes = {};

export default TitleCarousel;
