import React from "react";
import { RegisterFinanceSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Buttons, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class RegisterFinanceSuccessContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.APPROVAL_STATUS);
  };

  render() {
    return (
      <RegisterFinanceSuccessContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="bd_block">
            <div className="title">ทำการสมัครสินเชื่อ</div>
            <div className="title mb_16">รีไฟแนนซ์สำเร็จ</div>
            <div className="desc mb_9">เลขที่อ้างอิง MDD24052400011</div>
            <img
              className="pin_success_img"
              alt="pin change success"
              src="/assets/images/icons/regis-finance-success.png"
            />
            <div className="desc">ระบบจะส่งแจ้งเตือนผลการพิจารณา</div>
            <div className="desc mb_15">ให้ท่านทราบอีกครั้ง</div>
            <div className="desc">ขอบคุณลูกค้าที่ให้ความไว้วางใจ</div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard
            label="ตรวจสอบสถานะ"
            onClick={this.handleClickNext}
          />
        </div>
      </RegisterFinanceSuccessContainerStyled>
    );
  }
}

export default withRouter(RegisterFinanceSuccessContainer);
