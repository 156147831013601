import React from "react";
import { PinResetContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Icons, Inputs } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class PinResetContainer extends React.Component {
  handleClickKeyboard = () => {
    const { router } = this.props;
    let queryString = "";
    if (router.location.search) {
      queryString = router.location.search;
    }
    this.props.router.navigate(
      !queryString
        ? ROUTE_PATH.PIN_RESET_SUCCESS
        : `${ROUTE_PATH.PIN_RESET_SUCCESS}${queryString}`
    );
  };

  render() {
    return (
      <PinResetContainerStyled>
        <div className="title">ตั้งค่ารหัา PIN</div>
        <div className="desc">กรุณากรอกรหัส PIN เพื่อยืนยันการทำรายการ</div>
        <div className="pin_row mb_15">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
        <div className="secure_block">
          <Icons.Secure width="14" height="16" />
          รหัส PIN ความปลอดภัยสูง
        </div>
        <div className="pad_keyboar_wrap">
          <Inputs.TextNumberKeyboadPad onClick={this.handleClickKeyboard} />
        </div>
      </PinResetContainerStyled>
    );
  }
}

export default withRouter(PinResetContainer);
