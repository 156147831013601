import React from "react";
import { AuthenTestContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
// import { moneyDDService } from "apiServices";
import { Displays, Forms, Headers } from "components";
// import { toast } from "react-toastify";
import { ROUTE_PATH } from "utils/constants/routePath";

class AuthenTestContainer extends React.Component {
  state = {
    step: 1,
  };

  handleSubmitStep1 = async (values) => {
    // this.setState({
    //   isBgLoading: true,
    // });
    // let params = {
    //   ...values,
    //   deviceId: "RP1A.200720.011",
    // };
    // let res = await moneyDDService.POST_LOGIN(params);
    // if (res && res.respCode === 2000) {
    //   this.setState({
    //     isBgLoading: false,
    //   });
    // } else {
    //   this.setState({
    //     isBgLoading: false,
    //   });
    //   toast.error(res.respMsg);
    // }

    this.setState({
      step: 2,
    });
  };

  handleSubmitStep2 = () => {
    this.props.router.navigate(ROUTE_PATH.TERM_AND_CONDITION);
  };

  render() {
    const { step, isBgLoading } = this.state;
    return (
      <AuthenTestContainerStyled>
        <Headers.Normal title={"Authen"} />
        <Displays.BGLoading visible={isBgLoading} />
        <div className="body_container">
          {step === 1 && (
            <Forms.AuthenTestStep1Form onSubmit={this.handleSubmitStep1} />
          )}
          {step === 2 && (
            <Forms.AuthenTestStep2Form onSubmit={this.handleSubmitStep2} />
          )}
        </div>
      </AuthenTestContainerStyled>
    );
  }
}

export default withRouter(AuthenTestContainer);
