import React from "react";
import { TermAndConditionFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const TermAndConditionForm = ({ onChangeAgreementTermCondition, onSubmit }) => {
  const schema = yup.object().shape({
    is_term_condition: yup
      .boolean()
      .required("Accept is required")
      .oneOf([true], "Accept is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <TermAndConditionFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="input_wrap">
          <Controller
            control={control}
            render={({ field }) => (
              <Inputs.Checkbox
                {...field}
                label="ข้าพเจ้าได้อ่านข้อกำหนดและเงื่อนไขเรียบร้อยแล้ว"
                errors={errors.is_term_condition?.message}
                onChangeCus={onChangeAgreementTermCondition}
              />
            )}
            name="is_term_condition"
            defaultValue={false}
          />
        </div>
        <Buttons.BgStandard
          type="submit"
          label="ยอมรับ"
          disabled={isSubmitting || !isValid}
        />
      </form>
    </TermAndConditionFormStyled>
  );
};

TermAndConditionForm.propTypes = {};

export default TermAndConditionForm;
