export default function renderIcon({
  width = "13",
  height = "13",
  color = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.47391 3.21151V8.75771C7.47391 9.16219 7.14713 9.48897 6.74264 9.48897C6.33816 9.48897 6.01138 9.16219 6.01138 8.75771V3.21151L4.33404 4.88885C4.04839 5.17451 3.58449 5.17451 3.29884 4.88885C3.01319 4.6032 3.01319 4.13931 3.29884 3.85366L6.2239 0.928593C6.50955 0.642943 6.97345 0.642943 7.2591 0.928593L10.1842 3.85366C10.4698 4.13931 10.4698 4.6032 10.1842 4.88885C9.89851 5.17451 9.43462 5.17451 9.14896 4.88885L7.47391 3.21151ZM2.35505 8.75771H5.28011C5.28011 9.56438 5.93597 10.2202 6.74264 10.2202C7.54932 10.2202 8.20518 9.56438 8.20518 8.75771H11.1302C11.9369 8.75771 12.5928 9.41356 12.5928 10.2202V10.9515C12.5928 11.7582 11.9369 12.414 11.1302 12.414H2.35505C1.54837 12.414 0.892517 11.7582 0.892517 10.9515V10.2202C0.892517 9.41356 1.54837 8.75771 2.35505 8.75771ZM10.7646 11.1343C10.9101 11.1343 11.0496 11.0765 11.1524 10.9737C11.2553 10.8708 11.3131 10.7313 11.3131 10.5859C11.3131 10.4404 11.2553 10.3009 11.1524 10.1981C11.0496 10.0952 10.9101 10.0374 10.7646 10.0374C10.6191 10.0374 10.4796 10.0952 10.3768 10.1981C10.2739 10.3009 10.2162 10.4404 10.2162 10.5859C10.2162 10.7313 10.2739 10.8708 10.3768 10.9737C10.4796 11.0765 10.6191 11.1343 10.7646 11.1343Z"
        fill={color}
      />
    </svg>
  );
}
