import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

export const financeRequest = createSlice({
  name: "financeRequestReducer",
  initialState,
  reducers: {
    setReduxFinanceRequest: (state, action) => {
      state = action.payload;
      return state;
    },
    setReduxStep1GeneralInfo: (state, action) => {
      state.occupation_type = action.payload.occupation_type;
      state.occupation = action.payload.occupation;
      state.job_category = action.payload.job_category;
      state.monthly_income = action.payload.monthly_income;
      state.monthly_expenses_debt = action.payload.monthly_expenses_debt;
      state.credit_limit = action.payload.credit_limit;
      state.installment_period = action.payload.installment_period;
      state.payment_due_date = action.payload.payment_due_date;
      state.current_step = 2;
      return state;
    },
    setReduxStep2PesonalInfo: (state, action) => {
      state.education_level = action.payload.education_level;
      state.gender = action.payload.gender;
      state.marital_status = action.payload.marital_status;
      state.num_working_children = action.payload.num_working_children;
      state.num_non_working_children = action.payload.num_non_working_children;
      state.current_step = 3;
      return state;
    },
    setReduxStep3WorkInfo: (state, action) => {
      state.workplace = action.payload.workplace;
      state.job_position = action.payload.job_position;
      state.job_duration_years = action.payload.job_duration_years;
      state.job_duration_months = action.payload.job_duration_months;
      state.total_work_experience_years =
        action.payload.total_work_experience_years;
      state.total_work_experience_months =
        action.payload.total_work_experience_months;
      state.workplace_house_number = action.payload.workplace_house_number;
      if (action.payload.workplace_alley) {
        state.workplace_alley = action.payload.workplace_alley;
      }
      if (action.payload.workplace_street) {
        state.workplace_street = action.payload.workplace_street;
      }
      state.workplace_province = action.payload.workplace_province;
      state.workplace_district = action.payload.workplace_district;
      state.workplace_subdistrict = action.payload.workplace_subdistrict;
      state.workplace_postal_code = action.payload.workplace_postal_code;
      state.current_step = 4;
      return state;
    },
    setReduxStep4AddressInfo: (state, action) => {
      state.residence_type = action.payload.residence_type;
      state.residence_duration_years = action.payload.residence_duration_years;
      state.residence_duration_months =
        action.payload.residence_duration_months;
      state.residence_status = action.payload.residence_status;
      state.house_number = action.payload.house_number;
      if (action.payload.alley) {
        state.alley = action.payload.alley;
      }
      if (action.payload.street) {
        state.street = action.payload.street;
      }
      state.province = action.payload.province;
      state.district = action.payload.district;
      state.subdistrict = action.payload.subdistrict;
      state.postal_code = action.payload.postal_code;
      state.mailing_address = action.payload.mailing_address;
      state.reference_info_list = action.payload.reference_info_list;
      state.current_step = 5;
      return state;
    },
    setReduxStep5AccountInfo: (state, action) => {
      state.loan_account = action.payload.loan_account;
      state.current_step = 6;
      return state;
    },
    setReduxStep6UploadDocInfo: (state, action) => {
      state.card_id = action.payload.card_id;
      if (action.payload.name_change_certificate) {
        state.name_change_certificate = action.payload.name_change_certificate;
      }
      state.current_salary_slip = action.payload.current_salary_slip;
      state.bank_statement = action.payload.bank_statement;
      state.credit_bureau_report = action.payload.credit_bureau_report;
      state.credit_score = action.payload.credit_score;
      if (action.payload.other_documents) {
        state.other_documents = action.payload.other_documents;
      }
      if (action.payload.referral_code) {
        state.referral_code = action.payload.referral_code;
      }
      state.current_step = 7;
      return state;
    },
    setReduxFinanceRequestAgreementPublicInfo: (state, action) => {
      state.agreement_public_info = action.payload;
      return state;
    },
    setReduxFinanceRequestClear: (state) => {
      state = false;
      return state;
    },
  },
});

export const {
  setReduxFinanceRequest,
  setReduxStep1GeneralInfo,
  setReduxStep2PesonalInfo,
  setReduxStep3WorkInfo,
  setReduxStep4AddressInfo,
  setReduxStep5AccountInfo,
  setReduxStep6UploadDocInfo,
  setReduxFinanceRequestAgreementPublicInfo,
  setReduxFinanceRequestClear,
} = financeRequest.actions;

export default financeRequest.reducer;
