import styled from "styled-components";

export const FinanceInfoStyled = styled.div`
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
  box-shadow: 0px 4px 8px -2px #1018281a;
  padding: 5px 12px;
  .fni_title {
    margin-bottom: 6px;
    color: ${({ theme }) => theme.COLORS.PINK_6};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .fni_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &.mb_6 {
      margin-bottom: 6px;
    }
    .fni_label {
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .fni_desc {
      color: ${({ theme }) => theme.COLORS.PINK_6};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    }
    .fni_price {
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S16};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
    }
  }

  &.theme_standard {
  }
`;
