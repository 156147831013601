import styled from "styled-components";

export const PinResetContainerStyled = styled.div`
  min-height: 100vh;
  background-image: url("/assets/images/backgrounds/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .title {
    padding-top: 132px;
    margin-bottom: 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .desc {
    margin-bottom: 58px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .pin_row {
    margin-bottom: 66px;
    min-height: 13px;
    display: flex;
    justify-content: center;
    column-gap: 17px;
    &.mb_15 {
      margin-bottom: 15px;
    }
    .dot {
      border-radius: 17px;
      width: 17px;
      height: 17px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
  .secure_block {
    margin-bottom: 41px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 4px;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .pad_keyboar_wrap {
  }
`;
