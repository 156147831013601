import styled from "styled-components";

export const RegisterFinanceSuccessContainerStyled = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .body_container {
    flex: 1;
    padding: 25px 20px 0px 20px;
    display: flex;
    flex-direction: column;
    .bd_block {
      flex: 1;
      border-radius: 13px;
      box-shadow: 2px 4px 15px 0px #0000001a;
      padding: 48px;
      text-align: center;
      .title {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        &.mb_16 {
          margin-bottom: 24px;
        }
      }
      .pin_success_img {
        margin-bottom: 26px;
        width: 160px;
        height: auto;
      }
      .desc {
        text-align: center;
        color: ${({ theme }) => theme.COLORS.GRAY_8};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        &.mb_9 {
          margin-bottom: 9px;
        }
        &.mb_15 {
          margin-bottom: 25px;
        }
      }
    }
  }
  .footer {
    padding: 18px 20px;
  }
`;
