import styled from "styled-components";

export const Step1GeneralInfoContainerStyled = styled.div`
  .body_container {
    padding: 26px 22px;
    .step_wrap {
      margin-bottom: 14px;
    }
  }
`;
