import { forwardRef } from "react";
import { RadioBoxContainer } from "./styled";
import cx from "classnames";

const RadioBox = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_three,
    desc,
    label,
    value,
    onChange,
    errors,
    options,
    isRequire,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
    theme_three: theme_three,
  });

  const _onChange = (e) => {
    onChange && onChange(e.value);
  };

  return (
    <RadioBoxContainer className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      {desc && <div className="rd_desc">{desc}</div>}
      <div className="radio_container" ref={ref}>
        {options &&
          options.map((e, i) => (
            <div
              key={i}
              className={`radio_option_item ${
                value === e.value ? "active" : ""
              } ${e.disabled ? "disabled" : ""}`}
              onClick={() => {
                if (!e.disabled) {
                  _onChange(e);
                }
              }}
            >
              {/* {_value === e.value ? <Icons.Radio isActive /> : <Icons.Radio />} */}
              {e.label}
            </div>
          ))}
      </div>
      {errors && <div className="errors">{errors}</div>}
    </RadioBoxContainer>
  );
});

export default RadioBox;
