export default function renderIcon({
  width = "14",
  height = "12",
  color = "white",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2027_4558)">
        <path
          d="M11.9669 11.9738H2.15321C1.07577 11.9738 0.200195 11.0983 0.200195 10.0208V6.95875C0.200195 6.57204 0.513942 6.25586 0.903084 6.25586C1.29223 6.25586 1.60597 6.56961 1.60597 6.95875V10.0208C1.60597 10.3224 1.85162 10.5705 2.15564 10.5705H11.9693C12.2709 10.5705 12.519 10.3248 12.519 10.0208V6.95875C12.519 6.57204 12.8327 6.25586 13.2219 6.25586C13.611 6.25586 13.9248 6.56961 13.9248 6.95875V10.0208C13.9248 11.0983 13.0492 11.9738 11.9718 11.9738H11.9669Z"
          fill={color}
        />
        <path
          d="M10.5848 4.04274C10.2978 3.7825 9.85268 3.80439 9.59244 4.09381L8.08695 5.76226V1.22632C8.08695 0.659626 7.62727 0.199951 7.06058 0.199951C6.49389 0.199951 6.03422 0.659626 6.03422 1.22632V5.76226L4.52872 4.09381C4.26848 3.80682 3.8234 3.7825 3.53641 4.04274C3.24942 4.30298 3.2251 4.74562 3.48533 5.03505L6.54011 8.41816C6.67387 8.56652 6.86358 8.64921 7.06058 8.64921C7.25759 8.64921 7.44973 8.56409 7.58106 8.41816L10.6358 5.03505C10.8961 4.74806 10.8742 4.30298 10.5848 4.04274Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2027_4558">
          <rect
            width="13.7197"
            height="11.774"
            fill={color}
            transform="translate(0.200195 0.199951)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
