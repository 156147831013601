import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TemplateDocumentDeleteStyled } from "./styled";

const TemplateDocumentDelete = ({
  theme_standard,
  desc,
  imgSource,
  title,
  onCancel,
  onConfirm,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <TemplateDocumentDeleteStyled className={customClass}>
      {title && (
        <div className="title_block">
          {title.map((e, i) => (
            <div key={i} className="title_label">
              {e}
            </div>
          ))}
        </div>
      )}
      <div className="gmoney_figture">
        <img
          className="gmoney_img"
          alt="gmoney"
          src={imgSource || "/assets/images/icons/gmoney.png"}
        />
      </div>
      {!desc ? (
        <div className="tpdd_desc">ท่านต้องการลบเอกสารใช้หรือไม่</div>
      ) : (
        typeof desc !== "string" && (
          <div className="">
            {desc.map((e, i) => (
              <div key={i} className="tpdd_desc_list">
                {e}
              </div>
            ))}
          </div>
        )
      )}
      <div className="tpdd_action_row">
        {onCancel && (
          <div className="tpdd_col">
            <button
              type="button"
              className="btn_tpdd_cancel"
              onClick={onCancel}
            >
              ยกเลิก
            </button>
          </div>
        )}
        <div className="tpdd_col">
          <button
            type="button"
            className="btn_tpdd_confirm"
            onClick={onConfirm}
          >
            ยืนยัน
          </button>
        </div>
      </div>
    </TemplateDocumentDeleteStyled>
  );
};

TemplateDocumentDelete.propTypes = {};

export default TemplateDocumentDelete;
