import Checkbox from "./Checkbox";
import DateTime from "./DateTime";
import DropdownField from "./DropdownField";
import InputCamera from "./InputCamera";
import OtpField from "./OtpField";
import Radio from "./Radio";
import Radio2 from "./Radio2";
import RadioBox from "./RadioBox";
import TextField from "./TextField";
import TextFieldPrefixIconField from "./TextFieldPrefixIconField";
import TextMask from "./TextMask";
import TextNumber from "./TextNumber";
import TextNumberBtn from "./TextNumberBtn";
import TextNumberField from "./TextNumberField";
import TextNumberFloat from "./TextNumberFloat";
import TextNumberKeyboadPad from "./TextNumberKeyboadPad";
import TextPhoneMask from "./TextPhoneMask";
import UploadImgField from "./UploadImgField";

const EXPORT = {
  Checkbox,
  DateTime,
  DropdownField,
  InputCamera,
  OtpField,
  Radio,
  Radio2,
  RadioBox,
  TextField,
  TextFieldPrefixIconField,
  TextMask,
  TextNumber,
  TextNumberBtn,
  TextNumberField,
  TextNumberFloat,
  TextNumberKeyboadPad,
  TextPhoneMask,
  UploadImgField,
};

export default EXPORT;
