export default function renderIcon({ width = '15', height = '15', active }) {
  return active ? (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="7.5" cy="7.5" r="7" fill="white" stroke="#ED008C" />
      <circle cx="7.5" cy="7.5" r="6" fill="#EE008E" stroke="white" />
    </svg>
  ) : (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="7.5"
        cy="7.5"
        r="6.75"
        fill="white"
        stroke="#EEEEEE"
        strokeWidth="1.5"
      />
    </svg>
  );
}
