import React from "react";
import { RefinanceProcessStatusContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Displays, Headers, Modals, Tables } from "components";

class RefinanceProcessStatusContainer extends React.Component {
  state = {};

  componentDidMount() {
    setTimeout(() => {
      this.setState({
        renderModal: (
          <Modals.TemplateRefinanceSuccess onConfirm={this.handleCloseModal} />
        ),
      });
    }, 400);
  }

  handleCloseModal = () => {
    this.setState({
      renderModal: false,
    });
  };

  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_DOC);
  };

  render() {
    const { renderModal } = this.state;
    return (
      <>
        <RefinanceProcessStatusContainerStyled>
          <Headers.Normal title="ดำเนินการยื่นเรื่องรีไฟแนนซ์" />
          <div className="body_container">
            <div className="refinane_wrap">
              <Cards.ReFinanceInfo />
            </div>
            <div className="refinane_wrap">
              <Tables.Standard columns={COLUMN_LIST} data={DATA} />
            </div>
            <div className="note">
              หมายเหตุ :
              บริษัทอาจไม่สามารถดำเนินการโอนเงินปิดบัญชีไปยังผู้ให้บริการ
              ทางการเงินตามรายการที่แสดง ไม่ว่าทั้งหมดหรือบางส่วน
              ทั้งนี้ให้เป็นไปตามข้อกำหนด และเงื่อนไขของบริษัท
            </div>
          </div>
          <div className="footer">
            <Buttons.BgStandard
              disabled
              label="กลับไปหน้าหลัก"
              onClick={this.handleClickNext}
            />
          </div>
        </RefinanceProcessStatusContainerStyled>
        <Modals.BodyEmpty
          isShowModal={renderModal ? true : false}
          onCloseModal={this.handleCloseModal}
        >
          {renderModal}
        </Modals.BodyEmpty>
      </>
    );
  }
}

const COLUMN_LIST = [
  {
    Header: "สินเชื่อที่ต้องการรีไฟแนนซ์", // หัวข้อใหญ่สุด
    columns: [
      {
        Header: <div style={{ textAlign: "left" }}>ชื่อสถานบัน</div>,
        accessor: "name",
        // Cell: (props) => <div>{moment(props.value).format("DD MMM YYYY")}</div>,
      },
      {
        Header: <div style={{ textAlign: "right" }}>วงเงินรีไฟแนนซ์</div>,
        accessor: "finance",
        Cell: (props) => (
          <div style={{ textAlign: "right" }}> {props.value}</div>
        ),
      },
      {
        Header: <div>สถานะการชำระ</div>,
        accessor: "status",
        Cell: (props) => (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Displays.ServiceStatus status={props.value} />
          </div>
        ),
      },
    ],
  },
];

const DATA = [
  {
    name: "ธนาคารกรุงไทย",
    finance: "60,000.00",
    status: "pending",
  },
  {
    name: "ธนาคารกรุงไทย",
    finance: "60,000.00",
    status: "complete",
  },
  {
    name: "ธนาคารกรุงไทย",
    finance: "60,000.00",
    status: "reject",
  },
];

export default withRouter(RefinanceProcessStatusContainer);
