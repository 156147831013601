import Alert from "./Alert";
import BodyEmpty from "./BodyEmpty";
import TemplateDocumentDelete from "./TemplateDocumentDelete";
import TemplateRefinanceSuccess from "./TemplateRefinanceSuccess";

const EXPORT = {
  Alert,
  BodyEmpty,
  TemplateDocumentDelete,
  TemplateRefinanceSuccess,
};

export default EXPORT;
