import styled from "styled-components";

export const BookConfirmConsentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .title {
    margin-top: 27px;
    margin-bottom: 17px;
    padding: 0 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .body_sec {
    flex: 1;
    overflow: auto;
    padding: 0 20px;
    .confiton_container {
      border-radius: 14px;
      border: 1px solid ${({ theme }) => theme.COLORS.GRAY_6};
      padding: 12px 16px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
  .footer_sec {
    padding: 20px 20px 26px 20px;
    .version_label {
      margin-bottom: 20px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;
