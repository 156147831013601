import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { FinanceInfoStyled } from "./styled";

const FinanceInfo = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <FinanceInfoStyled className={customClass}>
      <div className="fni_title">สินเชื่อรีไฟแนนซ์</div>
      <div className="fni_row mb_6">
        <div className="fni_label">ดอกเบี้ยเริ่มต้น 19.00% ต่อปี</div>
        <div className="fni_desc">รายละเอียดผลิตภัณฑ์</div>
      </div>
      <div className="fni_row">
        <div className="fni_label">วงเงินที่ได้รับการอนุมัติ</div>
        <div className="fni_price">300,000.00 บาท</div>
      </div>
    </FinanceInfoStyled>
  );
};

FinanceInfo.propTypes = {};

export default FinanceInfo;
