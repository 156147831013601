import React from "react";
import { AgreementPublicInfoFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const AgreementPublicInfoForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    test: yup.string().required("New password is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm({
    resolver: yupResolver(schema),
  });

  return (
    <AgreementPublicInfoFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="condition_block">
          <div className="condition">
            ข้าพเจ้าได้อ่านและเข้าใจรายละเอียดในข้อกำหนดและเงื่อนไข ของผลิตภัณฑ์
            ดังนี้ 1. ประเภทและรูปแบบผลิตภัณฑ์ที่ให้บริการ บริษัท เงินดีดี จำกัด
            ("บริษัท") ให้บริการและเสนอขาย ผลิตภัณฑ์สินเชื่อกู๊ดมันนี่
            ที่ซึ่งเป็นสินเชื่อแบบไม่มีหลัก ทรัพย์หรือบุคคลค้ำประกัน
            โดยคิดอัตราดอกเบี้ยแบบลดต้น ลดดอกสูงสุดไม่เกินตามที่กฎหมายกำหนด*
            ได้แก่ 1) สินเชื่อส่วนบุคคลกู๊ดมันนี่ (สินเชื่อส่วนบุคคลภายใต้การ
            กำกับ) มีวัตถุประสงค์เพื่อเพิ่มสภาพคล่องในการดำรงชีพ /
            เพื่อการประกอบอาชีพ 2) สินเชื่อนาโนกู๊ดมันนี่
            (สินเชื่อรายย่อยเพื่อการประกอบ อาชีพภายใต้การกำกับ)
            มีวัตถุประสงค์เพื่อการประกอบ อาชีพ ทั้งนี้ รูปแบบของผลิตภัณฑ์ทั้ง 2
            ประเภท สามารถแบ่งย่อย ได้ 2 รูปแบบ ได้แก่ 1)
            การให้กู้ยืมเงินในลักษณะที่มีกำหนดระยะเวลา (Term เoan)
            หรือชื่อที่ปรากฎในแอปพลิเคชัน Good Money เรียก ว่า
            "รับเงินก้อนครั้งเดียว" หรือชื่ออื่นใดที่บริษัทจะกำหนดใน ภายหลัง 2)
            การให้กู้ยืมเงินในลักษณะการให้วงเงินสินเชื่อแบบ หมุนเวียน
            หรือชื่อที่ปรากฎในแอปพลิเคชัน Good Money เรียกว่า
            "รับวงเงินหมุนเวียน" หรือชื่ออื่นใดที่บริษัทจะ กำหนดในภายหลัง

            
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.Radio2
                  {...field}
                  options={[
                    { label: "ยินยอม", value: "1" },
                    { label: "ไม่ยินยอม", value: "2" },
                  ]}
                  errors={errors.phone?.message}
                />
              )}
              name="test"
              defaultValue=""
            />
          </div>
        </div>
        <div className="btn_submit_wrap">
          <Buttons.BgStandard
            type="submit"
            label="เสร็จสิ้น"
            disabled={isSubmitting || !isValid}
          />
        </div>
      </form>
    </AgreementPublicInfoFormStyled>
  );
};

AgreementPublicInfoForm.propTypes = {};

export default AgreementPublicInfoForm;
