import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { LogoStyled } from "./styled";
import { useNavigate } from "react-router-dom";
import { Icons } from "components";

const Logo = ({ theme_standard, title }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  let navigate = useNavigate();

  const _handleClickBack = () => {
    navigate(-1);
  };

  return (
    <LogoStyled className={customClass}>
      <button className="btn_back_header" onClick={_handleClickBack}>
        <Icons.ArrowLeftCircle />
      </button>
      <div className="logo_header">
        <img alt="logo" src="/assets/images/icons/logo2.png" />
      </div>
    </LogoStyled>
  );
};

Logo.propTypes = {};

export default Logo;
