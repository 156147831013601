import styled from "styled-components";

export const LogoStyled = styled.div`
  position: relative;
  padding: 0px 30px;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("/assets/images/backgrounds/header-circle.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  .btn_back_header {
    position: absolute;
    left: 16px;
    bottom: 0px;
    background: transparent;
    cursor: pointer;
  }
  .logo_header {
    position: absolute;
    bottom: 13px;
    width: 57px;
    img {
      width: 100%;
      height: auto;
    }
  }

  .theme_standard {
  }
`;
