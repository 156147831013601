import styled from "styled-components";

export const OfferContainerStyled = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  .scroll_container {
    flex: 1;
    padding: 20px 20px 15px 20px;
    overflow: auto;
    .offer_container {
      border-radius: 13px;
      box-shadow: 0px 4px 3px 0px #00000014;
      overflow: hidden;
      .offer_img {
        width: 100%;
        height: auto;
      }
      .contentd_block {
        margin-bottom: 20px;
        padding: 10px 20px;
        .article_layout {
          margin-bottom: 16px;
          .title {
            margin-bottom: 5px;
            color: ${({ theme }) => theme.COLORS.PINK_4};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
          .desc {
            color: ${({ theme }) => theme.COLORS.GRAY_8};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
            &.fw_regular {
              font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
            }
          }
          .desc_list {
            margin: 0;
            padding-left: 24px;
            &.mb_16 {
              margin-bottom: 16px;
            }
            li {
              color: ${({ theme }) => theme.COLORS.GRAY_8};
              font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
              font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
            }
          }
        }
      }
    }
  }
  .action_layout {
    padding: 12px 20px;
  }
`;
