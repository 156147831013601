import React from "react";
import { CreditLimitConfirmPinContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import PinVerifyContainer from "containers/PinSystem/PinVerifyContainer";

class CreditLimitConfirmPinContainer extends React.Component {
  handleVerifySuccess = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_EXCUTE);
  };

  render() {
    return (
      <CreditLimitConfirmPinContainerStyled>
        <PinVerifyContainer
          resetRedirect={ROUTE_PATH.REFINANCE_CREDIT_PIN_VERIFY}
          onVerifySuccess={this.handleVerifySuccess}
        />
      </CreditLimitConfirmPinContainerStyled>
    );
  }
}

export default withRouter(CreditLimitConfirmPinContainer);
