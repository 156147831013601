import styled from "styled-components";

export const UploadMediaWidgetStyled = styled.div`
  .upmd_label {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    column-gap: 2px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    .sub_label {
      color: ${({ theme }) => theme.COLORS.GRAY_3};
    }
    span {
      color: ${({ theme }) => theme.COLORS.PINK_6};
    }
  }
  .upmd_container {
    .input_upload {
      border-radius: 10px;
      box-shadow: 0px 4px 4px 0px #00000014;
      padding: 3.5px 12px;
      display: flex;
      align-items: center;
      column-gap: 12px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
      cursor: pointer;
      .circle_upload {
        border-radius: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(102.16deg, #ed008c 57.64%, #ff9300 97.99%);
      }
      .ul_desc_label {
        margin-top: 8px;
        margin-bottom: 12px;
        color: ${({ theme }) => theme.COLORS.PINK_6};
        font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
        font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
      }
    }
    .file_upload_block {
      // border-radius: 10px;
      // border: 1px solid ${({ theme }) => theme.COLORS.GRAY_6};
      // padding: 12px;
      width: 100%;
      .file_row {
        margin-bottom: 8px;
        border-radius: 8px;
        padding: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 6px;
        background: ${({ theme }) => theme.COLORS.PINK_5};
        .fr_left_col {
          display: flex;
          align-items: center;
          column-gap: 10px;
          .f_name {
            color: ${({ theme }) => theme.COLORS.GRAY_8};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
          .f_size {
            color: ${({ theme }) => theme.COLORS.GRAY_8};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
          }
        }
        .fr_right_col {
          display: flex;
          align-items: center;
          column-gap: 5px;
          .ful_icon_wrap {
            cursor: pointer;
          }
        }
      }
      .file_code {
        margin-bottom: 16px;
      }
    }
  }

  &.theme_update {
    .upmd_label {
      margin-bottom: 2px;
      text-align: left;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
    .upmd_desc_label {
      margin: 0px;
      margin-bottom: 11px;
      padding-left: 16px;
      color: ${({ theme }) => theme.COLORS.GRAY_8};
      font-size: ${({ theme }) => theme.FONTS.SIZES.S10};
      font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
    }
  }
`;

export const UploadMethodModalStyled = styled.div`
  margin: auto;
  width: 100vw;
  max-width: ${({ theme }) => theme.SCREENS.SURFACE_DUO};
  height: 100vh;
  display: flex;
  flex-direction: column;
  .upm_bd {
    flex: 1;
    background: ${({ theme }) => theme.COLORS.WHIET_1}59;
  }
  .upm_body {
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    padding: 22px 20px;
    background: ${({ theme }) => theme.COLORS.WHITE_1};
    .btn_upm_wrap {
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
`;
