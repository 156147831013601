import React from "react";
import { RefinanceSuccessContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Buttons, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class RefinanceSuccessContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_PROCESS_STATUS);
  };

  render() {
    return (
      <RefinanceSuccessContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="top_block">
            <div className="title">ดำเนินการยื่นเรื่องรีไฟแนนซ์</div>
            <div className="desc">เลขที่อ้างอิง MDD24052400011</div>
            <img
              className="pin_success_img"
              alt="pin change success"
              src="/assets/images/icons/refinance-success.png"
            />
            <div className="process_label">
              ระบบทำการส่งสัญญาสินเชื่อไปยังอีเมล (Su*****@moneydd.co.th)
              เรียบร้อยแล้ว
            </div>
            <div className="process_label">กรุณาตรวจสอบกล่องข้อความของท่าน</div>
          </div>
          <div className="bottom_block">
            <div className="rec_label">
              ระบบจะทำการชำระยอดหนี้ไปยังผู้ให้บริการทางการเงิน ที่ท่านระบุไว้
              <ul>
                <li>ธนาคารกรุงไทย</li>
                <li>บริษัท กรุงศรีออโต้</li>
                <li>บริษัท อยุธยา แคปปิตอล เซอร์วิสเซส จำกัด</li>
              </ul>
            </div>
            <div className="contact_label">
              <div>หากต้องการสอบถามเพิ่มกรุณาติดต่อ Call Center</div>โทร
              02-024-38881
            </div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard
            label="ตรวจสอบสถานะ"
            onClick={this.handleClickNext}
          />
        </div>
      </RefinanceSuccessContainerStyled>
    );
  }
}

export default withRouter(RefinanceSuccessContainer);
