export default function renderIcon() {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_2569_10300)">
        <ellipse
          cx="23.4181"
          cy="18.3471"
          rx="17.7013"
          ry="17.6947"
          fill="white"
        />
      </g>
      <path
        d="M29.3917 19.656H17.7207M17.7207 19.656L23.5562 25.5698M17.7207 19.656L23.5562 13.7422"
        stroke="#ED018C"
        stroke-width="1.67"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_d_2569_10300"
          x="0.262797"
          y="0.652344"
          width="46.3103"
          height="46.2967"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="5.454" />
          <feGaussianBlur stdDeviation="2.727" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2569_10300"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2569_10300"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
