import styled from "styled-components";

export const NCBConsentContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  .title {
    margin-top: 36px;
    margin-bottom: 18px;
    padding: 0px 20px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .body_sec {
    flex: 1;
    padding: 16px 20px 22px 20px;
    overflow: auto;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .footer_sec {
    padding: 0px 12px 26px 12px;
    .download_wrap {
      margin-bottom: 40px;
    }
  }
`;
