import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { ReFinanceInfoStyled } from "./styled";

const ReFinanceInfo = ({ theme_standard }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <ReFinanceInfoStyled className={customClass}>
      <div className="fni_top_block">
        <div className="fni_row mb_9">
          <div className="fni_label pink">สินเชื่อรีไฟแนนซ์</div>
        </div>
        <div className="fni_row mb_9">
          <div className="fni_label">ดอกเบี้ยเริ่มต้น 19.00% ต่อปี</div>
          <div className="fni_value pink">รายละเอียดผลิตภัณฑ์</div>
        </div>
        <div className="fni_row">
          <div className="fni_label">วงเงินที่ได้รับการอนุมัติ</div>
          <div className="fni_value">300,000.00 บาท</div>
        </div>
      </div>
      <div className="fni_bottom_block">
        <div className="bb_label">วงเงินที่ได้รับการอนุมัติ</div>
        <div className="bb_value">300,000.00 บาท</div>
      </div>
    </ReFinanceInfoStyled>
  );
};

ReFinanceInfo.propTypes = {};

export default ReFinanceInfo;
