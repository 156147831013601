import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { IconSquarePrefixStyled } from "./styled";

const IconSquarePrefix = ({
  theme_standard,
  icon,
  label,
  type = "button",
  onClick,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <IconSquarePrefixStyled
      className={customClass}
      type={type}
      onClick={onClick}
    >
      <div className="icq_square">{icon}</div>
      {label}
    </IconSquarePrefixStyled>
  );
};

IconSquarePrefix.propTypes = {};

export default IconSquarePrefix;
