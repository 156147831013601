import styled from "styled-components";

export const PrivacyPolicyFormStyled = styled.div`
  padding: 30px 26px;
  .pvpl_title {
    margin-bottom: 22px;
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S24};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .pvpl_title_topic {
    margin-bottom: 22px;
    color: ${({ theme }) => theme.COLORS.PINK_4};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
  }
  .pvpl_content {
    margin-bottom: 13px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .input_wrap {
    margin-bottom: 14px;
  }
`;
