import styled from "styled-components";

export const PinVerifyContainerStyled = styled.div`
  min-height: 100vh;
  background-image: url("/assets/images/backgrounds/bg-1.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;
  .title {
    padding-top: 54px;
    margin-bottom: 28px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S20};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.MEDIUM};
  }
  .figture {
    margin-bottom: 16px;
    text-align: center;
    .avatar_img {
      width: 130px;
      height: auto;
    }
  }
  .desc {
    margin-bottom: 12px;
    text-align: center;
    color: ${({ theme }) => theme.COLORS.WHITE_1};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
  }
  .pin_row {
    margin-bottom: 30px;
    min-height: 13px;
    display: flex;
    justify-content: center;
    column-gap: 17px;
    .dot {
      border-radius: 17px;
      width: 17px;
      height: 17px;
      background: ${({ theme }) => theme.COLORS.WHITE_1};
    }
  }
  .btn_forget_pin {
    margin-bottom: 22px;
    border-radius: 100px;
    padding: 2px 46px;
    color: ${({ theme }) => theme.COLORS.GRAY_8};
    font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
    font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
    font-family: inherit;
    cursor: pointer;
  }
  .pad_keyboar_wrap {
  }
`;
