export default function renderIcon({
  width = "19",
  height = "14",
  color = "#ED008C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 19 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.370194 8.06898L3.74285 12.9591C4.11206 13.4946 4.79288 13.8245 5.52868 13.8245H16.825C18.0256 13.8245 18.9997 13.0049 18.9997 11.9941V1.83035C18.9997 0.819598 18.0256 0 16.825 0H5.52868C4.79288 0 4.11206 0.329934 3.74285 0.865422L0.370194 5.75551C-0.123398 6.47037 -0.123398 7.35412 0.370194 8.06898Z"
        fill={color}
      />
      <path
        d="M14.0403 4.95727C14.2959 4.70165 14.2942 4.28688 14.0364 4.02911C13.7787 3.77135 13.3639 3.76961 13.1083 4.02523L11.0287 6.10483L8.9316 4.00775C8.67384 3.74999 8.25907 3.74825 8.00345 4.00387C7.74784 4.25949 7.74957 4.67426 8.00734 4.93202L10.1044 7.0291L8.02481 9.10871C7.76919 9.36432 7.77093 9.77909 8.0287 10.0369C8.28646 10.2946 8.70123 10.2964 8.95685 10.0407L11.0365 7.96114L13.1335 10.0582C13.3913 10.316 13.8061 10.3177 14.0617 10.0621C14.3173 9.80648 14.3156 9.39171 14.0578 9.13395L11.9607 7.03687L14.0403 4.95727Z"
        fill="white"
      />
    </svg>
  );
}
