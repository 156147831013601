export default function renderIcon({
  width = "13",
  height = "13",
  color = "#ED008C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.5C5.13261 7.5 5.25978 7.44732 5.35355 7.35355C5.44732 7.25979 5.5 7.13261 5.5 7L5.5 5C5.5 4.86739 5.44732 4.74022 5.35355 4.64645C5.25978 4.55268 5.13261 4.5 5 4.5C4.86739 4.5 4.74021 4.55268 4.64644 4.64645C4.55268 4.74022 4.5 4.86739 4.5 5L4.5 7C4.5 7.13261 4.55268 7.25978 4.64644 7.35355C4.74021 7.44732 4.86739 7.5 5 7.5ZM4.54 3.19C4.55094 3.22186 4.56607 3.25213 4.585 3.28L4.645 3.355C4.71531 3.42438 4.8046 3.47138 4.90159 3.49006C4.99859 3.50875 5.09894 3.49829 5.19 3.46C5.25059 3.43468 5.3064 3.39917 5.355 3.355C5.40134 3.30828 5.438 3.25287 5.46288 3.19195C5.48777 3.13103 5.50038 3.0658 5.5 3C5.49921 2.93466 5.48562 2.87011 5.46 2.81C5.43754 2.74795 5.40172 2.6916 5.35506 2.64494C5.3084 2.59828 5.25205 2.56246 5.19 2.54C5.13015 2.51355 5.06543 2.49988 5 2.49988C4.93456 2.49988 4.86985 2.51355 4.81 2.54C4.74795 2.56246 4.6916 2.59828 4.64494 2.64494C4.59828 2.6916 4.56245 2.74795 4.54 2.81C4.51438 2.87011 4.50079 2.93466 4.5 3C4.49754 3.03329 4.49754 3.06671 4.5 3.1C4.50861 3.13188 4.5221 3.16224 4.54 3.19ZM5 10C5.9889 10 6.9556 9.70675 7.77785 9.15735C8.60009 8.60794 9.24096 7.82705 9.6194 6.91342C9.99783 5.99979 10.0969 4.99445 9.90392 4.02455C9.711 3.05464 9.23479 2.16373 8.53553 1.46447C7.83627 0.765204 6.94535 0.289 5.97545 0.0960738C5.00554 -0.0968517 4.00021 0.00216438 3.08658 0.380602C2.17295 0.75904 1.39206 1.3999 0.842649 2.22215C0.293242 3.04439 -3.21953e-06 4.01109 -3.31781e-06 5C-3.44959e-06 6.32608 0.526781 7.59785 1.46446 8.53553C2.40215 9.47322 3.67391 10 5 10ZM5 1C5.79112 1 6.56448 1.2346 7.22228 1.67412C7.88008 2.11365 8.39277 2.73836 8.69552 3.46927C8.99827 4.20017 9.07748 5.00444 8.92314 5.78036C8.7688 6.55629 8.38784 7.26902 7.82842 7.82843C7.26901 8.38784 6.55628 8.7688 5.78036 8.92314C5.00443 9.07748 4.20017 8.99827 3.46926 8.69552C2.73836 8.39277 2.11364 7.88008 1.67412 7.22228C1.23459 6.56448 0.999998 5.79113 0.999998 5C0.999998 3.93913 1.42142 2.92172 2.17157 2.17157C2.92172 1.42143 3.93913 1 5 1Z"
        fill={color}
      />
    </svg>
  );
}
