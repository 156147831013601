import React, { forwardRef } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { TextFieldPrefixIconFieldStyled } from "./styled";

const TextFieldPrefixIconField = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_label_horizontal,
    label,
    disabled,
    errors,
    icon,
    isRequire,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_label_horizontal: theme_label_horizontal,
  });
  return (
    <TextFieldPrefixIconFieldStyled disabled={disabled}>
      <div className={`input_text_container ${customClass}`}>
        {label && (
          <div className="label">
            {label} {isRequire && <span>*</span>}
          </div>
        )}
        <div className="input_block">
          <div className="ip_icon">{icon}</div>
          <input {...props} disabled={disabled} />
        </div>
        {errors && <div className="errors">{errors}</div>}
      </div>
    </TextFieldPrefixIconFieldStyled>
  );
});

TextFieldPrefixIconField.propTypes = {};

export default TextFieldPrefixIconField;
