import React from "react";
import { ApprovalStatusContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Displays, Forms, Headers, Icons } from "components";

class ApprovalStatusContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REQUEST_FINANCE);
  };

  render() {
    return (
      <ApprovalStatusContainerStyled>
        <Headers.Normal title="ยื่นเรื่องไฟแนนซ์" />
        <div className="body_container">
          <div className="finance_info_layout">
            <Cards.FinanceInfo />
          </div>
          <div className="block">
            <div className="tracking_step_wrap">
              <Displays.TrackingStep
                currentStep={1}
                steps={[
                  "ขอสินเชื่อ",
                  ["พิจารณา", "สินเชื่อ"],
                  ["ผลการอนุมัติ", "สินเชื่อ"],
                ]}
              />
            </div>
            <div className="form_wrap">
              <Forms.UploadDocEtcForm />
            </div>
            <div className="approve_f">
              <Displays.ApproveStatus
                icon={<Icons.Infomation2 />}
                label="วงเงินของท่านไม่ได้รับการอนุมัติ"
              />
            </div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard
            label="ทำรายการต่อ"
            onClick={this.handleClickNext}
          />
        </div>
      </ApprovalStatusContainerStyled>
    );
  }
}

export default withRouter(ApprovalStatusContainer);
