import React from "react";
import { CreditLimitChangeContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms, Headers } from "components";

class CreditLimitChangeContainer extends React.Component {
  handleSubmit = (values) => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_CREDIT_PIN_VERIFY);
  };

  render() {
    return (
      <CreditLimitChangeContainerStyled>
        <Headers.Normal title="ดำเนินการยื่นเรื่องรีไฟแนนซ์" />
        <div className="body_container">
          <div className="header_layout">
            <div className="title">เลือกปรับวงเงิน</div>
            <div className="title">ที่ท่านต้องการได้เลย</div>
          </div>
        </div>
        <Forms.CreditLimitEditForm onSubmit={this.handleSubmit} />
      </CreditLimitChangeContainerStyled>
    );
  }
}

export default withRouter(CreditLimitChangeContainer);
