import styled from "styled-components";

export const StandardStyled = styled.div`
  /*===============================================
 Container 
===============================================*/

  /*===============================================
Theme 
===============================================*/
  table {
    border-spacing: 0;
    border: none;
    width: 100%;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_12};
    border-radius: 12px;
    overflow: hidden;
    thead {
      tr {
        &:first-child {
          th {
            padding: 8px 0px;
            background: ${({ theme }) => theme.COLORS.WHITE_1};
            color: ${({ theme }) => theme.COLORS.PINK_6};
            font-size: ${({ theme }) => theme.FONTS.SIZES.S18};
            font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
          }
        }
        th {
          padding: 6px 12px;
          background: ${({ theme }) => theme.COLORS.PINK_6};
          color: ${({ theme }) => theme.COLORS.WHITE_1};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};
        }
      }
    }
    tbody {
      tr {
        td {
          border-bottom: 1px solid ${({ theme }) => theme.COLORS.GRAY_10};
          padding: 8px 12px;
          color: ${({ theme }) => theme.COLORS.GRAY_8};
          font-size: ${({ theme }) => theme.FONTS.SIZES.S12};
          font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.LIGHT};
        }
        &:last-child {
          td {
            border-bottom: 0px;
          }
        }
        &:nth-child(even) {
          td {
            background: ${({ theme }) => theme.COLORS.GRAY_7};
          }
        }
      }
    }
  }
  .pagi_layout {
    margin-top: 20px;
  }
`;
