import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { DateTimeStyled } from "./styled";
import DatePicker from "react-mobile-datepicker";
import moment from "moment";

const DateTime = ({
  theme_standard,
  label,
  placeholder,
  onChange,
  isRequire,
}) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValue] = useState();
  const [_isOpen, _setIsOpen] = useState();

  const _handleSelect = (e) => {
    _setValue(e);
    onChange && onChange(e);
    // _setIsOpen(false);
  };

  const _handleClick = () => {
    _setIsOpen(true);
  };
  const _handleClickCancel = () => {
    _setIsOpen(false);
  };

  return (
    <DateTimeStyled className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      <div className="input_date_time" onClick={_handleClick}>
        {_value ? moment(_value).format("DD/MM/YYYY") : placeholder}
      </div>
      <DatePicker
        showHeader={false}
        showFooter={false}
        dateFormat={["MMM", "DD", "YYYY"]}
        dateConfig={DATE_CONFIG}
        value={_value}
        isOpen={_isOpen}
        onChange={_handleSelect}
        onCancel={_handleClickCancel}
        confirmText="ตกลง"
        cancelText="ยกเลิก"
      />
    </DateTimeStyled>
  );
};

const monthMap = {
  1: "January",
  2: "February",
  3: "March",
  4: "April",
  5: "May",
  6: "Jun",
  7: "July",
  8: "August",
  9: "September",
  10: "October",
  11: "November",
  12: "December",
};

const DATE_CONFIG = {
  month: {
    format: (value) => monthMap[value.getMonth() + 1],
    caption: "Mon",
    step: 1,
  },
  date: {
    format: "DD",
    caption: "Day",
    step: 1,
  },
  year: {
    format: "YYYY",
    caption: "Year",
    step: 1,
  },
};

DateTime.propTypes = {};

export default DateTime;
