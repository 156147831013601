import React from "react";
import { RequestFinanceAgainContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { Buttons, Headers } from "components";
import { ROUTE_PATH } from "utils/constants/routePath";

class RequestFinanceAgainContainer extends React.Component {
  handleClickNext = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_CREDIT_COMFIRM);
  };

  render() {
    return (
      <RequestFinanceAgainContainerStyled>
        <Headers.Normal title="สมัครสินเชื่อรีไฟแนนซ์" />
        <div className="body_container">
          <div className="bd_block">
            <div className="title">ทำการส่งเอกสาร</div>
            <div className="title mb_16">เพิ่มเติมสำเร็จ</div>
            <div className="desc">เลขที่อ้างอิง MDD24052400011</div>
            <img
              className="pin_success_img"
              alt="pin change success"
              src="/assets/images/icons/approve-request.png"
            />
            <div className="desc_sec2 mb_15">
              ระบจะส่งแจ้งเตือนผลการพิจารณา ให้ท่านทราบอีกครั้ง
            </div>
            <div className="desc_sec2">ขอบคุณลูกค้าที่ให้ความไว้วางใจ</div>
          </div>
        </div>
        <div className="footer">
          <Buttons.BgStandard
            label="ตรวจสอบสถานะ"
            onClick={this.handleClickNext}
          />
        </div>
      </RequestFinanceAgainContainerStyled>
    );
  }
}

export default withRouter(RequestFinanceAgainContainer);
