import React, { useState, forwardRef, useImperativeHandle } from "react";
// import PropTypes from 'prop-types'
import { AlertStyled } from "./styled";
import { Icons } from "components";
// import { Buttons } from "components";

const Alert = forwardRef((props, ref) => {
  const [_modalVisible, _setModalVisible] = useState(false);
  const [_tempValue, _setTempValue] = useState(false);

  useImperativeHandle(ref, () => ({
    openModal({
      theme,
      title,
      description,
      data,
      btnLabel1,
      onEvent1,
      btnLabel2,
      onEvent2,
      isClose,
    }) {
      _setTempValue({
        theme,
        title,
        description,
        data,
        btnLabel1,
        onEvent1,
        btnLabel2,
        onEvent2,
        isClose,
      });
      _handleClickEnbModal();
    },
  }));

  // ENABLE DISABLE
  // ====================================
  // ====================================
  const _handleClickEnbModal = () => {
    const body = document.body;
    body.style.overflow = "hidden";
    _setModalVisible(true);
  };
  const _handleClickDisModal = () => {
    const body = document.body;
    body.style.overflow = "auto";
    _setModalVisible(false);
  };

  // CANCEL COMFIRM
  // ====================================
  // ====================================
  const handleClickCancelModal = () => {
    _handleClickDisModal();
  };
  const _onConfirm = (key) => {
    if (key === 1) {
      _tempValue.onEvent1 && _tempValue.onEvent1();
    }
    if (key === 2) {
      _tempValue.onEvent2 && _tempValue.onEvent2();
    }
  };

  return !_modalVisible ? (
    <></>
  ) : (
    <AlertStyled>
      <div className="backdrop" onClick={_handleClickDisModal} />
      <div
        className={`alert_body ${_tempValue?.theme ? _tempValue?.theme : ""}`}
      >
        {_tempValue?.isClose && (
          <div className="btn_alert_close" onClick={_handleClickDisModal}>
            <Icons.Close />
          </div>
        )}
        <div className="alert_title">{_tempValue?.title}</div>
        {_tempValue?.description &&
          (typeof _tempValue?.description === "string" ? (
            <div className="alert_description">{_tempValue?.description}</div>
          ) : (
            _tempValue?.description.map((e, i) => (
              <div key={i} className="alert_description">{e}</div>
            ))
          ))}
        <div className="alert_action_row">
          {_tempValue.btnLabel1 && (
            <div className="alert_action_col">
              <button
                className="btn_alert_cancel"
                onClick={() => {
                  _onConfirm(1);
                  handleClickCancelModal();
                }}
              >
                {_tempValue.btnLabel1}
              </button>
            </div>
          )}
          {_tempValue.btnLabel2 && (
            <div className="alert_action_col">
              <button
                className="btn_alert_confirm"
                onClick={() => {
                  _onConfirm(2);
                  handleClickCancelModal();
                }}
              >
                {_tempValue.btnLabel2}
              </button>
            </div>
          )}
        </div>
      </div>
    </AlertStyled>
  );
});

Alert.propTypes = {};

let refParent = null;

const RefLink = (e) => {
  refParent = e;
};

const AlertRefWrap = () => {
  const onRef = (e) => {
    RefLink(e);
  };
  return <Alert ref={onRef} />;
};

const showWrap = (e) => {
  refParent.openModal(e);
};

export const AlertControl = {
  show: (e) => showWrap(e),
};

export default AlertRefWrap;
