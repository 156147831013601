import React from "react";
import { CreditLimitEditFormStyled } from "./styled";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Buttons, Inputs } from "components";

const CreditLimitEditForm = ({ onSubmit }) => {
  const schema = yup.object().shape({
    credit_limit: yup.string().required("Required"),
    installment_period: yup.string().required("Required"),
    payment_due_date: yup.string().required("Required"),
  });

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting, isValid },
    setValue,
    trigger
  } = useForm({
    resolver: yupResolver(schema),
  });

  const watchField = watch();

  const _handleChangeCredit = () => {
    setValue("installment_period", "", { shouldValidate: true });
    setValue("payment_due_date", "", { shouldValidate: true });
  };

  return (
    <CreditLimitEditFormStyled>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="f_container">
          <div className="form_title">เลือกจำนวนเงินและระยะเวลาการผ่อน</div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.TextNumberBtn
                  {...field}
                  isRequire
                  label="เลือกวงเงิน"
                  labelSuffix="(สูงสุด 500,000.00  บาท)"
                  maxLength={500000}
                  placeholder="กรุณากรอก"
                  errors={errors.credit_limit?.message}
                  onChangeCus={_handleChangeCredit}
                />
              )}
              name="credit_limit"
              defaultValue={"10000"}
            />
          </div>
          <div className="input_wrap">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.RadioBox
                  {...field}
                  isRequire
                  label="เลือกระยะเวลาการผ่อน"
                  desc="(วงเงินกู้ 10,000 - 100,000 บาท ผ่อนสูงสุด 48 เดือน วงเงินกู้ มากกว่า 100,000 บาท ผ่อนสูงสุด 60 เดือน)"
                  options={RENDER_DATA(watchField?.credit_limit)}
                  errors={errors.installment_period?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("installment_period");
                  }}
                />
              )}
              name="installment_period"
              defaultValue=""
            />
          </div>
          <div className="input_wrap mb_26">
            <Controller
              control={control}
              render={({ field }) => (
                <Inputs.RadioBox
                  {...field}
                  isRequire
                  theme_three
                  label="เลือกวันครบกำหนดชำระ"
                  options={[
                    {
                      label: "ทุกวันที่ 2",
                      value: "6",
                    },
                    {
                      label: "ทุกวันที่ 17",
                      value: "12",
                    },
                    {
                      label: "ทุกวันที่ 27",
                      value: "27",
                    },
                  ]}
                  errors={errors.payment_due_date?.message}
                  onChange={(e) => {
                    field.onChange(e);
                    trigger("payment_due_date");
                  }}
                />
              )}
              name="payment_due_date"
              defaultValue=""
            />
          </div>
          <div className="summay_row">
            <div className="s_label">ยอดชำระต่อเดือน</div>
            <div className="s_value">30,875.00 บาท</div>
          </div>
        </div>
        <div className="f_action">
          <Buttons.BgStandard
            type="submit"
            label="ยอมรับ"
            disabled={isSubmitting || !isValid}
          />
        </div>
      </form>
    </CreditLimitEditFormStyled>
  );
};

const RENDER_DATA = (e) => {
  const isFull = !(!e ? false : e >= 100000);
  return [
    {
      label: "6",
      value: "6",
    },
    {
      label: "12",
      value: "12",
    },
    {
      label: "18",
      value: "18",
    },
    {
      label: "24",
      value: "24",
    },
    {
      label: "30",
      value: "30",
    },
    {
      label: "36",
      value: "36",
    },
    {
      label: "42",
      value: "42",
    },
    {
      label: "48",
      value: "48",
    },
    {
      label: "54",
      value: "54",
      disabled: isFull,
    },
    {
      label: "60",
      value: "60",
      disabled: isFull,
    },
  ];
};

CreditLimitEditForm.propTypes = {};

export default CreditLimitEditForm;
