import { Routes, Route } from "react-router-dom";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Mainlayouts } from "components";
import systemRoutes from "./systemRoutes";
// import SplashContainer from "containers/SplashContainer";
import AuthenTestContainer from "containers/AuthenTestContainer";
import LoginContainer from "containers/LoginContainer";

const Navigations = () => {
  return (
    <Routes>
      <Route element={<Mainlayouts.SystemLayout />}>
        <Route path={ROUTE_PATH.ROOT} element={<LoginContainer />} />
        <Route path="*" element={<LoginContainer />} />
      </Route>
      <Route element={<Mainlayouts.PageLayout isNotBg />}>
        <Route
          path={ROUTE_PATH.AUTHEN_TEST}
          element={<AuthenTestContainer />}
        />
      </Route>
      <Route element={<Mainlayouts.SystemLayout />}>
        {systemRoutes.map((e, i) => {
          return !e.children ? (
            <Route key={i} index={e.index} path={e.path} element={e.element} />
          ) : (
            <Route key={i} path={e.path} element={e.element}>
              {e.children &&
                e.children.map((f, j) => (
                  <Route key={j} index path={f.path} element={f.element} />
                ))}
            </Route>
          );
        })}
      </Route>
    </Routes>
  );
};

export default Navigations;
