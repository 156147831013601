import React from "react";
import { ExcuteRefinanceContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Buttons, Cards, Forms, Headers } from "components";

class ExcuteRefinanceContainer extends React.Component {
  state = {
    data: SERVICE,
    dataSort: [],
  };

  handleSubmit = (values) => {
    let params = {
      ...values,
    };
    console.log("params", params);
    this.props.router.navigate(ROUTE_PATH.REFINANCE_INFO);
  };

  // TRANFET DATA FLOW
  // ================================
  // ================================
  handleSelectProvider = (index, e) => {
    const { data, dataSort } = this.state;
    let tempData = [...data];
    let spliceData = tempData.splice(index, 1);
    let tempDataSort = [...dataSort];
    tempDataSort.push(spliceData[0]);
    this.setState({
      data: [...tempData],
      dataSort: [...tempDataSort],
    });
  };
  handleUnSelectProvider = (index, e) => {
    const { data, dataSort } = this.state;
    let tempDataSort = [...dataSort];
    let spliceData = tempDataSort.splice(index, 1);
    let tempData = [...data];
    tempData.push(spliceData[0]);
    this.setState({
      data: [...tempData],
      dataSort: [...tempDataSort],
    });
  };

  render() {
    const { data, dataSort } = this.state;
    return (
      <ExcuteRefinanceContainerStyled>
        <Headers.Normal title="ดำเนินการยื่นเรื่องรีไฟแนนซ์" />
        <div className="body_container">
          <Cards.FinanceSumApprove />
          <div className="line_horizontal" />
          <div className="title">เลือกสินเชื่อที่ต้องการรีไฟแนนซ์</div>
          <div className="sub_title">
            *ลำดับการเลือก เรียงความสำคัญของบัญชีที่ต้องการรีไฟแนนซ์จาก
            ก่อนไปหลัง
          </div>
          <div className="service_layout">
            <Forms.ServiceProviderSortForm
              initialValues={{
                service_provider_list: dataSort,
              }}
              onUnSelect={this.handleUnSelectProvider}
              // onSubmit={this.handleSubmit}
            />
          </div>
          <div className="service_provideer_list">
            <div className="svp_title">
              รายการสินเชื่อที่ไม่ต้องการรีไฟแนนซ์
            </div>
            <div className="svp_content_block">
              {data &&
                data.map((e, i) => (
                  <div key={i} className="svp_item_wrap">
                    <Cards.FinancialProvider
                      theme_standard
                      name={e.name}
                      total_dept={e.total_dept}
                      installment={e.installment}
                      onSelect={() => this.handleSelectProvider(i, e)}
                      // no={i + 1}
                    />
                  </div>
                ))}
            </div>
          </div>
          <Buttons.BgStandard
            label={"ทำการรีไฟแนนซ์"}
            onClick={this.handleSubmit}
          />
        </div>
      </ExcuteRefinanceContainerStyled>
    );
  }
}

const SERVICE = [
  {
    id: "1",
    name: "กรุงไทย",
    installment: 10,
    total_dept: 200,
  },
  {
    id: "2",
    name: "กสิกร",
    installment: 5,
    total_dept: 4440,
  },
  {
    id: "3",
    name: "ออมสิน",
    installment: 6,
    total_dept: 120,
  },
];

export default withRouter(ExcuteRefinanceContainer);
