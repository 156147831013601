import React from "react";
import { BookConfirmConsentContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Forms, Headers } from "components";

class BookConfirmConsentContainer extends React.Component {
  handleSubmit = (values) => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_VERIFY);
  };

  render() {
    return (
      <BookConfirmConsentContainerStyled>
        <Headers.Logo title="ยืนยันการเลือกรีไฟแนนซ์" />
        <div className="title">
          หนังสือยืนยันให้นำเงินจากวงเงินสินเชื่อส่วนบุคคลไปชำระหนี้สินเชื่อให้แก่ผู้ให้บริการทางการเงิน
        </div>
        <div className="body_sec">
          <div className="confiton_container">
            ชื่อ ............ [XXXXX]......... นามสกุล ............. [XXXXX]……..
            เลขที่ลูกค้า…………… [XXXXX]……………
            โดยการลงลายมือชื่อทางอิเล็กทรอนิกส์นี้ ข้าพเจ้าขอรับรองว่า
            ข้าพเจ้าได้ยื่นคำขอใช้สินเชื่อกับบริษัท เงินดีดี จำกัด (“บริษัท”)
            ตามใบสมัครสินเชื่อส่วนบุคคลภายใต้โครงการ [ชื่อโครงการ refinance]
            (“สินเชื่อส่วนบุคคล”) ของข้าพเจ้าที่ได้รับอนุมติ
            ข้าพเจ้ามีความประสงค์ให้บริษัทนำเงินกู้ที่ข้าพเจ้ามีสิทธิจะได้รับชำระหนี้สินเชื่อส่วนบุคคลที่ข้าพเจ้ามีอยู่กับผู้ให้บริการทางการเงิน
            (รายละเอียดปรากฏตามที่ระบุในหนังสือฉบับนี้)
            ตามลำดับที่บริษัทเห็นสมควร และ/หรือตามที่ข้าพเจ้าเลือก
            และให้ถือว่าข้าพเจ้าได้รับเงินกู้นับตั้งแต่วันที่บริษัทนำเงินกู้เข้าบัญชีผู้ให้บริการทางการเงินโดยถูกต้อง
            ครบถ้วน ทั้งนี้
            เอกสารหรือหลักฐานการโอนเงินให้ถือเป็นหลักฐานรับเงินตามคำขอฉบับนี้และสัญญาสินเชื่อด้วยรายละเอียดสินเชื่อส่วนบุคคลที่อยู่กับผู้ให้บริการทางการเงิน
            อ้างอิงสัญญาสินเชื่อ..........[ชื่อโครงการ refinance]……… สัญญาเลขที่
            ........... [XXXXXX]
            ฉบับลงวันที่………..[XXXXXX].....วงเงินอนุมัติทั้งหมดจำนวนทั้งสิ้น.....[XX.XXXXX].....บาท
            อัตราดอกเบี้ย..........[X.XX]…… ต่อปี 
            ค่าธรรมเนียมรวมทั้งสิ้น........[XXXXXXX].......บาท
            (โปรดศึกษาค่าธรรมเนียม ค่าบริการ และดอกเบี้ยได้ที่
            [XXตารางค่าธรรมเนียม ploanXXX]
            และสามารถดูรายละเอียดในเอกสารใบเสร็จรับเงิน)
          </div>
        </div>
        <div className="footer_sec">
          <div className="version_label">Version 1.0.15 (25 เม.ย. 2567)</div>
          <Forms.TermAndConditionForm onSubmit={this.handleSubmit} />
        </div>
      </BookConfirmConsentContainerStyled>
    );
  }
}

export default withRouter(BookConfirmConsentContainer);
