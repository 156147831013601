import React from "react";
import { PinVerifyContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Inputs } from "components";

class PinVerifyContainer extends React.Component {
  handleClickForgetPin = () => {
    const { resetRedirect } = this.props;
    this.props.router.navigate(
      `${ROUTE_PATH.PIN_OTP}?redirect=${resetRedirect}`
    );
  };

  handleClickKeyboadPad = () => {
    const { onVerifySuccess } = this.props;
    if (!onVerifySuccess) {
      this.props.router.navigate(ROUTE_PATH.NCB_CONSENT);
    } else {
      onVerifySuccess();
    }
  };

  render() {
    return (
      <PinVerifyContainerStyled>
        <div className="title">ใส่รหัส PIN เพื่อทำรายการ</div>
        <div className="figture">
          <img
            className="avatar_img"
            alt="avatar"
            src="/assets/images/icons/avatar.png"
          />
        </div>
        <div className="desc">กรุณากรอกรหัส PIN เพื่อยืนยันการทำรายการ</div>
        <div className="pin_row">
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
        <button className="btn_forget_pin" onClick={this.handleClickForgetPin}>
          ลืมรหัสผ่าน
        </button>
        <div className="pad_keyboar_wrap">
          <Inputs.TextNumberKeyboadPad onClick={this.handleClickKeyboadPad} />
        </div>
      </PinVerifyContainerStyled>
    );
  }
}

export default withRouter(PinVerifyContainer);
