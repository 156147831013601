import styled from "styled-components";

export const ApproveStatusStyled = styled.div`
  border-radius: 16px;
  padding: 2px 6px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  background: ${({ theme }) => theme.COLORS.ORANGE_1};
  color: ${({ theme }) => theme.COLORS.WHITE_1};
  font-size: ${({ theme }) => theme.FONTS.SIZES.S14};
  font-weight: ${({ theme }) => theme.FONTS.WEIGHTS.REGULAR};

  &.theme_standard {
    color: ${({ theme }) => theme.COLORS.GREEN_1};
  }
`;
