import React from "react";
import { connect } from "react-redux";
import { LoginContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import liff from "@line/liff";
import { ROUTE_PATH } from "utils/constants/routePath";
import { setReduxAuthen } from "store/reducers/authenReducer";
// import { moneyDDService } from "apiServices";
import { Forms, Headers } from "components";

class LoginContainer extends React.Component {
  state = {};

  componentDidMount() {
    // this.initialLineLiff();
  }

  initialLineLiff = async () => {
    await liff
      .init({ liffId: process.env.REACT_APP_LINE_LIFF_ID })
      .catch((err) => {
        throw err;
      });
    if (!liff.isLoggedIn()) {
      liff.login();
    } else {
      const accessToken = liff.getAccessToken();
      const getProfile = await liff.getProfile();
      let params = {
        access_token: accessToken,
        user_id: getProfile.userId,
        member_type: [2, 3],
      };
      console.log("pama", params, getProfile);
      // let res = await userService.POST_LINE_LOGIN(params);
      // if (res && res.status === 200) {
      //   localStorage.setItem("token", res.token);
      //   this.fetchMyInfomation(res.data);
      // } else {
      //   this.props.router.navigate(
      //     `${ROUTE_PATH.REGISTER}?lineID=${res.data.lineRefID}&avatar=${getProfile.pictureUrl}`
      //   );
      // }
    }
  };

  handleSubmit = async (e) => {
    // let res = await moneyDDService.GET_MY_PROFILE();
    // console.log("res", res);
    // if (res && res.respCode === 2000) {
    //   this.props.setReduxAuthen(res.data)
    // }
    this.props.setReduxAuthen(e);
    this.handleClickNext();
  };

  handleClickNext = () => {
    const { onVerifySuccess } = this.props;
    if (!onVerifySuccess) {
      this.props.router.navigate(ROUTE_PATH.REGIS_CUS_VERIFY_OTP);
      // this.props.router.navigate(ROUTE_PATH.LANDING_PAGE);
    } else {
      onVerifySuccess();
    }
  };

  render() {
    return (
      <LoginContainerStyled>
        <Headers.Normal title="เบอร์โทรศัพท์" />
        <div className="body_container_layout">
          <Forms.LoginForm onSubmit={this.handleSubmit} />
        </div>
      </LoginContainerStyled>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  setReduxAuthen: (data) => dispatch(setReduxAuthen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(LoginContainer));
