import styled from "styled-components";

export const Step6UploadDocContainerStyled = styled.div`
  .body_container {
    padding: 20px 16px;
    .step_wrap {
      margin-bottom: 14px;
    }
  }
`;
