import React, { forwardRef, useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { OtpFieldStyled } from "./styled";
import OtpInput from "react-otp-input";

const OtpField = forwardRef((props, ref) => {
  const {
    theme_standard,
    theme_email,
    label,
    subLabel,
    disabled,
    errors,
    nodeLabel,
    onChange,
    onChangeCus,
    isRequire,
  } = props;
  const customClass = cx({
    theme_standard: theme_standard,
    theme_email: theme_email,
  });

  const [_otp, _setOtp] = useState("");

  const _onChange = (e) => {
    if (!disabled) {
      _setOtp(e);
      if (e && e.length === 6) {
        onChange && onChange(e);
        onChangeCus && onChangeCus(e);
      } else {
        onChange("");
        onChangeCus && onChangeCus("");
      }
    } else {
      _setOtp("");
    }
  };
  return (
    <OtpFieldStyled className={customClass} disabled={disabled}>
      <div className={`otp_input_container`}>
        {label && (
          <div className="label">
            <div>
              {label} {isRequire && <span>*</span>}{" "}
              {subLabel && <span className="sub_label">{subLabel}</span>}
            </div>
            {nodeLabel}
          </div>
        )}
        <OtpInput
          ref={ref}
          containerStyle="otp_input"
          inputStyle={"input_item"}
          value={_otp}
          onChange={_onChange}
          numInputs={6}
          isDisabled={disabled}
          renderInput={(props) => <input {...props} />}
        />
        {errors && <div className="errors">{errors}</div>}
      </div>
    </OtpFieldStyled>
  );
});

export default OtpField;
