import React, { useState } from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CameraContainer, InputCameraStyled } from "./styled";
import Webcam from "react-webcam";
import { Icons, Modals } from "components";

const InputCamera = ({ theme_standard, onChange }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_renderModal, _setRenderModal] = useState(false);

  const _handleClickUseCamera = () => {
    _setRenderModal(true);
  };

  const _handleCloseModal = () => {
    _setRenderModal(false);
  };

  const _takePhoto = (e) => {
    onChange && onChange("dddd");
  };

  return (
    <>
      <InputCameraStyled className={customClass}>
        <button
          type="button"
          className="btn_upm_item"
          onClick={_handleClickUseCamera}
        >
          <Icons.UploadCamera />
          <div className="value_txt">กล้องถ่ายภาพ</div>
        </button>
      </InputCameraStyled>
      <Modals.BodyEmpty
        isShowModal={_renderModal ? true : false}
        onCloseModal={_handleCloseModal}
      >
        <CameraContainer>
          <Webcam
            audio={false}
            height={720}
            screenshotFormat="image/jpeg"
            width={1280}
            videoConstraints={videoConstraints}
          >
            {({ getScreenshot }) => (
              <button
                className="btn_take_phone"
                type="button"
                onClick={() => {
                  const imageSrc = getScreenshot();
                  _takePhoto(imageSrc);
                }}
              ></button>
            )}
          </Webcam>
        </CameraContainer>
      </Modals.BodyEmpty>
    </>
  );
};

const videoConstraints = {
  width: 1280,
  height: 720,
  facingMode: "user",
};

InputCamera.propTypes = {};

export default InputCamera;
