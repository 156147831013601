import { forwardRef, useState, useEffect } from 'react';
import { Radio2Container } from './styled';
import cx from 'classnames';
import { Icons } from 'components';

const Radio2 = forwardRef((props, ref) => {
  const {
    theme_standard,
    desc,
    label,
    value,
    onChange,
    errors,
    options,
    isRequire,
  } = props;

  const customClass = cx({
    theme_standard: theme_standard,
  });

  const [_value, _setValue] = useState();

  useEffect(() => {
    if (value && !_value) {
      _setValue(value);
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  const _onChange = (e) => {
    _setValue(e.value);
    onChange && onChange(e.value);
  };

  return (
    <Radio2Container className={customClass}>
      {label && (
        <div className="label">
          {label} {isRequire && <span>*</span>}
        </div>
      )}
      {desc && <div className="rd_desc">{desc}</div>}
      <div className="radio_container" ref={ref}>
        {options &&
          options.map((e, i) => (
            <div
              key={i}
              className={`radio_option_item ${
                _value === e.value ? 'active' : ''
              }`}
              onClick={() => _onChange(e)}
            >
              {_value === e.value ? (
                <Icons.RadioCircle2 active />
              ) : (
                <Icons.RadioCircle2 />
              )}
              {e.label}
            </div>
          ))}
      </div>
      {errors && <div className="errors">{errors}</div>}
    </Radio2Container>
  );
});

export default Radio2;
