import styled from "styled-components";

export const AgreementPublicInfoFormStyled = styled.div`
  height: 100%;
  form {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .condition_block {
    flex: 1;
    border-radius: 13px;
    border: 1px solid ${({ theme }) => theme.COLORS.GRAY_6};
    padding: 22px 16px;
    display: flex;
    flex-direction: column;
    .condition {
      flex: 1;
    }
    .input_wrap {
      margin-top: 20px;

    }
  }
  .btn_submit_wrap {
    padding-top: 20px;
  }
`;
