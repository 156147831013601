import React from "react";
import { CreditLimitConfirmContainerStyled } from "./styled";
import { withRouter } from "navigations/withRouter";
import { ROUTE_PATH } from "utils/constants/routePath";
import { Headers } from "components";

class CreditLimitConfirmContainer extends React.Component {
  handleClickConfirm = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_CREDIT_PIN_VERIFY);
  };

  handleClickCreditLimitEdit = () => {
    this.props.router.navigate(ROUTE_PATH.REFINANCE_CREDIT_CHANGE);
  };

  render() {
    return (
      <CreditLimitConfirmContainerStyled>
        <Headers.Normal title="ดำเนินการยื่นเรื่องรีไฟแนนซ์" />
        <div className="body_container">
          <div className="header_layout">
            <div className="title">ท่านได้รับการอนุมัตสินเชื่อ</div>
            <div className="title">ส่วนบุคคลกู๊ดมันนี่</div>
          </div>
          <div className="info_layout">
            <div className="info_row mb_16">
              <div className="label">วงเงินที่ได้รับการอนุมัติ</div>
              <div className="value md_16">300,000 บาท</div>
            </div>
            <div className="info_row pink">
              <div className="label pink">วงเงินอนุมัติ</div>
              <div className="value pink">300,000 บาท</div>
            </div>
            <div className="info_row bd_bt">
              <div className="label">จำนวนงวดตามสัญญา</div>
              <div className="value">6 เดือน</div>
            </div>
            <div className="info_row bd_bt">
              <div className="label">อัตตราดอกเบี้ย</div>
              <div className="value">23.50% ต่อปี</div>
            </div>
            <div className="info_row bd_bt">
              <div className="label">ยอดผ่อนชำระ / เดือน</div>
              <div className="value">61,750.00 บาท</div>
            </div>
            <div className="info_row bd_bt">
              <div className="label">วันครบกำหนดชำระ</div>
              <div className="value">ทุกวันที่ 2</div>
            </div>
            <div className="info_row bd_bt">
              <div className="label">วันครบกำหนดชำระงวดแรก</div>
              <div className="value">2 ก.ค. 2567</div>
            </div>
          </div>
          <div className="action_layout">
            <button className="btn_confirm" onClick={this.handleClickConfirm}>
              ยืนยันรับวงเงิน
            </button>
            <button
              className="btn_change"
              onClick={this.handleClickCreditLimitEdit}
            >
              ปรับเปลี่ยนวงเงิน
            </button>
          </div>
        </div>
      </CreditLimitConfirmContainerStyled>
    );
  }
}

export default withRouter(CreditLimitConfirmContainer);
