import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { CusInfoRecStyled } from "./styled";

const CusInfoRec = ({ theme_standard, title, src }) => {
  const customClass = cx({
    theme_standard: theme_standard,
  });
  return (
    <CusInfoRecStyled className={customClass}>
      <div className="cir_left_col">
        <img alt="avatar" src={src} />
      </div>
      <div className="cir_right_col">
        <div className="rc_left_col">
          <div className="circle_no">1</div>
        </div>
        <div className="rc_right_col">
          <div className="cir_title">{title}</div>
          <ul className="cir_desc">
            <li>วงเงิน 5 เท่าของเงินเดือนสูงสุด 5 แสนบาท</li>
            <li>ใช้สลิปเงินเดือน</li>
          </ul>
        </div>
      </div>
    </CusInfoRecStyled>
  );
};

CusInfoRec.propTypes = {};

export default CusInfoRec;
