import React from "react";
import cx from "classnames";
// import PropTypes from 'prop-types'
import { InfomationListStyled } from "./styled";
import { Icons } from "components";

const InfomationList = ({ theme_standard, theme_title, data, title, type }) => {
  const customClass = cx({
    theme_standard: theme_standard,
    theme_title: theme_title,
  });
  return !type ? (
    <InfomationListStyled className={customClass}>
      <div className="info_icon_wrap">
        <Icons.Infomation color="#ED018C" />
      </div>
      <ul className="info_block">
        {data &&
          data.map((e, i) => (
            <li key={i} className="info_label">
              {e}
            </li>
          ))}
      </ul>
    </InfomationListStyled>
  ) : (
    <InfomationListStyled className={customClass}>
      <div className="info_title">{title}</div>
      <ul className="info_block">
        {data &&
          data.map((e, i) => (
            <li key={i} className="info_label">
              {e}
            </li>
          ))}
      </ul>
    </InfomationListStyled>
  );
};

InfomationList.propTypes = {};

export default InfomationList;
